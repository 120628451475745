let datosVictima = {
  nombres: "",
  apellidos: "",
  rut: "",
  genero: "",
  fecha_nacimiento: "",
  nacionalidad: "",
  pueblo_originario: "",
  nivel_educacional: "",
  ocupacion: "",
  estado_civil: "",
  relacion_con_ofensor: "",
  direccion_principal: "",
  region_principal: "",
  comuna_principal: "",
  correo: "",
  telefonos: [""],
};

let datosOfensor = {
  delito_previo_bool: false,
  delitos_previos: ["", ""],
  nombres: "",
  apellidos: "",
  rut: "",
  genero: "",
  fecha_nacimiento: "",
  nacionalidad: "",
  pueblo_originario: "",
  nivel_educacional: "",
  ocupacion: "",
  estado_civil: "",
  relacion_con_victima: "",
  direccion_principal: "",
  region_principal: "",
  comuna_principal: "",
  correo: "",
  telefonos: [""],
};

let datosConcurrente = {
  relacionado_a: "",
  nombres: "",
  apellidos: "",
  rut: "",
  genero: "",
  fecha_nacimiento: "",
  nacionalidad: "",
  pueblo_originario: "",
  nivel_educacional: "",
  ocupacion: "",
  estado_civil: "",
  relacion_con_victima: "",
  direccion_principal: "",
  region_principal: "",
  comuna_principal: "",
  correo: "",
  telefonos: [""],
};

export {datosVictima,datosOfensor, datosConcurrente}
