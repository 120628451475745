//CSS
import classes from "./FiltrosGenerales.module.css";
//Librerías react
import { useState } from "react";
import {
  delitos,
  comunas,
  derivadores,
  devolucion,
  mediadores,
  acuerdos,
} from "../../components/data/opciones";

function FiltrosGenerales(props) {
  const [isOpen, setOpen] = useState(true);
  const estadistica = props.estadistica;
  return (
    <div className={classes.mainContainer}>
      {/* Conteo por: */}
      {estadistica ? (
        <>
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>
              <strong>Eje X</strong>
            </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={props.ejeX}
                onChange={(event) => {
                  //props.setIsCargandoData(true);
                  props.setEjeX(event.target.value);
                }}
              >
                <option disabled value=" ">
                  {" "}
                  -- Seleccione --{" "}
                </option>
                {Object.keys(props.opcionesEjeX).map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          <hr className={classes.divider} />
        </>
      ) : (
        <div></div>
      )}

      <div
        className={classes.collapsible}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <span className={classes.divisorFiltros}>Filtros Generales</span>
        <span className={isOpen ? classes.arrowOpen : classes.arrowClose}>
          {">"}
        </span>
      </div>
      {isOpen ? (
        <div
          className={[
            classes.contentContainer,
            isOpen ? classes.open : classes.closed,
          ].join(" ")}
        >
          {/* Filtro Estado: */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Estado: </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) => {
                  props.setFechaDesdeCierre("");
                  props.setFechaHastaCierre("");
                  props.addFiltro("general", "estado", event.target.value);
                }}
              >
                <option value="Todos">{"Todos"}</option>
                <option value={"vigente"}>{"Vigente"}</option>
                <option value={"finalizado"}>{"Finalizado"}</option>
              </select>
            </span>
          </div>
          {/* Filtro Etapa: */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Etapa: </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro("general", "etapa", event.target.value)
                }
              >
                <option value="Todos">{"Todos"}</option>
                <option value={"Ingreso"}>{"Ingreso"}</option>
                <option value={"Premediación"}>{"Premediación"}</option>
                <option value={"Mediación"}>{"Mediación"}</option>
                <option value={"Seguimiento"}>{"Seguimiento"}</option>
                <option value={"Cierre"}>{"Cierre"}</option>
              </select>
            </span>
          </div>
          {/* Filtro Delito */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Delito: </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro("general", "delito", event.target.value)
                }
              >
                <option value="Todos">{"Todos"}</option>
                {delitos.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden.charAt(0) + orden.slice(1).toLowerCase()}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          {/* Filtro Comuna */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Comuna del hecho: </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro("general", "comuna_hecho", event.target.value)
                }
              >
                <option value="Todos">{"Todos"}</option>
                {comunas.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          {/* Filtro derivador */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Derivado por: </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro(
                    "general",
                    "derivador_caso",
                    event.target.value
                  )
                }
              >
                <option value="Todos">{"Todos"}</option>
                {derivadores.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          {/* Causal devolución */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Causal devolución: </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro(
                    "general",
                    "causal_devolucion",
                    event.target.value
                  )
                }
              >
                <option value="Todos">{"Todos"}</option>
                {devolucion.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          {/* Mediador asignado */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Mediador asignado: </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro(
                    "general",
                    "mediador_asignado",
                    event.target.value
                  )
                }
              >
                <option value="Todos">{"Todos"}</option>
                {mediadores.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          {/* "Tipo de auerdo" */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Tipo acuerdo: </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro(
                    "general",
                    "tipo_acuerdo",
                    event.target.value
                  )
                }
              >
                <option value="Todos">{"Todos"}</option>
                {[...acuerdos, "Con acuerdo"].map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default FiltrosGenerales;
