import classes from "./FiltrosParticipantes.module.css";
import { useState } from "react";
import {
  nacionalidades,
  puebloOriginario,
  nivelEducacional,
  generos,
  estadoCivil,
} from "../../components/data/opciones";

function FiltrosParticipantes(props) {
  const [isOpen, setOpen] = useState(false);
  const colorDict = {
    "victima": classes.collapsibleVictima,
    "ofensor": classes.collapsibleOfensor,
    "concurrente": classes.collapsibleConcurrente
  }

  return (
    <div className={classes.mainContainer}>
      <div className={[classes.collapsible, colorDict[props.tipoParticipante]].join(" ")}
        onClick={() => {
          setOpen(!isOpen);
        }}>
        <div
          className={classes.divisorFiltros}
        >
          {props.titulo}
        </div>
        <div className={isOpen ? classes.arrowOpen : classes.arrowClose}>
          {">"}
        </div>
      </div>
      {isOpen ? (
        <div className={[classes.contentContainer, isOpen? classes.open : classes.closed].join(" ")}>
          {/* Nacionalidad */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Nacionalidad </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro(
                    props.tipoParticipante,
                    "nacionalidad",
                    event.target.value
                  )
                }
              >
                <option value="Todos">{"Todos"}</option>
                {nacionalidades.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          {/* Pueblo originario */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Pueblo originario </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro(
                    props.tipoParticipante,
                    "pueblo_originario",
                    event.target.value
                  )
                }
              >
                <option value="Todos">{"Todos"}</option>
                {puebloOriginario.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          {/* Nivel educacional */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Nivel eduacional </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro(
                    props.tipoParticipante,
                    "nivel_educacional",
                    event.target.value
                  )
                }
              >
                <option value="Todos">{"Todos"}</option>
                {nivelEducacional.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          {/* Género */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Género </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro(
                    props.tipoParticipante,
                    "genero",
                    event.target.value
                  )
                }
              >
                <option value="Todos">{"Todos"}</option>
                {generos.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          {/* Estado Civil */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Estado civil </span>
            <span>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={"Todos"}
                onChange={(event) =>
                  props.addFiltro(
                    props.tipoParticipante,
                    "estado_civil",
                    event.target.value
                  )
                }
              >
                <option value="Todos">{"Todos"}</option>
                {estadoCivil.map(function (orden, i) {
                  return (
                    <option value={orden} key={i}>
                      {orden}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default FiltrosParticipantes;
