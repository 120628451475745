import { useState } from "react";

import ModalIngreso from "../modal/IngresoModal";

import clock from "../../img/clock.png";
import classes from "./IngresoCard.module.css";

function IngresoCard(props) {
  const showModal = () => {
    setShowModalIngreso(true);
  };

  const [modalIngreso, setShowModalIngreso] = useState(false);
  const mediador_asignado = props.caso.mediador_asignado
    ? props.caso.mediador_asignado
    : "Sin asignar";

  let victima =
    props.caso.victimas.length > 0
      ? props.caso.victimas.length > 1
        ? "Múltiple"
        : props.caso.victimas[0].nombres +
          " " +
          props.caso.victimas[0].apellidos
      : "Sin información";

  let ofensor =
    props.caso.ofensores.length > 0
      ? props.caso.ofensores.length > 1
        ? "Múltiple"
        : props.caso.ofensores[0].nombres +
          " " +
          props.caso.ofensores[0].apellidos
      : "Sin información";

  let timeDiff = Math.abs(Date.now() - props.caso.fecha_ingreso.seconds * 1000);
  let dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  function handleDate(timestamp) {
    if (timestamp) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "Sin información";
    }
  }

  return (
    <div className={classes.MainCaso} onClick={showModal}>
      <div className={classes.CasoEtiqueta}>
        CASO: <span>{props.caso.ruc}</span>{" "}
      </div>
      <div className={classes.CasoDatos}>
        <p className={classes.item}>
          Mediador: <span>{mediador_asignado}</span>
        </p>
        <p className={classes.item}>
          Delito: <span>{props.caso.delito}</span>
        </p>
        <p className={classes.item}>
          Víctima: <span>{victima}</span>
        </p>
        <p className={classes.item}>
          Ofensor: <span>{ofensor}</span>
        </p>
        <p className={classes.item}>
          Fecha Ingreso: <span>{handleDate(props.caso.fecha_ingreso)}</span>
        </p>
        <p className={classes.item}>
          Última modificación:{" "}
          <span>{handleDate(props.caso.fecha_ultima_modificacion)}</span>
        </p>
      </div>

      <div className={classes.CasoAyuda}>
        <img className={classes.CasoReloj} src={clock} alt="" />
        <p>{dayDiff ? dayDiff + " días desde ingreso" : "Sin información"}</p>
        {/* <img className={classes.CasoClip} src={clip} alt="" /> */}
      </div>
      <div className={classes.modalContainer}>
        {modalIngreso ? (
          <ModalIngreso
            setShowModalIngreso={setShowModalIngreso}
            key={props.caso ? props.caso.id : "ingresoEnProcesoCard"}
            caso={props.caso}
          ></ModalIngreso>
        ) : null}
      </div>
    </div>
  );
}

export default IngresoCard;
