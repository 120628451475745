import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import CasosMain from "./pages/CasosMain";
import { ToastContainer } from "react-toastify";
import SignIn from "./pages/SignIn.js";
import CasosCerradosProv from "./pages/CasosCerradosProv.js";
import "react-toastify/dist/ReactToastify.css";
import Estadistica from "./pages/Estadistica";
import Admin from "./pages/Admin";
import { getCasos, getCasosOnce } from "./firebase/firebaseGetData.js";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase/firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { userSignIn } from "./firebase/firebasePostData";
import { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import LinearWithValueLabel from "./components/layout/LinearProgressWithLabel.js";
import logoSam from "./img/logo.png";
import logoUc from "./img/Logo-TSocial.png";
import logoUnimico from "./img/logoUNIMICO_gris.png";

function App() {
  const [isCargandoDatos, setIsCargandoCasos] = useState(true);
  const [activarFiltro, setActivarFiltro] = useState(false);
  const [primerLog, setPrimerLog] = useState(false);
  const [casos, setCasos] = useState([]);
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [isPrimeraVez, setIsPrimeraVez] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (loading) return;
    if (!user) {
      setPrimerLog(true);
      return navigate("/login");
    } else {
      let data = {
        email: user.email,
        displayName: user.displayName ? user.displayName : "",
        uid: user.uid,
      };
      userSignIn(data);
      return;
    }
  }, [user, loading, navigate, primerLog]);

  useEffect(() => {
    if (user) {
      if (isPrimeraVez) {
        getCasosOnce(
          user.uid,
          setCasos,
          setIsCargandoCasos,
          setIsPrimeraVez,
          setProgress
        );
      } else {
        const unSub = getCasos(
          user.uid,
          setCasos,
          setIsCargandoCasos,
          setProgress,
          setActivarFiltro,
          casos
        );
      }
      //return async () => await unSub;
    }
  }, [isPrimeraVez, user]);
  //Está bien que no agregue casos arriba! isPrimeraVez controla el primer acceso para que haya un nuevo trigger de useEffect

  return (
    <div className="App">
      <Routes>
        <Route
          path="/login"
          element={
            primerLog ? (
              <SignIn></SignIn>
            ) : (
              <div className="Loading">
                <div className="Hash">
                  <HashLoader color="#36d7b7" size={80} />
                </div>
              </div>
            )
          }
        />
        <Route
          path="/"
          element={
            isCargandoDatos ? (
              <div className="Loading">
                <div className="Hash">
                  <HashLoader color="#36d7b7" size={80} />
                </div>
                <div className="Bar">
                  <LinearWithValueLabel value={progress} />
                </div>
                <div className="logoContainer">
                  <div>
                    <img
                      className="logoUc"
                      src={logoUc}
                      alt="Logo Universidad Católica"
                    />
                  </div>
                  <div>
                    <img
                      className="logoSam"
                      src={logoSam}
                      alt="Logo de Servicio de mediación penal restaurativa"
                    />
                  </div>
                  <div>
                    <img
                      className="logoUnimico"
                      src={logoUnimico}
                      alt="Logo de Servicio de mediación penal restaurativa"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Layout>
                <CasosMain
                  user={user}
                  casos={casos}
                  isCargandoDatos={isCargandoDatos}
                  setIsCargandoCasos={setIsCargandoCasos}
                  activarFiltro={activarFiltro}
                  setActivarFiltro={setActivarFiltro}
                ></CasosMain>
              </Layout>
            )
          }
        />
        <Route
          path="/historicos"
          element={
            isCargandoDatos ? (
              <div className="Loading">
                <div className="Hash">
                  <HashLoader color="#36d7b7" size={80} />
                </div>
                <div className="Bar">
                  <LinearWithValueLabel value={progress} />
                </div>
                <div className="logoContainer">
                  <div>
                    <img
                      className="logoUc"
                      src={logoUc}
                      alt="Logo Universidad Católica"
                    />
                  </div>
                  <div>
                    <img
                      className="logoSam"
                      src={logoSam}
                      alt="Logo de Servicio de mediación penal restaurativa"
                    />
                  </div>
                  <div>
                    <img
                      className="logoUnimico"
                      src={logoUnimico}
                      alt="Logo de Servicio de mediación penal restaurativa"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Layout>
                <CasosCerradosProv
                  user={user}
                  casos={casos}
                  isCargandoDatos={isCargandoDatos}
                  setIsCargandoCasos={setIsCargandoCasos}
                  activarFiltro={activarFiltro}
                  setActivarFiltro={setActivarFiltro}
                ></CasosCerradosProv>
              </Layout>
            )
          }
        ></Route>
        <Route
          path="/estadisticas"
          element={
            isCargandoDatos ? (
              <div className="Loading">
                <div className="Hash">
                  <HashLoader color="#36d7b7" size={80} />
                </div>
                <div className="Bar">
                  <LinearWithValueLabel value={progress} />
                </div>
                <div className="logoContainer">
                  <div>
                    <img
                      className="logoUc"
                      src={logoUc}
                      alt="Logo Universidad Católica"
                    />
                  </div>
                  <div>
                    <img
                      className="logoSam"
                      src={logoSam}
                      alt="Logo de Servicio de mediación penal restaurativa"
                    />
                  </div>
                  <div>
                    <img
                      className="logoUnimico"
                      src={logoUnimico}
                      alt="Logo de Servicio de mediación penal restaurativa"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Layout>
                <Estadistica
                  user={user}
                  casos={casos}
                  isCargandoDatos={isCargandoDatos}
                  setIsCargandoCasos={setIsCargandoCasos}
                  activarFiltro={activarFiltro}
                  setActivarFiltro={setActivarFiltro}
                ></Estadistica>
              </Layout>
            )
          }
        ></Route>
        <Route
          path="/admin"
          element={
            isCargandoDatos ? (
              <div className="Loading">
                <div className="Hash">
                  <HashLoader color="#36d7b7" size={80} />
                </div>
                <div className="Bar">
                  <LinearWithValueLabel value={progress} />
                </div>
                <div className="logoContainer">
                  <div>
                    <img
                      className="logoUc"
                      src={logoUc}
                      alt="Logo Universidad Católica"
                    />
                  </div>
                  <div>
                    <img
                      className="logoSam"
                      src={logoSam}
                      alt="Logo de Servicio de mediación penal restaurativa"
                    />
                  </div>
                  <div>
                    <img
                      className="logoUnimico"
                      src={logoUnimico}
                      alt="Logo de Servicio de mediación penal restaurativa"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Layout>
                <Admin
                  user={user}
                  casos={casos}
                  isCargandoDatos={isCargandoDatos}
                  setIsCargandoCasos={setIsCargandoCasos}
                  activarFiltro={activarFiltro}
                  setActivarFiltro={setActivarFiltro}
                ></Admin>
              </Layout>
            )
          }
        ></Route>
      </Routes>

      <ToastContainer autoClose={2000} hideProgressBar={true} />
    </div>
  );
}

export default App;
