import moment from "moment/moment";

function procesarActividades(caso) {
  let sesiones = caso.sesiones ? caso.sesiones : [];
  let actividades = caso.actividades ? caso.actividades : [];
  let allActividades = [...sesiones, ...actividades];
  allActividades.sort((a, b) => {
    let fechaA;
    let fechaB;
    if (a.fecha_actividad) {
      fechaA = a.fecha_actividad;
    } else {
      fechaA = a.fecha_sesion_mediacion;
    }
    if (b.fecha_actividad) {
      fechaB = b.fecha_actividad;
    } else {
      fechaB = b.fecha_sesion_mediacion;
    }
    return fechaA.seconds - fechaB.seconds;
  });
  return allActividades
}

function mediacionResult(tipo_acuerdo) {
  switch (tipo_acuerdo) {
    case "Cierre sin acuerdo":
      return <div>Devolver a fiscalía</div>;
    case "Cierre con acuerdo y seguimiento":
      return <div>Avanzar a seguimiento</div>;
    case "Cierre con acuerdo cumplido":
      return <div>Avanzar a cierre</div>;
    default:
      return <div></div>;
  }
}

function participantesMediacion(participantes) {
  let datosParticipantes = [];
  for (let i = 0; i < participantes.length; i++) {
    let participante =
      participantes[i].nombres + " " + participantes[i].apellidos;
    datosParticipantes.push(participante);
  }
  return datosParticipantes;
}

function parseVictima(victimas) {
  return victimas.length > 0
    ? victimas.length > 1
      ? "Múltiple"
      : victimas[0].nombres + " " + victimas[0].apellidos
    : "Sin información";
}

function parseOfensor(ofensores) {
  return ofensores.length > 0
    ? ofensores.length > 1
      ? "Múltiple"
      : ofensores[0].nombres + " " + ofensores[0].apellidos
    : "Sin información";
}

function deepEqual(x, y) {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}

function actividadesIndex(allActividades, currentActividad){
  let indexSesion = 0;
  let indexActividad = 0;
  for(let actividad of allActividades){
    if(actividad.fecha_sesion_mediacion){
      if(deepEqual(actividad, currentActividad)){
        return indexSesion;
      }else{
        indexSesion++
      }      
    }else{
      if(deepEqual(actividad, currentActividad)){
        return indexActividad;
      }else{
        indexActividad++
      } 
    }
  }
}

export {
  procesarActividades,
  mediacionResult,
  participantesMediacion,
  parseOfensor,
  parseVictima,
  actividadesIndex
};
