import classes from "./TarjetaResumen.module.css";
function TarjetaResumen(props) {
  const morado = "#a943d8";
  const verde = "#30b783";
  const naranja = "#ff7c26";
  const rojo = "#fc5164";
  const azul = "#2275d1";
  const colorDict = {
    azul: azul,
    morado: morado,
    verde: verde,
    naranja: naranja,
    rojo: rojo,
  };
  const color = colorDict[props.color]
  const borderStyle = {
    border: `1px solid ${
      color
    }`,
  };

  const textColor = {
    color: `${
      color
    }`,
  };
  return (
    <div className={classes.container} style={borderStyle}>
      <div className={classes.titulo}>{props.titulo}</div>
      <div className={classes.numero} style={textColor}>
        {props.numero}
      </div>
    </div>
  );
}

export default TarjetaResumen;
