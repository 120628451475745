import classes from "./CasosCerradosProv.module.css";
/* import { useState } from "react"; */
/* import casosIngreso from "../components/data/casosDummy"; */
import { useEffect, useState } from "react";
import HistoricoRow from "../components/tarjetas/HistoricoRow";
import Pagination from "../helpers/Paginacion";
import FiltrosBusquedas from "../components/layout/FiltrosBusquedas";
import {
  filtrosDefault,
  filtrosParticipanteDefault,
} from "../components/data/opciones";
import ResumenHistorico from "../components/historico/ResumenHistorico";
import moment from "moment";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Button, Menu, MenuItem } from "@mui/material";

function CasosCerradosProv(props) {
  const activarFiltro = props.activarFiltro;
  const setActivarFiltro = props.setActivarFiltro;
  const [isOpen, setOpen] = useState(true);
  const [casosPaginados, setCasosPaginados] = useState([]);

  // Pruebas Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const casosPorPagina = 5;

  //Busquedas disponibles
  const [rut, setRut] = useState("");
  const [ruc, setRuc] = useState("");
  const [apellidos, setApellidos] = useState("");

  //Filtros disponibles
  const [casosFiltrados, setCasosFiltrados] = useState(props.casos);
  const [filtros, setFiltros] = useState(filtrosDefault);
  const [filtrosVictima, setFiltrosVictima] = useState(
    filtrosParticipanteDefault
  );
  const [filtrosOfensor, setFiltrosOfensor] = useState(
    filtrosParticipanteDefault
  );
  const [filtrosConcurrente, setFiltrosConcurrente] = useState(
    filtrosParticipanteDefault
  );

  //Días del caso
  const [menosDiez, setMenosDiez] = useState(0);
  const [entreDiezVeinte, setEntreDiezVeinte] = useState(0);
  const [masVeinte, setMasVeinte] = useState(0);
  const [finalizado, setFinalizado] = useState(0);

  //Para menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Paginación de casos
  useEffect(() => {
    let casosPaginadosTemp = casosFiltrados.slice(
      (currentPage - 1) * casosPorPagina,
      currentPage * casosPorPagina
    );
    const convertirFecha = (fecha) => {
      const timestamp = fecha.seconds * 1000 + fecha.nanoseconds / 1000000; // Convertir el objeto de fecha a milisegundos
      return moment(timestamp);
    };
    let menosDiezTemp = 0;
    let entreDiezVeinteTemp = 0;
    let masVeinteTemp = 0;
    let finalizadoTemp = 0;
    for (let caso of casosFiltrados) {
      let fechaPasoEtapaActual;
      try {
        if (caso.etapa === "Ingreso") {
          fechaPasoEtapaActual = convertirFecha(caso.fecha_ingreso);
        }
        if (caso.etapa === "Premediación") {
          fechaPasoEtapaActual = convertirFecha(caso.fecha_paso_premediacion);
        }
        if (caso.etapa === "Mediación") {
          fechaPasoEtapaActual = convertirFecha(caso.fecha_paso_mediacion);
        }
        if (caso.etapa === "Seguimiento") {
          fechaPasoEtapaActual = convertirFecha(caso.fecha_inicio_seguimiento);
        }
        if (caso.etapa === "Cierre") {
          fechaPasoEtapaActual = convertirFecha(caso.fecha_inicio_cierre);
        }
      } catch {
        console.log(caso.ruc);
      }
      const fechaActual = moment();
      const diferenciaDias = fechaActual.diff(fechaPasoEtapaActual, "days");
      if (diferenciaDias < 10 && caso.estado !== "finalizado") menosDiezTemp++;
      if (
        diferenciaDias >= 10 &&
        diferenciaDias <= 20 &&
        caso.estado !== "finalizado"
      )
        entreDiezVeinteTemp++;
      if (diferenciaDias > 20 && caso.estado !== "finalizado") masVeinteTemp++;
      if (caso.estado === "finalizado") finalizadoTemp++;
      caso["diferenciaDias"] = diferenciaDias;
    }
    setCasosPaginados(casosPaginadosTemp);
    setMenosDiez(menosDiezTemp);
    setEntreDiezVeinte(entreDiezVeinteTemp);
    setMasVeinte(masVeinteTemp);
    setFinalizado(finalizadoTemp);
  }, [casosFiltrados, currentPage]);

  // Función para cambiar de página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const exportToExcel = async (excelData, fileName, fileExtension) => {
    let cleanedData = [];
    excelData.map((entry) => {
      cleanedData.push({
        RUC: entry.ruc,
        "Fecha ingreso": entry.fecha_ingreso.seconds
          ? new Date(entry.fecha_ingreso.seconds * 1000).toLocaleDateString()
          : "Sin fecha ingreso",
        Estado: entry.estado,
        Etapa: entry.etapa,
        "Mediador Asignado": entry.ingresoData.mediador_asignado
          ? entry.ingresoData.mediador_asignado
          : "Sin asignar",
        Delito: entry.ingresoData.delito
          ? entry.ingresoData.delito
          : "Sin asignar",
      });
      return cleanedData;
    });

    console.log(casosFiltrados[0]);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charet=UTF-8";
    const ws = XLSX.utils.json_to_sheet(cleanedData);
    const wb = {
      Sheets: {
        data: ws,
      },
      SheetNames: ["data"],
    };
    if (fileExtension === ".xlsx") {
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataToExport = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(dataToExport, fileName + fileExtension);
    } else {
      const ws = XLSX.utils.json_to_sheet(cleanedData);
      const csv = XLSX.utils.sheet_to_csv(ws);
      const csvBuffer = new ArrayBuffer(csv.length);
      const view = new Uint8Array(csvBuffer);
      for (let i = 0; i !== csv.length; ++i) view[i] = csv.charCodeAt(i) & 0xff;
      const dataToExport = new Blob([csvBuffer], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(dataToExport, fileName + fileExtension);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.botonDescarga}>
        <Button
          id="botonDescarga"
          variant="contained"
          className={classes.botonDescarga2}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Descargar
        </Button>
        <Menu
          id="menuDescarga"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              exportToExcel(
                casosFiltrados,
                "Historicos_" + moment().format("YYYY-MM-DD"),
                ".xlsx"
              );
              handleClose();
            }}
          >
            Data en Excel
          </MenuItem>
          <MenuItem
            onClick={() => {
              exportToExcel(
                casosFiltrados,
                "Historicos_" + moment().format("YYYY-MM-DD"),
                ".csv"
              );
              handleClose();
            }}
          >
            Data en CSV
          </MenuItem>
        </Menu>
      </div>
      <div className={classes.filtros}>
        <FiltrosBusquedas
          rut={rut}
          setRut={setRut}
          ruc={ruc}
          setRuc={setRuc}
          apellidos={apellidos}
          setApellidos={setApellidos}
          estadisticas={false}
          setIsCargandoData={props.setIsCargandoCasos}
          casos={props.casos}
          casosFiltrados={casosFiltrados}
          setCasosFiltrados={setCasosFiltrados}
          filtros={filtros}
          setFiltros={setFiltros}
          filtrosVictima={filtrosVictima}
          setFiltrosVictima={setFiltrosVictima}
          filtrosOfensor={filtrosOfensor}
          setFiltrosOfensor={setFiltrosOfensor}
          filtrosConcurrente={filtrosConcurrente}
          setFiltrosConcurrente={setFiltrosConcurrente}
          activarFiltro={activarFiltro}
          setActivarFiltro={setActivarFiltro}
        ></FiltrosBusquedas>
      </div>
      <div className={classes.filterAndRows}>
        <div className={classes.mainCollapsibleContainer}>
          <div
            className={classes.collapsible}
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            <div className={classes.divisorFiltros}>
              {"Histórico de los casos"}
            </div>
            <div className={isOpen ? classes.arrowOpen : classes.arrowClose}>
              {">"}
            </div>
          </div>
          {isOpen ? (
            <div
              className={[
                classes.contentContainer,
                isOpen ? classes.open : classes.closed,
              ].join(" ")}
            >
              <div
                className={[
                  classes.historicoRow,
                  isOpen ? classes.open : classes.closed,
                ].join(" ")}
              >
                {casosPaginados.map((caso, i) => {
                  return (
                    <div className={classes.singleRow} key={caso.ruc + "_" + i}>
                      <HistoricoRow
                        key={caso.ingresoData.ruc}
                        caso={caso}
                        setIsCargandoAllCasos={props.setIsCargandoCasos}
                        diferenciaDias={caso.diferenciaDias}
                      ></HistoricoRow>
                    </div>
                  );
                })}
              </div>
              <div
                className={[
                  classes.pagination,
                  isOpen ? classes.open : classes.closed,
                ].join(" ")}
              >
                <Pagination
                  currentPage={currentPage}
                  totalCases={casosFiltrados.length}
                  casesPerPage={casosPorPagina}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <ResumenHistorico
            menosDiez={menosDiez}
            entreDiezVeinte={entreDiezVeinte}
            masVeinte={masVeinte}
            finalizado={finalizado}
            casosTotales={casosFiltrados.length}
          ></ResumenHistorico>
        </div>
      </div>
    </div>
  );
}

export default CasosCerradosProv;
