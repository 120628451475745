import classes from "./Busquedas.module.css";
import { useState } from "react";

function Busquedas(props) {
  const [isOpen, setOpen] = useState(true);
  const colorDict = {
    victima: classes.collapsibleVictima,
    ofensor: classes.collapsibleOfensor,
    concurrente: classes.collapsibleConcurrente,
    busqueda: classes.collapsibleBusqueda,
  };

  return (
    <div className={classes.mainContainer}>
      <div
        className={[classes.collapsible, colorDict["busqueda"]].join(" ")}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <div className={classes.divisorFiltros}>{"Búsquedas"}</div>
        <div className={isOpen ? classes.arrowOpen : classes.arrowClose}>
          {">"}
        </div>
      </div>
      {isOpen ? (
        <div
          className={[
            classes.contentContainer,
            isOpen ? classes.open : classes.closed,
          ].join(" ")}
        >
          {/* Nacionalidad */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>RUT </span>
            <span>
              <input
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={""}
                onChange={(event) => props.setRut(event.target.value)}
              ></input>
            </span>
          </div>
          {/* Pueblo originario */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>RUC </span>
            <span>
              <input
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={""}
                onChange={(event) => props.setRuc(event.target.value)}
              ></input>
            </span>
          </div>
          {/* Nivel educacional */}
          <div className={classes.dosPartes}>
            <span className={classes.subtitulo}>Apellidos </span>
            <span>
              <input
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={""}
                onChange={(event) => {
                  console.log(event.target.value);
                  props.setApellidos(event.target.value.toUpperCase());
                }}
              ></input>
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Busquedas;
