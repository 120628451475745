import classes from "./ResumenHistorico.module.css";
import { useState } from "react";
import TarjetaResumen from "./TarjetaResumen";

function ResumenHistorico(props) {
  const [isOpen, setOpen] = useState(true);
  const menosDiez = props.menosDiez;
  const entreDiezVeinte = props.entreDiezVeinte;
  const masVeinte = props.masVeinte;
  const finalizado = props.finalizado;
  const casosTotales = props.casosTotales;

  const colorDict = {
    victima: classes.collapsibleVictima,
    ofensor: classes.collapsibleOfensor,
    concurrente: classes.collapsibleConcurrente,
    busqueda: classes.collapsibleBusqueda,
  };

  return (
    <div className={classes.mainContainer}>
      <div
        className={[classes.collapsible, colorDict["busqueda"]].join(" ")}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <div className={classes.divisorFiltros}>{"Resumen del total de casos según estado"}</div>
        <div className={isOpen ? classes.arrowOpen : classes.arrowClose}>
          {">"}
        </div>
      </div>
      {isOpen ? (
        <div
          className={[
            classes.contentContainer,
            isOpen ? classes.open : classes.closed,
          ].join(" ")}
        >
          <div>
            <TarjetaResumen
              titulo={"Menos de 10 días en la etapa actual"}
              numero={menosDiez}
              color={"verde"}
            ></TarjetaResumen>
          </div>
          <div>
            <TarjetaResumen
              titulo={"Entre 10 y 20 días en la etapa actual"}
              numero={entreDiezVeinte}
              color={"naranja"}></TarjetaResumen>
          </div>
          <div>
            <TarjetaResumen
              titulo={"Más de veinte días en la etapa actual"}
              numero={masVeinte}
              color={"rojo"}></TarjetaResumen>
          </div>
          <div>
            <TarjetaResumen
              titulo={"Finalizado"}
              numero={finalizado}
              color={"morado"}></TarjetaResumen>
          </div>
          <div>
            <TarjetaResumen
              titulo={"Casos totales"}
              numero={casosTotales}
              color={"azul"}></TarjetaResumen>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ResumenHistorico;
