import CasoHistoricoModal from "../modal/CasoHistoricoModal";
import { useState } from "react";
import classes from "./HistoricoRow.module.css";

function HistoricoRow(props) {
  const [modalHistorico, setShowModalHistorico] = useState(false);
  const morado = "#a943d8";
  const verde = "#30b783";
  const naranja = "#ff7c26";
  const rojo = "#fc5164";
  const diferenciaDias = props.diferenciaDias;
  const showModal = () => {
    setShowModalHistorico(true);
  };

  function handleDate(timestamp) {
    if (timestamp) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "Sin información";
    }
  }

  const borderStyle = {
    border: `1px solid ${
      props.caso.estado === "finalizado"
        ? morado
        : diferenciaDias < 10
        ? verde
        : diferenciaDias >= 10 && diferenciaDias <= 20
        ? naranja
        : diferenciaDias > 20
        ? rojo
        : ""
    }`,
  };

  const textColor = {
    color: `${
      props.caso.estado === "finalizado"
        ? morado
        : diferenciaDias < 10
        ? verde
        : diferenciaDias >= 10 && diferenciaDias <= 20
        ? naranja
        : diferenciaDias > 20
        ? rojo
        : ""
    }`,
  };
  const backgroundColor = {
    backgroundColor: `${
      props.caso.estado === "finalizado"
        ? morado
        : diferenciaDias < 10
        ? verde
        : diferenciaDias >= 10 && diferenciaDias <= 20
        ? naranja
        : diferenciaDias > 20
        ? rojo
        : ""
    }`,
  };

  return (
    <div>
      <div
        className={classes.mainContainer}
        style={borderStyle}
        onClick={showModal}
      >
        <div>
          <div className={`${classes.rucTitle}`} style={backgroundColor}>
            RUC DEL CASO
          </div>
          <div className={`${classes.bold}`} style={textColor}>
            {props.caso.ingresoData.ruc}
          </div>
        </div>
        <div>
          <div className={classes.row}>Delito</div>
          <div
            className={`${classes.row2} ${classes.value_box}`}
            style={borderStyle}
          >
            {props.caso.ingresoData.delito}{" "}
          </div>
        </div>
        <div>
          <div className={classes.row}>Mediador</div>
          <div
            className={`${classes.row2} ${classes.value_box}`}
            style={borderStyle}
          >
            {props.caso.ingresoData.mediador_asignado
              ? props.caso.ingresoData.mediador_asignado
              : "Sin asignar"}
          </div>
        </div>
        <div>
          <div className={classes.row}>Etapa</div>
          <div
            className={`${classes.row2} ${classes.value_box}`}
            style={borderStyle}
          >
            {props.caso.etapa ? props.caso.etapa : "Sin asignar"}
          </div>
        </div>
        <div>
          <div className={classes.row}>Fecha ingreso</div>
          <div
            className={`${classes.row2} ${classes.value_box}`}
            style={borderStyle}
          >
            {handleDate(props.caso.ingresoData.fecha_ingreso)}
          </div>
        </div>
        <div>
          <div className={classes.row}>Última modificación</div>
          <div
            className={`${classes.row2} ${classes.value_box}`}
            style={borderStyle}
          >
            {handleDate(props.caso.fecha_ultima_modificacion)}
          </div>
        </div>
      </div>
      <div className={classes.modalContainer}>
        {modalHistorico ? (
          <CasoHistoricoModal
            setShowModalHistorico={setShowModalHistorico}
            setIsCargandoAllCasos={props.setIsCargandoAllCasos}
            key={
              props.caso.ingresoData
                ? props.caso.ingresoData.ruc
                : "ingresoEnProcesoCard"
            }
            caso={props.caso}
          ></CasoHistoricoModal>
        ) : null}
      </div>
    </div>
  );
}

export default HistoricoRow;
