import classes from "./SignIn.module.css";
import logo from "../img/logo.png";
import logoUc from "../img/Logo-TSocial.png";
import logoPart1 from "../img/logoPart1.png";
import logoPart2 from "../img/logoPart2.png";
import logoPart3 from "../img/logoPart3.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn, auth } from "../firebase/firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import { userSignIn } from "../firebase/firebasePostData";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const success = () => {
    toast.success("Inicio de sesión correcto!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const errorToast = () => {
    toast.error(
      "Hubo un error con sus credenciales, por favor intente nuevamente",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      let data = {
        email: user.email,
        displayName: user.displayName ? user.displayName : "",
        uid: user.uid,
      };
      userSignIn(data);
      success();
      navigate("/");
    }
    if (error) {
      errorToast();
    }
  }, [user, loading, error, navigate]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.cardContainer}>
        <div>
          <img
            className={classes.logo}
            src={logo}
            alt="Logo de Servicio de mediación penal restaurativa"
          />
        </div>
        {/* <div className={classes.iniciarSesion}>
          INICIAR SESIÓN
        </div> */}
        <div className={classes.inputBox}>
          <div className={classes.hintText}>Correo</div>
          <div>
            <input
              className={classes.inputField}
              type="email"
              name="Correo"
              id="mail"
              placeholder="usuario@uc.cl"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
        </div>
        <div className={classes.inputBox}>
          <div className={classes.hintText}>Contraseña</div>
          <div>
            <input
              className={classes.inputField}
              type="password"
              name=""
              id="pass"
              placeholder="*************"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </div>
        </div>
        <div className={classes.submit}>
          <button
            className={classes.button}
            onClick={async () => {
              await signIn(email, password);
            }}
          >
            INICIAR SESIÓN
          </button>
        </div>
        <div className={classes.logosContainer}>
          <img src={logoUc} className={classes.logoFooter} alt="Logo UC" />
          <div className={classes.logoUnimico}>
            <img src={logoPart1} className={classes.u1} alt="Logo Unimico1" />
            <img src={logoPart2} className={classes.u2} alt="Logo Unimico2" />
            <img src={logoPart3} className={classes.u3} alt="Logo Unimico3" />
          </div>
        </div>
        <div className={classes.footer}>
          Sitio desarrollado en conjunto con la Escuela de Trabajo Social de la
          Pontificia Universidad Católica de Chile y Unimico
        </div>
      </div>
    </div>
  );
}

export default SignIn;
