import { toast } from "react-toastify";
const notify = () => {
  toast.success("Datos Mediación guardados con éxito !", {
    position: toast.POSITION.TOP_CENTER,
  });
};
const paso_a_fiscalia = () => {
  toast.success("Caso derivado a Fiscalía", {
    position: toast.POSITION.TOP_CENTER,
  });
};

const documento_agregado = () => {
  toast.success(
    "Documento agregado con éxito, recuerda guardar el caso para mantener estos cambios",
    {
      position: toast.POSITION.TOP_CENTER,
    }
  );
};

const paso_a_seguimiento = () => {
  toast.success("Caso pasa a fase de Seguimiento con éxito", {
    position: toast.POSITION.TOP_CENTER,
  });
};

const archivo_adjunto_exitoso = () => {
  toast.success("Archivo subido con éxito !", {
    position: toast.POSITION.TOP_CENTER,
  });
};

const archivo_adjunto_fallido = () => {
  toast.warning(
    "Problemas con la subida del archivo. Por favor, inténtelo nuevamente",
    {
      position: toast.POSITION.TOP_CENTER,
    }
  );
};

const derivado = () => {
  toast.success("Caso fue archivado exitosamente", {
    position: toast.POSITION.TOP_CENTER,
  });
};

export {
  archivo_adjunto_exitoso,
  archivo_adjunto_fallido,
  derivado,
  documento_agregado,
  notify,
  paso_a_fiscalia,
  paso_a_seguimiento,
};
