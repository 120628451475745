import {
  etapas,
  delitos,
  comunas,
  derivadores,
  mediadores,
  acuerdos,
} from "../../components/data/opciones";
import { dateArray } from "../../funciones/funcionesAuxiliares";

const delitosFormateados = delitos.map((delito) => {
  return delito.charAt(0) + delito.slice(1).toLowerCase();
});

const gradientesTitulos = {
  Ingreso: "#fc5164",
  Ingreso2: "#a8475d",
  Premediación: "#ffc726",
  Premediación2: "#fc5164",
  Mediación: "#30b783",
  Mediación2: "#1b41b5",
  Seguimiento: "#a943d8",
  Seguimiento2: "#1b41b5",
  Cierre: "#2178FD",
  Cierre2: "#0a36a5",
  Santiago: "#fc5164",
  Santiago2: "#a8475d",
  Amenazas: "#fc5164",
  Amenazas2: "#a8475d",
  Fiscalía: "#fc5164",
  Fiscalía2: "#a8475d",
  Convocatorias: "#fc5164",
  Convocatorias2: "#a8475d",
  "Mónica Valdés": "#fc5164",
  "Mónica Valdés2": "#a8475d",
  "Estación Central": "#a8457d",
  "Estación Central2": "#f54107",
  Estafas: "#a8457d",
  Estafas2: "#f54107",
  "Quinta Normal": "#ff7c26",
  "Quinta Normal2": "#fdb151",
  Hurtos: "#ff7c26",
  Hurtos2: "#fdb151",
  "Estefanie Villalobos": "#ff7c26",
  "Estefanie Villalobos2": "#fdb151",
  Independencia: "#ff7c26",
  Independencia2: "#fc5164",
  "Apropiación indebida": "#ff7c26",
  "Apropiación indebida2": "#fc5164",
  "Fiscal a cargo": "#ff7c26",
  "Fiscal a cargo2": "#fc5164",
  Recoleta: "#30b783",
  Recoleta2: "#236ca3",
  Defensor: "#30b783",
  Defensor2: "#236ca3",
  Entrevistas: "#30b783",
  Entrevistas2: "#236ca3",
  "Natalia Droguett": "#30b783",
  "Natalia Droguett2": "#236ca3",
  "Cuasidelito de lesiones": "#30b783",
  "Cuasidelito de lesiones2": "#236ca3",
  "Cerro Navia": "#236ca3",
  "Cerro Navia2": "#1b41b5",
  "Cuasidelito de homicidio": "#236ca3",
  "Cuasidelito de homicidio2": "#1b41b5",
  "Lo Prado": "#1b41b5",
  "Lo Prado2": "#17134f",
  "Cuasidelito vehículo motorizado ley tránsito": "#1b41b5",
  "Cuasidelito vehículo motorizado ley tránsito2": "#17134f",
  Renca: "#a943d8",
  Renca2: "#17134f",
  "Daños simples": "#a943d8",
  "Daños simples2": "#17134f",
  Juez: "#a943d8",
  Juez2: "#17134f",
  "Ana Fuentes": "#a943d8",
  "Ana Fuentes2": "#17134f",
  Conchalí: "#1b41b5",
  Conchalí2: "#a943d8",
  "Lesiones leves": "#1b41b5",
  "Lesiones leves2": "#a943d8",
  Quilicura: "#db43d8",
  Quilicura2: "#1b41b5",
  "Lesiones menos graves": "#db43d8",
  "Lesiones menos graves2": "#1b41b5",
  Huechuraba: "#db43d8",
  Huechuraba2: "#f197b5",
  "Lesiones graves": "#db43d8",
  "Lesiones graves2": "#f197b5",
  Colina: "#f197b5",
  Colina2: "#f17d43",
  Otros: "#f197b5",
  Otros2: "#f17d43",
  Otro: "#f197b5",
  Otro2: "#f17d43",
  "Sesiones Mediación": "#f197b5",
  "Sesiones Mediación2": "#f17d43",
  "Carolina Contreras": "#f197b5",
  "Carolina Contreras2": "#f17d43",
  Lampa: "#f19f43",
  Lampa2: "#ff5226",
  "Til Til": "#ff5226",
  "Til Til2": "#a8475d",
  "Disculpas privadas":"#fc5164",
  "Disculpas privadas2":"#a8475d",
  "Disculpas públicas":"##ff7c26",
  "Disculpas públicas2":"#fdb151",
  "Pago en dinero":"#30b783",
  "Pago en dinero2":"#236ca3",
  "Pago en especies":"#a943d8",
  "Pago en especies2":"#1b41b5",
  "Restitución o reparación":"#1b41b5",
  "Restitución o reparación2":"#17134f",
  "Sin acuerdo": "#db43d8",
  "Sin acuerdo2": "#f197b5",
  "En curso": "#f19f43",
  "En curso2": "#ff5226",
}

const colores = {
  Ingreso: "#fc5164",
  Ingreso2: "#a8475d",
  Premediación: "#ffc726",
  Premediación2: "#fc5164",
  Mediación: "#30b783",
  Mediación2: "#1b41b5",
  Seguimiento: "#a943d8",
  Seguimiento2: "#1b41b5",
  Cierre: "#2178FD",
  Cierre2: "#0a36a5",
};

const gradientes = {
  "1_1": "#fc5164",
  "1_2": "#a8475d",
  "2_1": "#a8457d",
  "2_2": "#f54107",
  "3_1": "#ff7c26",
  "3_2": "#fdb151",
  "4_1": "#ff7c26",
  "4_2": "#fc5164",
  "5_1": "#30b783",
  "5_2": "#236ca3",
  "6_1": "#236ca3",
  "6_2": "#1b41b5",
  "7_1": "#1b41b5",
  "7_2": "#17134f",
  "8_1": "#a943d8",
  "8_2": "#17134f",
  "9_1": "#1b41b5",
  "9_2": "#a943d8",
  "10_1": "#db43d8",
  "10_2": "#1b41b5",
  "11_1": "#db43d8",
  "11_2": "#f197b5",
  "12_1": "#f197b5",
  "12_2": "#f17d43",
  "13_1": "#f19f43",
  "13_2": "#ff5226",
  "14_1": "#ff5226",
  "14_2": "#a8475d",
};

const keysChart = {
  Etapa: etapas,
  Delitos: delitosFormateados,
  Comunas: comunas,
  Derivadores: derivadores,
  Mediadores: mediadores,
  Actividades: ["Convocatorias", "Entrevistas", "Sesiones Mediación"],
  "Tipo Acuerdo": [...acuerdos, "Sin acuerdo", "En curso" ]
};
const defEtapa = [
  {
    id: "gradientIngreso",
    type: "linearGradient",
    colors: [
      { offset: 0, color: colores["Ingreso"] },
      { offset: 100, color: colores["Ingreso2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "gradientPremediacion",
    type: "linearGradient",
    colors: [
      { offset: 0, color: colores["Premediación"] },
      { offset: 100, color: colores["Premediación2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "gradientMediacion",
    type: "linearGradient",
    colors: [
      { offset: 0, color: colores["Mediación"] },
      { offset: 100, color: colores["Mediación2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "gradientSeguimiento",
    type: "linearGradient",
    colors: [
      { offset: 0, color: colores["Seguimiento"] },
      { offset: 100, color: colores["Seguimiento2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "gradientCierre",
    type: "linearGradient",
    colors: [
      { offset: 0, color: colores["Cierre"] },
      { offset: 100, color: colores["Cierre2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
];
const fillEtapa = [
  // match using object query
  { match: { id: "Ingreso" }, id: "gradientIngreso" },
  { match: { id: "Premediación" }, id: "gradientPremediacion" },
  { match: { id: "Mediación" }, id: "gradientMediacion" },
  { match: { id: "Seguimiento" }, id: "gradientSeguimiento" },
  { match: { id: "Cierre" }, id: "gradientCierre" },
];

const defGradientes = [
  {
    id: "1",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["1_1"] },
      { offset: 100, color: gradientes["1_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "2",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["2_1"] },
      { offset: 100, color: gradientes["2_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "3",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["3_1"] },
      { offset: 100, color: gradientes["3_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "4",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["4_1"] },
      { offset: 100, color: gradientes["4_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "5",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["5_1"] },
      { offset: 100, color: gradientes["5_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "6",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["6_1"] },
      { offset: 100, color: gradientes["6_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "7",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["7_1"] },
      { offset: 100, color: gradientes["7_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "8",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["8_1"] },
      { offset: 100, color: gradientes["8_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "9",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["9_1"] },
      { offset: 100, color: gradientes["9_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "10",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["10_1"] },
      { offset: 100, color: gradientes["10_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "11",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["11_1"] },
      { offset: 100, color: gradientes["11_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "12",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["12_1"] },
      { offset: 100, color: gradientes["12_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "13",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["13_1"] },
      { offset: 100, color: gradientes["13_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
  {
    id: "14",
    type: "linearGradient",
    colors: [
      { offset: 0, color: gradientes["14_1"] },
      { offset: 100, color: gradientes["14_2"] },
    ],
    gradientTransform: "rotate(90 0.5 0.5)",
  },
];
const fillGradientes = [
  // match using object query
  { match: { id: "Santiago" }, id: "1" },
  { match: { id: "Amenazas" }, id: "1" },
  { match: { id: "Disculpas privadas" }, id: "1" },
  { match: { id: "Fiscalía" }, id: "1" },
  { match: { id: "Mónica Valdés" }, id: "1" },
  { match: { id: "Convocatorias" }, id: "1" },
  { match: { id: "Estación Central" }, id: "2" },
  { match: { id: "Estafas" }, id: "2" },
  { match: { id: "Quinta Normal" }, id: "3" },
  { match: { id: "Hurtos" }, id: "3" },
  { match: { id: "Disculpas públicas" }, id: "3" },
  { match: { id: "Estefanie Villalobos" }, id: "3" },
  { match: { id: "Independencia" }, id: "4" },
  { match: { id: "Apropiación indebida" }, id: "4" },
  { match: { id: "Fiscal a cargo" }, id: "4" },
  { match: { id: "Recoleta" }, id: "5" },
  { match: { id: "Defensor" }, id: "5" },
  { match: { id: "Entrevistas" }, id: "5" },
  { match: { id: "Natalia Droguett" }, id: "5" },
  { match: { id: "Cuasidelito de lesiones" }, id: "5" },
  { match: { id: "Pago en dinero" }, id: "5" },
  { match: { id: "Cerro Navia" }, id: "6" },
  { match: { id: "Cuasidelito de homicidio" }, id: "6" },
  { match: { id: "Lo Prado" }, id: "7" },
  { match: { id: "Pago en especies" }, id: "7" },
  { match: { id: "Cuasidelito vehículo motorizado ley tránsito" }, id: "7" },
  { match: { id: "Renca" }, id: "8" },
  { match: { id: "Daños simples" }, id: "8" },
  { match: { id: "Juez" }, id: "8" },
  { match: { id: "Ana Fuentes" }, id: "8" },
  { match: { id: "Conchalí" }, id: "9" },
  { match: { id: "Lesiones leves" }, id: "9" },
  { match: { id: "Restitución o reparación" }, id: "9" },
  { match: { id: "Quilicura" }, id: "10" },
  { match: { id: "Lesiones menos graves" }, id: "10" },
  { match: { id: "Huechuraba" }, id: "11" },
  { match: { id: "Sin acuerdo" }, id: "11" },
  { match: { id: "Lesiones graves" }, id: "11" },
  { match: { id: "Colina" }, id: "12" },
  { match: { id: "Otros" }, id: "12" },
  { match: { id: "Otro" }, id: "12" },
  { match: { id: "Sesiones Mediación" }, id: "12" },
  { match: { id: "Carolina Contreras" }, id: "12" },
  { match: { id: "Lampa" }, id: "13" },
  { match: { id: "En curso" }, id: "13" },
  { match: { id: "Til Til" }, id: "14" },
];

export { defEtapa, fillEtapa, keysChart, defGradientes, fillGradientes, gradientesTitulos };
