import FiltrosBusquedas from "../layout/FiltrosBusquedas";
import IndicadorCard from "../tarjetas/IndicadorCard";
import classes from "./Indicadores.module.css";
import { useState } from "react";
import { filtrosDefault, filtrosParticipanteDefault } from "../data/opciones";
import moment from "moment";

function Indicadores(props) {
  const allCasos = props.casos;
  const activarFiltro = props.activarFiltro;
  const setActivarFiltro = props.setActivarFiltro;
  const [isSatisfaccionOpen, setSatisfaccionOpen] = useState(false);
  const [isFlujoOpen, setFlujoOpen] = useState(false);
  const [isMediadoresOpen, setMediadoresOpen] = useState(false);
  const [casosFiltrados, setCasosFiltrados] = useState(props.casos);
  const [isFiltrandoCasos, setIsFiltradoCasos] = useState(true);
  const [isCargandoData, setIsCargandoData] = useState(true);

  //Filtros disponibles
  const [filtros, setFiltros] = useState(filtrosDefault);
  const [filtrosVictima, setFiltrosVictima] = useState(
    filtrosParticipanteDefault
  );
  const [filtrosOfensor, setFiltrosOfensor] = useState(
    filtrosParticipanteDefault
  );
  const [filtrosConcurrente, setFiltrosConcurrente] = useState(
    filtrosParticipanteDefault
  );
  const [fechaDesdeIngreso, setFechaDesdeIngreso] = useState(
    moment(new Date("2023-05-01")).format("YYYY-MM-DD")
  );
  const [fechaHastaIngreso, setFechaHastaIngreso] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [fechaDesdeCierre, setFechaDesdeCierre] = useState(
    moment(new Date("2023-05-01")).format("YYYY-MM-DD")
  );
  const [fechaHastaCierre, setFechaHastaCierre] = useState(
    moment().format("YYYY-MM-DD")
  );

  function roundTwo(x) {
    return Number.parseFloat(x).toFixed(2);
  }

  //Filtra sólo casos con encuestas respondidas
  const infoExtraida = () => {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Inicializadores
    const encuestasIngreso = [];
    const encuestasPremediacion = [];
    const encuestasMediacion = [];
    const encuestasSeguimiento = [];
    const encuestasCierre = [];
    let nParticipantes = 0;
    let victimasEncuestadas = 0;
    let ofensoresEncuestadas = 0;
    let nCasosLleganMediacion = 0;
    let nCasosLleganPremediacion = 0;
    let nSatisfechosJusticia = 0;
    let nSatisfechosJusticiaV = 0;
    let nSatisfechosJusticiaO = 0;
    let ajusteJusticia = 0;
    let ajusteJusticiaV = 0;
    let ajusteJusticiaO = 0;
    let nSatisfechosExperiencia = 0;
    let nSatisfechosExperienciaV = 0;
    let nSatisfechosExperienciaO = 0;
    let ajusteExperiencia = 0;
    let ajusteExperienciaV = 0;
    let ajusteExperienciaO = 0;
    let nSatisfechosCreacionAcuerdo = 0;
    let nSatisfechosCreacionAcuerdoV = 0;
    let nSatisfechosCreacionAcuerdoO = 0;
    let ajusteCreacionAcuerdo = 0;
    let ajusteCreacionAcuerdoV = 0;
    let ajusteCreacionAcuerdoO = 0;
    let nSatisfechosMediadora = 0;
    let nSatisfechosMediadoraV = 0;
    let nSatisfechosMediadoraO = 0;
    let ajusteMediadora = 0;
    let ajusteMediadoraV = 0;
    let ajusteMediadoraO = 0;
    let nSatisfechosInfraestructura = 0;
    let nSatisfechosInfraestructuraV = 0;
    let nSatisfechosInfraestructuraO = 0;
    let ajusteInfraestructura = 0;
    let ajusteInfraestructuraV = 0;
    let ajusteInfraestructuraO = 0;
    let nExitoEnMediacion = 0;
    let nExitoEnSeguimiento = 0;
    let nExitoEnCierre = 0;
    let nCerradosEnIngreso = 0;
    let nRecomendaria = 0;
    let nRecomendariaV = 0;
    let nRecomendariaO = 0;
    let ajusteRecomendaria = 0;
    let ajusteRecomendariaV = 0;
    let ajusteRecomendariaO = 0;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Funciones de filtro
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra sólo casos con encuesta
    const casosConEncuesta = casosFiltrados.filter((caso) => {
      return Object.keys(caso.encuestas).length !== 0;
    });
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra casos cerrados en ingreso
    const cierreEnIngreso = casosFiltrados.filter((caso) => {
      try {
        return (
          Object.keys(caso.premediacionData).length === 0 &&
          caso.estado === "finalizado"
        );
      } catch {
        console.log(caso);
        return false;
      }
    });
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra casos cerrados en premediación
    const cierreEnPremediacion = casosFiltrados.filter((caso) => {
      return (
        Object.keys(caso.premediacionData).length !== 0 &&
        Object.keys(caso.mediacionData).length === 0 &&
        caso.estado === "finalizado"
      );
    });
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra casos que hayan pasado a mediación
    const casosLlegadaMediacion = casosFiltrados.filter((caso) => {
      return Object.keys(caso.mediacionData).length !== 0;
    });
    nCasosLleganMediacion = casosLlegadaMediacion.length;
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra casos que pasaron a premediación
    const casosLlegadaPremediacion = casosFiltrados.filter((caso) => {
      return Object.keys(caso.premediacionData).length !== 0;
    });
    nCasosLleganPremediacion = casosLlegadaPremediacion.length;
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra sólo casos que tengan salida en mediación con acuerdo cumplido
    const casosSalidaExitosaMediacion = casosLlegadaMediacion.filter((caso) => {
      return caso.mediacionData
        ? caso.mediacionData.estado_actual_mediacion ===
            "Cierre con acuerdo cumplido"
        : false;
    });
    nExitoEnMediacion = casosSalidaExitosaMediacion.length;
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra sólo casos que salen de seguimiento cumplido
    const casosSalidaExitosaSeguimiento = casosLlegadaMediacion.filter(
      (caso) => {
        return caso.seguimientoData
          ? [
              "Sí totalmente",
              "Sí parcialmente -Víctima conforme-",
              //"Sí parcialmente -Víctima disconforme-",
            ].includes(caso.seguimientoData.cumple_acuerdo)
          : false;
      }
    );
    nExitoEnSeguimiento = casosSalidaExitosaSeguimiento.length;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Procesos de iteración
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Pushea encuestas respondida y encuentra número de participantes
    for (const caso of casosConEncuesta) {
      let arrayVictimas = [];
      let arrayOfensores = [];
      for (const victima of caso.ingresoData.victimas) {
        arrayVictimas.push(victima.nombres + " " + victima.apellidos);
      }
      for (const ofensor of caso.ingresoData.ofensores) {
        arrayOfensores.push(ofensor.nombres + " " + ofensor.apellidos);
      }
      nParticipantes =
        nParticipantes +
        caso.ingresoData.victimas.length +
        caso.ingresoData.ofensores.length;
      for (const encuesta of Object.keys(caso.encuestas)) {
        if (arrayVictimas.indexOf(encuesta) !== -1)
          victimasEncuestadas = victimasEncuestadas + 1;
        if (arrayOfensores.indexOf(encuesta) !== -1)
          ofensoresEncuestadas = ofensoresEncuestadas + 1;
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Suma casos en que indicadores de satisfacción son buenos
        //Justicia y su ajustador
        if (caso.encuestas[encuesta].satisfaccion.justicia >= 6) {
          nSatisfechosJusticia = nSatisfechosJusticia + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            nSatisfechosJusticiaV = nSatisfechosJusticiaV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            nSatisfechosJusticiaO = nSatisfechosJusticiaO + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.justicia === 0 ||
          caso.encuestas[encuesta].satisfaccion.justicia === " " ||
          caso.encuestas[encuesta].satisfaccion.justicia === ""
        ) {
          ajusteJusticia = ajusteJusticia + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            ajusteJusticiaV = ajusteJusticiaV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            ajusteJusticiaO = ajusteJusticiaO + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Experiencia general y ajustador
        if (caso.encuestas[encuesta].satisfaccion.experiencia >= 6) {
          nSatisfechosExperiencia = nSatisfechosExperiencia + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            nSatisfechosExperienciaV = nSatisfechosExperienciaV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            nSatisfechosExperienciaO = nSatisfechosExperienciaO + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.experiencia === 0 ||
          caso.encuestas[encuesta].satisfaccion.experiencia === " " ||
          caso.encuestas[encuesta].satisfaccion.experiencia === ""
        ) {
          ajusteExperiencia = ajusteExperiencia + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            ajusteExperienciaV = ajusteExperienciaV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            ajusteExperienciaO = ajusteExperienciaO + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Creación del acuerdo y ajustador
        if (caso.encuestas[encuesta].satisfaccion.participacion >= 6) {
          nSatisfechosCreacionAcuerdo = nSatisfechosCreacionAcuerdo + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            nSatisfechosCreacionAcuerdoV = nSatisfechosCreacionAcuerdoV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            nSatisfechosCreacionAcuerdoO = nSatisfechosCreacionAcuerdoO + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.participacion === 0 ||
          caso.encuestas[encuesta].satisfaccion.participacion === " " ||
          caso.encuestas[encuesta].satisfaccion.participacion === ""
        ) {
          ajusteCreacionAcuerdo = ajusteCreacionAcuerdo + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            ajusteCreacionAcuerdoV = ajusteCreacionAcuerdoV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            ajusteCreacionAcuerdoO = ajusteCreacionAcuerdoO + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Mediadora y ajustador
        if (caso.encuestas[encuesta].satisfaccion.trabajoMediadora >= 6) {
          nSatisfechosMediadora = nSatisfechosMediadora + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            nSatisfechosMediadoraV = nSatisfechosMediadoraV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            nSatisfechosMediadoraO = nSatisfechosMediadoraO + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.trabajoMediadora === 0 ||
          caso.encuestas[encuesta].satisfaccion.trabajoMediadora === " " ||
          caso.encuestas[encuesta].satisfaccion.trabajoMediadora === ""
        ) {
          ajusteMediadora = ajusteMediadora + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            ajusteMediadoraV = ajusteMediadoraV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            ajusteMediadoraO = ajusteMediadoraO + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Infraestructura y ajustador
        if (caso.encuestas[encuesta].satisfaccion.calidadInfraestructura >= 6) {
          nSatisfechosInfraestructura = nSatisfechosInfraestructura + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            nSatisfechosInfraestructuraV = nSatisfechosInfraestructuraV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            nSatisfechosInfraestructuraO = nSatisfechosInfraestructuraO + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.calidadInfraestructura === 0 ||
          caso.encuestas[encuesta].satisfaccion.calidadInfraestructura ===
            " " ||
          caso.encuestas[encuesta].satisfaccion.calidadInfraestructura === ""
        ) {
          ajusteInfraestructura = ajusteInfraestructura + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            ajusteInfraestructuraV = ajusteInfraestructuraV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            ajusteInfraestructuraO = ajusteInfraestructuraO + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Recomiendan y ajustador
        if (caso.encuestas[encuesta].afirmacion.recomendaria === 3) {
          nRecomendaria = nRecomendaria + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            nRecomendariaV = nRecomendariaV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            nRecomendariaO = nRecomendariaO + 1;
        }
        if (
          caso.encuestas[encuesta].afirmacion.recomendaria === 0 ||
          caso.encuestas[encuesta].afirmacion.recomendaria === " " ||
          caso.encuestas[encuesta].afirmacion.recomendaria === ""
        ) {
          ajusteRecomendaria = ajusteRecomendaria + 1;
          if (arrayVictimas.indexOf(encuesta) !== -1)
            ajusteRecomendariaV = ajusteRecomendariaV + 1;
          if (arrayOfensores.indexOf(encuesta) !== -1)
            ajusteRecomendariaO = ajusteRecomendariaO + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Pushea las encuestas donde corresponde
        if (caso.etapa === "Ingreso") {
          encuestasIngreso.push(caso.encuestas[encuesta]);
        } else if (caso.etapa === "Premediación") {
          encuestasPremediacion.push(caso.encuestas[encuesta]);
        } else if (caso.etapa === "Mediación") {
          encuestasMediacion.push(caso.encuestas[encuesta]);
        } else if (caso.etapa === "Seguimiento") {
          encuestasSeguimiento.push(caso.encuestas[encuesta]);
        } else if (caso.etapa === "Cierre") {
          encuestasCierre.push(caso.encuestas[encuesta]);
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      }
    }

    return {
      nCasos: casosFiltrados.length,
      casosConEncuesta: casosConEncuesta.length,
      encuestasIngreso: encuestasIngreso,
      encuestasPremediacion: encuestasPremediacion,
      encuestasMediacion: encuestasMediacion,
      encuestasSeguimiento: encuestasSeguimiento,
      encuestasCierre: encuestasCierre,
      nParticipantes: nParticipantes,
      victimasEncuestadas: victimasEncuestadas,
      ofensoresEncuestadas: ofensoresEncuestadas,
      nCasosLleganPremediacion: nCasosLleganPremediacion,
      nCasosLleganMediacion: nCasosLleganMediacion,
      nExitoEnMediacion: nExitoEnMediacion,
      nExitoEnSeguimiento: nExitoEnSeguimiento,
      nSatisfechosJusticia: nSatisfechosJusticia,
      nSatisfechosJusticiaV: nSatisfechosJusticiaV,
      nSatisfechosJusticiaO: nSatisfechosJusticiaO,
      nSatisfechosExperiencia: nSatisfechosExperiencia,
      nSatisfechosExperienciaV: nSatisfechosExperienciaV,
      nSatisfechosExperienciaO: nSatisfechosExperienciaO,
      nSatisfechosCreacionAcuerdo: nSatisfechosCreacionAcuerdo,
      nSatisfechosCreacionAcuerdoV: nSatisfechosCreacionAcuerdoV,
      nSatisfechosCreacionAcuerdoO: nSatisfechosCreacionAcuerdoO,
      nSatisfechosMediadora: nSatisfechosMediadora,
      nSatisfechosMediadoraV: nSatisfechosMediadoraV,
      nSatisfechosMediadoraO: nSatisfechosMediadoraO,
      nSatisfechosInfraestructura: nSatisfechosInfraestructura,
      nSatisfechosInfraestructuraV: nSatisfechosInfraestructuraV,
      nSatisfechosInfraestructuraO: nSatisfechosInfraestructuraO,
      nRecomendaria: nRecomendaria,
      nRecomendariaV: nRecomendariaV,
      nRecomendariaO: nRecomendariaO,
      nCerradoEnIngreso: cierreEnIngreso.length,
      nCerradoEnPremediacion: cierreEnPremediacion.length,
      ajusteCreacionAcuerdo: ajusteCreacionAcuerdo,
      ajusteExperiencia: ajusteExperiencia,
      ajusteInfraestructura: ajusteInfraestructura,
      ajusteJusticia: ajusteJusticia,
      ajusteMediadora: ajusteMediadora,
      ajusteCreacionAcuerdoV: ajusteCreacionAcuerdoV,
      ajusteExperienciaV: ajusteExperienciaV,
      ajusteInfraestructuraV: ajusteInfraestructuraV,
      ajusteJusticiaV: ajusteJusticiaV,
      ajusteMediadoraV: ajusteMediadoraV,
      ajusteCreacionAcuerdoO: ajusteCreacionAcuerdoO,
      ajusteExperienciaO: ajusteExperienciaO,
      ajusteInfraestructuraO: ajusteInfraestructuraO,
      ajusteJusticiaO: ajusteJusticiaO,
      ajusteMediadoraO: ajusteMediadoraO,
      ajusteRecomendaria: ajusteRecomendaria,
      ajusteRecomendariaV: ajusteRecomendariaV,
      ajusteRecomendariaO: ajusteRecomendariaO,
    };
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Sumatorias de datos
  const data = infoExtraida();
  const nCasos = data.nCasos;
  const encuestasIngreso = data.encuestasIngreso;
  const encuestasPremediacion = data.encuestasPremediacion;
  const encuestasMediacion = data.encuestasMediacion;
  const encuestasSeguimiento = data.encuestasSeguimiento;
  const encuestasCierre = data.encuestasCierre;
  const nEncuestasIngreso = encuestasIngreso.length;
  const nEncuestasPremediacion = encuestasPremediacion.length;
  const nEncuestasMediacion = encuestasMediacion.length;
  const nEncuestasSeguimiento = encuestasSeguimiento.length;
  const nEncuestasCierre = encuestasCierre.length;
  const nParticipantes = data.nParticipantes;
  const nCasosLleganMediacion = data.nCasosLleganMediacion;
  const nCasosLleganPremediacion = data.nCasosLleganPremediacion;
  const nExitoEnMediacion = data.nExitoEnMediacion;
  const nExitoEnSeguimiento = data.nExitoEnSeguimiento;
  const nSatisfechosJusticia = data.nSatisfechosJusticia;
  const nSatisfechosExperiencia = data.nSatisfechosExperiencia;
  const nSatisfechosCreacionAcuerdo = data.nSatisfechosCreacionAcuerdo;
  const nSatisfechosMediadora = data.nSatisfechosMediadora;
  const nSatisfechosInfraestructura = data.nSatisfechosInfraestructura;
  const nSatisfechosJusticiaV = data.nSatisfechosJusticiaV;
  const nSatisfechosExperienciaV = data.nSatisfechosExperienciaV;
  const nSatisfechosCreacionAcuerdoV = data.nSatisfechosCreacionAcuerdoV;
  const nSatisfechosMediadoraV = data.nSatisfechosMediadoraV;
  const nSatisfechosInfraestructuraV = data.nSatisfechosInfraestructuraV;
  const nSatisfechosJusticiaO = data.nSatisfechosJusticiaO;
  const nSatisfechosExperienciaO = data.nSatisfechosExperienciaO;
  const nSatisfechosCreacionAcuerdoO = data.nSatisfechosCreacionAcuerdoO;
  const nSatisfechosMediadoraO = data.nSatisfechosMediadoraO;
  const nSatisfechosInfraestructuraO = data.nSatisfechosInfraestructuraO;
  const nCerradoEnIngreso = data.nCerradoEnIngreso;
  const nCerradoEnPremediacion = data.nCerradoEnPremediacion;
  const ajusteCreacionAcuerdo = data.ajusteCreacionAcuerdo;
  const ajusteExperiencia = data.ajusteExperiencia;
  const ajusteInfraestructura = data.ajusteInfraestructura;
  const ajusteRecomendaria = data.ajusteRecomendaria;
  const ajusteJusticia = data.ajusteJusticia;
  const ajusteMediadora = data.ajusteMediadora;
  const casosConEncuesta = data.casosConEncuesta;
  const ajusteCreacionAcuerdoV = data.ajusteCreacionAcuerdoV;
  const ajusteExperienciaV = data.ajusteExperienciaV;
  const ajusteInfraestructuraV = data.ajusteInfraestructuraV;
  const ajusteRecomendariaV = data.ajusteRecomendariaV;
  const ajusteJusticiaV = data.ajusteJusticiaV;
  const ajusteMediadoraV = data.ajusteMediadoraV;
  const ajusteCreacionAcuerdoO = data.ajusteCreacionAcuerdoO;
  const ajusteExperienciaO = data.ajusteExperienciaO;
  const ajusteInfraestructuraO = data.ajusteInfraestructuraO;
  const ajusteRecomendariaO = data.ajusteRecomendariaO;
  const ajusteJusticiaO = data.ajusteJusticiaO;
  const ajusteMediadoraO = data.ajusteMediadoraO;
  const victimasEncuestadas = data.victimasEncuestadas;
  const ofensoresEncuestadas = data.ofensoresEncuestadas;
  const nRecomendaria = data.nRecomendaria;
  const nRecomendariaV = data.nRecomendariaV;
  const nRecomendariaO = data.nRecomendariaO;
  const sumaPremediacionAdelante =
    nEncuestasPremediacion +
    nEncuestasMediacion +
    nEncuestasSeguimiento +
    nEncuestasCierre;
  const sumaMediacionAdelante =
    nEncuestasMediacion + nEncuestasSeguimiento + nEncuestasCierre;
  const sumaSeguimientoAdelante = nEncuestasSeguimiento + nEncuestasCierre;
  const sumaEncuestas =
    nEncuestasIngreso +
    nEncuestasPremediacion +
    nEncuestasMediacion +
    nEncuestasSeguimiento +
    nEncuestasCierre;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Indicadores:
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% participantes que declaran estar satisfechos con el nivel de justicia alcanzado en el proceso
  const iSatisfaccionJusticia =
    sumaEncuestas - ajusteJusticia > 0
      ? roundTwo(
          (100 * nSatisfechosJusticia) / (sumaEncuestas - ajusteJusticia)
        ) + "%"
      : "Faltan datos";
  const labelJusticia =
    sumaEncuestas - ajusteJusticia > 0
      ? nSatisfechosJusticia + "/" + (sumaEncuestas - ajusteJusticia)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% participantes que declaran estar satisfechos con la experiencia general del proceso de mediación
  const iSatisfaccionProceso =
    sumaEncuestas - ajusteExperiencia > 0
      ? roundTwo(
          (100 * nSatisfechosExperiencia) / (sumaEncuestas - ajusteExperiencia)
        ) + "%"
      : "Faltan datos";
  const labelProceso =
    sumaEncuestas - ajusteExperiencia > 0
      ? nSatisfechosExperiencia + "/" + (sumaEncuestas - ajusteExperiencia)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% usuarios satisfechos con su participación en la creación del acuerdo
  const iSatisfaccionAcuerdo =
    sumaEncuestas - ajusteCreacionAcuerdo > 0
      ? roundTwo(
          (100 * nSatisfechosCreacionAcuerdo) /
            (sumaEncuestas - ajusteCreacionAcuerdo)
        ) + "%"
      : "Faltan Datos";
  const labelSatisfaccion =
    sumaEncuestas - ajusteCreacionAcuerdo > 0
      ? nSatisfechosCreacionAcuerdo +
        "/" +
        (sumaEncuestas - ajusteCreacionAcuerdo)
      : "Faltan Datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes satisfechos con la función del mediador
  const iSatisfaccionMediador =
    sumaEncuestas - ajusteMediadora > 0
      ? roundTwo(
          (100 * nSatisfechosMediadora) / (sumaEncuestas - ajusteMediadora)
        ) + "%"
      : "Faltan datos";
  const labelMediador =
    sumaEncuestas - ajusteMediadora > 0
      ? nSatisfechosMediadora + "/" + (sumaEncuestas - ajusteMediadora)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes satisfechos con la infraestructura
  const iSatisfaccionInfraestructura =
    sumaPremediacionAdelante - ajusteInfraestructura > 0
      ? roundTwo(
          (100 * nSatisfechosInfraestructura) /
            (sumaPremediacionAdelante - ajusteInfraestructura)
        ) + "%"
      : "Faltan datos";
  const labelInsfraestructura =
    sumaPremediacionAdelante - ajusteInfraestructura > 0
      ? nSatisfechosInfraestructura +
        "/" +
        (sumaPremediacionAdelante - ajusteInfraestructura)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes que recomendarian
  const iRecomendarian =
    sumaPremediacionAdelante - ajusteRecomendaria > 0
      ? roundTwo(
          (100 * nRecomendaria) /
            (sumaPremediacionAdelante - ajusteRecomendaria)
        ) + "%"
      : "Faltan datos";
  const labelRecomendaria =
    sumaPremediacionAdelante - ajusteRecomendaria > 0
      ? nRecomendaria +
        "/" +
        (sumaPremediacionAdelante - ajusteRecomendaria)
      : "Faltan datos";

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% participantes que declaran estar satisfechos con el nivel de justicia alcanzado en el proceso
  const iSatisfaccionJusticiaV =
    victimasEncuestadas - ajusteJusticiaV > 0
      ? roundTwo(
          (100 * nSatisfechosJusticiaV) /
            (victimasEncuestadas - ajusteJusticiaV)
        ) + "%"
      : "Faltan datos";
  const labelJusticiaV =
    victimasEncuestadas - ajusteJusticiaV > 0
      ? nSatisfechosJusticiaV + "/" + (victimasEncuestadas - ajusteJusticiaV)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% participantes que declaran estar satisfechos con la experiencia general del proceso de mediación
  const iSatisfaccionProcesoV =
    victimasEncuestadas - ajusteExperienciaV > 0
      ? roundTwo(
          (100 * nSatisfechosExperienciaV) /
            (victimasEncuestadas - ajusteExperienciaV)
        ) + "%"
      : "Faltan datos";
  const labelProcesoV =
    victimasEncuestadas - ajusteExperienciaV > 0
      ? nSatisfechosExperienciaV +
        "/" +
        (victimasEncuestadas - ajusteExperienciaV)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% usuarios satisfechos con su participación en la creación del acuerdo
  const iSatisfaccionAcuerdoV =
    victimasEncuestadas - ajusteCreacionAcuerdoV > 0
      ? roundTwo(
          (100 * nSatisfechosCreacionAcuerdoV) /
            (victimasEncuestadas - ajusteCreacionAcuerdoV)
        ) + "%"
      : "Faltan Datos";
  const labelSatisfaccionV =
    victimasEncuestadas - ajusteCreacionAcuerdoV > 0
      ? nSatisfechosCreacionAcuerdoV +
        "/" +
        (victimasEncuestadas - ajusteCreacionAcuerdoV)
      : "Faltan Datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes satisfechos con la función del mediador
  const iSatisfaccionMediadorV =
    victimasEncuestadas - ajusteMediadoraV > 0
      ? roundTwo(
          (100 * nSatisfechosMediadoraV) /
            (victimasEncuestadas - ajusteMediadoraV)
        ) + "%"
      : "Faltan datos";
  const labelMediadorV =
    victimasEncuestadas - ajusteMediadoraV > 0
      ? nSatisfechosMediadoraV + "/" + (victimasEncuestadas - ajusteMediadoraV)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes satisfechos con la infraestructura
  const iSatisfaccionInfraestructuraV =
    victimasEncuestadas - ajusteInfraestructuraV > 0
      ? roundTwo(
          (100 * nSatisfechosInfraestructuraV) /
            (victimasEncuestadas - ajusteInfraestructuraV)
        ) + "%"
      : "Faltan datos";
  const labelInsfraestructuraV =
    victimasEncuestadas - ajusteInfraestructuraV > 0
      ? nSatisfechosInfraestructuraV +
        "/" +
        (victimasEncuestadas - ajusteInfraestructuraV)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes que recomendarian
  const iRecomendarianV =
    victimasEncuestadas - ajusteRecomendariaV > 0
      ? roundTwo(
          (100 * nRecomendariaV) /
            (victimasEncuestadas - ajusteRecomendariaV)
        ) + "%"
      : "Faltan datos";
  const labelRecomendariaV =
    victimasEncuestadas - ajusteRecomendariaV > 0
      ? nRecomendariaV +
        "/" +
        (victimasEncuestadas - ajusteRecomendariaV)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% participantes que declaran estar satisfechos con el nivel de justicia alcanzado en el proceso
  const iSatisfaccionJusticiaO =
    ofensoresEncuestadas - ajusteJusticiaO > 0
      ? roundTwo(
          (100 * nSatisfechosJusticiaO) /
            (ofensoresEncuestadas - ajusteJusticiaO)
        ) + "%"
      : "Faltan datos";
  const labelJusticiaO =
    ofensoresEncuestadas - ajusteJusticiaO > 0
      ? nSatisfechosJusticiaO + "/" + (ofensoresEncuestadas - ajusteJusticiaO)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% participantes que declaran estar satisfechos con la experiencia general del proceso de mediación
  const iSatisfaccionProcesoO =
    ofensoresEncuestadas - ajusteExperienciaO > 0
      ? roundTwo(
          (100 * nSatisfechosExperienciaO) /
            (ofensoresEncuestadas - ajusteExperienciaO)
        ) + "%"
      : "Faltan datos";
  const labelProcesoO =
    ofensoresEncuestadas - ajusteExperienciaO > 0
      ? nSatisfechosExperienciaO +
        "/" +
        (ofensoresEncuestadas - ajusteExperienciaO)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% usuarios satisfechos con su participación en la creación del acuerdo
  const iSatisfaccionAcuerdoO =
    ofensoresEncuestadas - ajusteCreacionAcuerdoO > 0
      ? roundTwo(
          (100 * nSatisfechosCreacionAcuerdoO) /
            (ofensoresEncuestadas - ajusteCreacionAcuerdoO)
        ) + "%"
      : "Faltan Datos";
  const labelSatisfaccionO =
    ofensoresEncuestadas - ajusteCreacionAcuerdoO > 0
      ? nSatisfechosCreacionAcuerdoO +
        "/" +
        (ofensoresEncuestadas - ajusteCreacionAcuerdoO)
      : "Faltan Datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes satisfechos con la función del mediador
  const iSatisfaccionMediadorO =
    ofensoresEncuestadas - ajusteMediadoraO > 0
      ? roundTwo(
          (100 * nSatisfechosMediadoraO) /
            (ofensoresEncuestadas - ajusteMediadoraO)
        ) + "%"
      : "Faltan datos";
  const labelMediadorO =
    ofensoresEncuestadas - ajusteMediadoraO > 0
      ? nSatisfechosMediadoraO + "/" + (ofensoresEncuestadas - ajusteMediadoraO)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes satisfechos con la infraestructura
  const iSatisfaccionInfraestructuraO =
    ofensoresEncuestadas - ajusteInfraestructuraO > 0
      ? roundTwo(
          (100 * nSatisfechosInfraestructuraO) /
            (ofensoresEncuestadas - ajusteInfraestructuraO)
        ) + "%"
      : "Faltan datos";
  const labelInsfraestructuraO =
    ofensoresEncuestadas - ajusteInfraestructuraO > 0
      ? nSatisfechosInfraestructuraO +
        "/" +
        (ofensoresEncuestadas - ajusteInfraestructuraO)
      : "Faltan datos";
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes que recomendarian
  const iRecomendarianO =
    ofensoresEncuestadas - ajusteRecomendariaO > 0
      ? roundTwo(
          (100 * nRecomendariaO) /
            (ofensoresEncuestadas - ajusteRecomendariaO)
        ) + "%"
      : "Faltan datos";
  const labelRecomendariaO =
    ofensoresEncuestadas - ajusteRecomendariaO > 0
      ? nRecomendariaO +
        "/" +
        (ofensoresEncuestadas - ajusteRecomendariaO)
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% casos que rechazan en ingreso
  const iCasosRechazadosIngreso =
    roundTwo((100 * nCerradoEnIngreso) / nCasos) + "%";
  const labelRechazadosIngreso = nCerradoEnIngreso + "/" + nCasos;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% casos que inician procesos de mediación vs casos derivadors
  const iCasosLleganMediacion =
    roundTwo((100 * nCasosLleganMediacion) / nCasos) + "%";
  const labelLleganMediacion = nCasosLleganMediacion + "/" + nCasos;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% casos que inician procesos de mediación vs casos derivadors
  const iCasosLleganPremediacion =
    roundTwo((100 * nCasosLleganPremediacion) / nCasos) + "%";
  const labelLleganPremediacion = nCasosLleganPremediacion + "/" + nCasos;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% casos que inician procesos de mediación vs casos derivadors
  const iCasosCierranPremediacion =
    roundTwo((100 * nCerradoEnPremediacion) / nCasos) + "%";
  const labelCierranPremediacion = nCerradoEnPremediacion + "/" + nCasos;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Tasa de mediaciones por mediador
  const iMediacionesMediador = nCasosLleganMediacion / 4;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Tasa de premediaciones por mediador
  const iPremediacionMedidor = nCasosLleganPremediacion / 4;

  return (
    <div className={classes.rowContainer}>
      <div className={classes.cajaFiltros}>
        <FiltrosBusquedas
          estadistica={false}
          setIsCargandoData={props.setIsCargandoCasos}
          casos={props.casos}
          setCasosFiltrados={setCasosFiltrados}
          setIsFiltradoCasos={setIsFiltradoCasos}
          filtros={filtros}
          setFiltros={setFiltros}
          filtrosVictima={filtrosVictima}
          setFiltrosVictima={setFiltrosVictima}
          filtrosOfensor={filtrosOfensor}
          setFiltrosOfensor={setFiltrosOfensor}
          filtrosConcurrente={filtrosConcurrente}
          setFiltrosConcurrente={setFiltrosConcurrente}
          ejeX={[]}
          setEjeX={() => {}}
          fechaDesdeIngreso={fechaDesdeIngreso}
          fechaHastaIngreso={fechaHastaIngreso}
          fechaDesdeCierre={fechaDesdeCierre}
          fechaHastaCierre={fechaHastaCierre}
          setFechaDesdeIngreso={setFechaDesdeIngreso}
          setFechaHastaIngreso={setFechaHastaIngreso}
          setFechaDesdeCierre={setFechaDesdeCierre}
          setFechaHastaCierre={setFechaHastaCierre}
          activarFiltro={activarFiltro}
          setActivarFiltro={setActivarFiltro}
        ></FiltrosBusquedas>
      </div>
      <div className={classes.mainContainer}>
        <div
          className={[classes.satisfaccionTitle].join(" ")}
          onClick={() => {
            setSatisfaccionOpen(!isSatisfaccionOpen);
          }}
        >
          Indicadores de satisfacción
        </div>
        {isSatisfaccionOpen ? (
          <div className={classes.indicadoresContainer}>
            <div className={classes.globalTitle}>Global</div>
            <div className={classes.track}>
              <IndicadorCard
                titulo={"Total encuestas"}
                indicador={sumaEncuestas}
                color={"Azul"}
              />
              <IndicadorCard
                titulo={"Satisfacción con "}
                negrita={"justicia alcanzada"}
                indicador={iSatisfaccionJusticia}
                color={"Azul"}
                label={labelJusticia}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfacción con "}
                negrita={"proceso en general"}
                indicador={iSatisfaccionProceso}
                color={"Azul"}
                label={labelProceso}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfechos con el "}
                negrita={"trabajo del Mediador(a)"}
                color={"Azul"}
                indicador={iSatisfaccionMediador}
                label={labelMediador}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfechos con la "}
                negrita={"infraestructura"}
                indicador={iSatisfaccionInfraestructura}
                color={"Azul"}
                label={labelInsfraestructura}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfechos con la "}
                negrita={"creación del acuerdo"}
                indicador={iSatisfaccionAcuerdo}
                color={"Azul"}
                label={labelSatisfaccion}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Recomendaría "}
                negrita={"participar del proceso"}
                indicador={iRecomendarian}
                color={"Azul"}
                label={labelRecomendaria}
                porcentajeBool={true}
              />
            </div>
            <div className={classes.participanteTitle}>Victimas</div>
            <div className={classes.track}>
              <IndicadorCard
                titulo={"Víctimas encuestadas"}
                indicador={victimasEncuestadas}
                color={"Azul"}
              />
              <IndicadorCard
                titulo={"Satisfacción con "}
                negrita={"justicia alcanzada"}
                indicador={iSatisfaccionJusticiaV}
                color={"Azul"}
                label={labelJusticiaV}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfacción con "}
                negrita={"proceso en general"}
                indicador={iSatisfaccionProcesoV}
                color={"Azul"}
                label={labelProcesoV}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfechos con el "}
                negrita={"trabajo del Mediador(a)"}
                color={"Azul"}
                label={labelMediadorV}
                indicador={iSatisfaccionMediadorV}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfechos con la "}
                negrita={"infraestructura"}
                indicador={iSatisfaccionInfraestructuraV}
                label={labelInsfraestructuraV}
                color={"Azul"}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfechos con la "}
                negrita={"creación del acuerdo"}
                indicador={iSatisfaccionAcuerdoV}
                label={labelSatisfaccionV}
                color={"Azul"}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Recomendaría "}
                negrita={"participar del proceso"}
                indicador={iRecomendarianV}
                color={"Azul"}
                label={labelRecomendariaV}
                porcentajeBool={true}
              />
            </div>
            <div className={classes.participanteTitle}>Ofensores</div>
            <div className={classes.track}>
              <IndicadorCard
                titulo={"Ofensores encuestados"}
                indicador={ofensoresEncuestadas}
                color={"Azul"}
              />
              <IndicadorCard
                titulo={"Satisfacción con "}
                negrita={"justicia alcanzada"}
                indicador={iSatisfaccionJusticiaO}
                label={labelJusticiaO}
                color={"Azul"}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfacción con "}
                negrita={"proceso en general"}
                indicador={iSatisfaccionProcesoO}
                label={labelProcesoO}
                color={"Azul"}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfechos con el "}
                negrita={"trabajo del Mediador(a)"}
                color={"Azul"}
                indicador={iSatisfaccionMediadorO}
                label={labelMediadorO}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfechos con la "}
                negrita={"infraestructura"}
                indicador={iSatisfaccionInfraestructuraO}
                label={labelInsfraestructuraO}
                color={"Azul"}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Satisfechos con la "}
                negrita={"creación del acuerdo"}
                indicador={iSatisfaccionAcuerdoO}
                label={labelSatisfaccionO}
                color={"Azul"}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Recomendaría "}
                negrita={"participar del proceso"}
                indicador={iRecomendarianO}
                color={"Azul"}
                label={labelRecomendariaO}
                porcentajeBool={true}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={classes.flujoTitle}
          onClick={() => {
            setFlujoOpen(!isFlujoOpen);
          }}
        >
          Indicadores de flujo de casos
        </div>
        {isFlujoOpen ? (
          <div className={classes.indicadoresContainer}>
            <div className={classes.track}>
              <IndicadorCard
                titulo={"Casos totales"}
                indicador={nCasos}
                color={"Naranja"}
              />
              <IndicadorCard
                titulo={"Casos rechazados en ingreso"}
                indicador={nCerradoEnIngreso}
                color={"Naranja"}
              />
              <IndicadorCard
                titulo={"Casos que pasan a premediación"}
                indicador={nCasosLleganPremediacion}
                color={"Naranja"}
              />
              <IndicadorCard
                titulo={"Casos que se cierran en premediación"}
                indicador={nCerradoEnPremediacion}
                color={"Naranja"}
              />
              <IndicadorCard
                titulo={"Casos que inician etapa de mediación"}
                indicador={nCasosLleganMediacion}
                color={"Naranja"}
              />
            </div>
            <div className={classes.track}>
              <div className={classes.emptyCard}></div>
              <IndicadorCard
                titulo={"Porcentaje rechazados en ingreso"}
                indicador={iCasosRechazadosIngreso}
                label={labelRechazadosIngreso}
                color={"Naranja"}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Porcentaje que pasan a premediación"}
                indicador={iCasosLleganPremediacion}
                label={labelLleganPremediacion}
                color={"Naranja"}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Porcentaje que cierran en premediación"}
                indicador={iCasosCierranPremediacion}
                label={labelCierranPremediacion}
                color={"Naranja"}
                porcentajeBool={true}
              />
              <IndicadorCard
                titulo={"Porcentaje que inician etapa de mediación"}
                indicador={iCasosLleganMediacion}
                label={labelLleganMediacion}
                color={"Naranja"}
                porcentajeBool={true}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={classes.mediadoresTitle}
          onClick={() => {
            setMediadoresOpen(!isMediadoresOpen);
          }}
        >
          Indicadores de mediadores
        </div>
        {isMediadoresOpen ? (
          <div className={classes.indicadoresContainer}>
            <div className={classes.track}>
              <IndicadorCard
                titulo={"Casos con encuesta"}
                indicador={casosConEncuesta}
                color={"Morado"}
                colores={["#a943d8", "#1b41b5"]}
              />
              <IndicadorCard
                titulo={"Encuestas realizadas"}
                indicador={sumaEncuestas}
                color={"Morado"}
                colores={["#a943d8", "#1b41b5"]}
              />
              <IndicadorCard
                titulo={"Casos atendidos por mediador(a)"}
                indicador={iPremediacionMedidor}
                color={"Morado"}
                colores={["#a943d8", "#1b41b5"]}
              />
              <IndicadorCard
                titulo={"Mediaciones atendidas por mediador(a)"}
                indicador={iMediacionesMediador}
                color={"Morado"}
                colores={["#a943d8", "#1b41b5"]}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default Indicadores;
