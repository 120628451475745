import {
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "./firebase_init";
import { toast } from "react-toastify";

const errorToast = () => {
  toast.error("Hay un problemas con sus credenciales, intente nuevamente", {
    position: toast.POSITION.TOP_CENTER,
  });
};

async function signIn(email, password) {
  try {
    await signInWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        const user = userCredential.user;
        return user;
      }
    );
  } catch (error) {
    errorToast();
    console.log(error);
  }
}

const logout = () => {
  signOut(auth);
};

export { signIn, logout, auth };
