import { firestore } from "./firebase_init";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";

async function userSignIn(data) {
  const userCollection = collection(firestore, "user");
  const userRef = doc(userCollection, data.uid);
  getDoc(userRef).then(async (docSnapshot) => {
    if (docSnapshot.exists()) {
      data.fecha_ultima_modificacion = Timestamp.now();
      await updateDoc(userRef, data);
    } else {
      await setDoc(userRef, data).then(async (docRef) => {
        await updateDoc(userRef, {
          uid: data.uid,
          fecha_ultima_modificacion: Timestamp.now(),
        });
      });
    }
  });
}

async function addCaso(data, toastNewCaso, toastSavedCaso, toastFail) {
  try {
    const ingresoCollection = collection(firestore, "ingreso");
    const historicoCollection = collection(firestore, "historico");
    if (data.id) {
      const casoRef = doc(ingresoCollection, data.id);
      data.fecha_ultima_modificacion = Timestamp.now();
      await updateDoc(casoRef, data);
      await updateDoc(data.historicoRef, {
        fecha_ultima_modificacion: Timestamp.now(),
      });
      toastSavedCaso();
      return { id: data.id, historicoRef: data.historicoRef };
    } else {
      let newId;
      let newHistorico;
      await addDoc(ingresoCollection, data)
        .then(async (docRef) => {
          newId = docRef.id;
          await updateDoc(docRef, {
            id: newId,
          });
          await addDoc(historicoCollection, {
            estado: "vigente",
            ruc: data.ruc,
            ingresoRef: docRef,
            fecha_ultima_modificacion: Timestamp.now(),
            fecha_ingreso: data.fecha_ingreso,
          }).then(async (docHistorico) => {
            newHistorico = docHistorico;
            await updateDoc(docRef, {
              historicoRef: docHistorico,
              fecha_ultima_modificacion: Timestamp.now(),
            });
            await updateDoc(docHistorico, {
              id: docHistorico.id,
            });
          });
          return docRef;
        })
        .then(async (docRef) => {
          const docSnap = await getDoc(docRef);
          let data = docSnap.data();
          toastNewCaso();
          return data;
        });
      return { id: newId, historicoRef: newHistorico };
    }
  } catch (error) {
    console.log(error);
    console.log(data);
    toastFail();
  }
}

async function addPremediacion(data, pasoPremediacion, errorGuardado) {
  try {
    const ingresoCollection = collection(firestore, "ingreso");
    const premediacionCollection = collection(firestore, "premediacion");
    let victimas = [];
    let ofensores = [];
    for (let i = 0; i < data.victimas.length; i++) {
      victimas.push({
        nombres: data.victimas[i].nombres,
        apellidos: data.victimas[i].apellidos,
        rut: data.victimas[i].rut,
      });
    }
    for (let i = 0; i < data.ofensores.length; i++) {
      ofensores.push({
        nombres: data.ofensores[i].nombres,
        apellidos: data.ofensores[i].apellidos,
        rut: data.ofensores[i].rut,
      });
    }

    let premediacion = {
      ruc: data.ruc,
      fecha_ingreso: data.fecha_ingreso,
      delito: data.delito,
      fecha_paso_premediacion: Timestamp.now(),
      fecha_ultima_modificacion: Timestamp.now(),
      estado_actual_victima: "Sin iniciar",
      estado_actual_ofensor: "Sin iniciar",
      estado: "premediacion",
      pasa_a_mediacion_bool: false,
      pasa_a_cierre_bool: false,
      mediador_asignado: data.mediador_asignado,
      comediador_asignado: data.comediador_asignado,
      actividades: [],
      ingresoRef: doc(ingresoCollection, data.id),
      victimas: victimas,
      ofensores: ofensores,
      adjuntos_premediacion: data.adjuntos_ingreso,
      historicoRef: data.historicoRef,
    };

    await updateDoc(doc(ingresoCollection, data.id), { estado: "finalizado" });
    await addDoc(premediacionCollection, premediacion).then(async (docRef) => {
      await updateDoc(docRef, {
        id: docRef.id,
      });
      await updateDoc(data.historicoRef, {
        premediacionRef: docRef,
        fecha_ultima_modificacion: Timestamp.now(),
        fecha_paso_premediacion: Timestamp.now(),
      });
    });
    pasoPremediacion();
  } catch (error) {
    console.log(error);
    errorGuardado();
  }
}

async function addMediacion(data) {
  const premediacionCollection = collection(firestore, "premediacion");
  const mediacionCollection = collection(firestore, "mediacion");

  let mediacion = {
    ingresoRef: data.ingresoRef,
    premediacionRef: doc(premediacionCollection, data.id),
    ruc: data.ruc,
    delito: data.delito,
    fecha_ingreso: data.fecha_ingreso,
    estado: "mediacion",
    mediador_asignado: data.mediador_asignado,
    comediador_asignado: data.comediador_asignado,
    fecha_paso_mediacion: Timestamp.now(),
    fecha_inicio_mediacion: Timestamp.now(),
    fecha_ultima_modificacion: Timestamp.now(),
    consentimiento_victima: data.consentimiento_victima,
    consentimiento_ofensor: data.consentimiento_ofensor,
    victimas: data.victimas,
    ofensores: data.ofensores,
    sesiones: [],
    estado_actual_mediacion: "Sin iniciar",
    adjuntos_mediacion: data.adjuntos_premediacion,
    historicoRef: data.historicoRef,
  };
  await updateDoc(doc(premediacionCollection, data.id), {
    estado: "finalizado",
  });
  await addDoc(mediacionCollection, mediacion).then(async (docRef) => {
    await updateDoc(docRef, {
      id: docRef.id,
    });
    await updateDoc(data.historicoRef, {
      mediacionRef: docRef,
      fecha_ultima_modificacion: Timestamp.now(),
      fecha_paso_mediacion: Timestamp.now(),
    });
  });
}

async function addSeguimiento(data) {
  const seguimientoCollection = collection(firestore, "seguimiento");
  const mediacionCollection = collection(firestore, "mediacion");
  let victimas = [];
  let ofensores = [];
  for (let i = 0; i < data.victimas.length; i++) {
    victimas.push({
      nombres: data.victimas[i].nombres,
      apellidos: data.victimas[i].apellidos,
      rut: data.victimas[i].rut,
    });
  }
  for (let i = 0; i < data.ofensores.length; i++) {
    ofensores.push({
      nombres: data.ofensores[i].nombres,
      apellidos: data.ofensores[i].apellidos,
      rut: data.ofensores[i].rut,
    });
  }

  let seguimiento = {
    ingresoRef: data.ingresoRef,
    premediacionRef: data.premediacionRef,
    mediacionRef: doc(mediacionCollection, data.id),
    fecha_inicio_seguimiento: Timestamp.now(),
    mediador_asignado: data.mediador_asignado,
    comediador_asignado: data.comediador_asignado,
    victimas: victimas,
    ofensores: ofensores,
    delito: data.delito,
    ruc: data.ruc,
    tipo_acuerdo: data.tipo_acuerdo,
    fecha_acuerdo: data.fecha_acuerdo, //TODO: Modificar por fecha de acuerdo
    acuerdo_adjunto: data.documento_seguimiento,
    estado: "seguimiento",
    fecha_paso_mediacion: Timestamp.now(),
    fecha_ultima_modificacion: Timestamp.now(),
    acciones_mediador: [],
    adjuntos_seguimiento: data.adjuntos_mediacion,
    historicoRef: data.historicoRef,
  };

  await updateDoc(doc(mediacionCollection, data.id), { estado: "finalizado" });
  await addDoc(seguimientoCollection, seguimiento).then(async (docRef) => {
    await updateDoc(docRef, {
      id: docRef.id,
    });
    await updateDoc(data.historicoRef, {
      seguimientoRef: docRef,
      fecha_ultima_modificacion: Timestamp.now(),
      fecha_inicio_seguimiento: Timestamp.now(),
    });
  });
}

async function addCierre(data) {
  const seguimientoCollection = collection(firestore, "seguimiento");
  const cierreCollection = collection(firestore, "cierre");
  let victimas = [];
  let ofensores = [];
  let concurrentes = [];
  for (let i = 0; i < data.victimas.length; i++) {
    victimas.push({
      nombres: data.victimas[i].nombres,
      apellidos: data.victimas[i].apellidos,
      rut: data.victimas[i].rut,
    });
  }
  for (let i = 0; i < data.ofensores.length; i++) {
    ofensores.push({
      nombres: data.ofensores[i].nombres,
      apellidos: data.ofensores[i].apellidos,
      rut: data.ofensores[i].rut,
    });
  }
  if (data.concurrentes) {
    for (let i = 0; i < data.concurrentes.length; i++) {
      concurrentes.push({
        nombres: data.concurrentes[i].nombres,
        apellidos: data.concurrentes[i].apellidos,
        rut: data.concurrentes[i].rut,
      });
    }
  }
  let cierre = {
    fecha_inicio_cierre: Timestamp.now(),
    fecha_ultima_modificacion: Timestamp.now(),
    ingresoRef: data.ingresoRef,
    premediacionRef: data.premediacionRef,
    mediacionRef: data.mediacionRef,
    seguimientoRef: doc(seguimientoCollection, data.id),
    ruc: data.ruc,
    delito: data.delito,
    victimas: victimas,
    ofensores: ofensores,
    concurrentes: concurrentes,
    mediador_asignado: data.mediador_asignado,
    comediador_asignado: data.comediador_asignado,
    fecha_envio_antecedentes_fiscalia: "",
    se_envia_con_acuerdo_bool: false,
    situacion_caso_mediacion: "",
    situacion_caso_penal: "",
    adjunto_ratificacion: data.adjunto_ratificacion,
    adjunto_acta_cumplimiento: data.adjunto_acta_cumplimiento,
    medida_tomada_por_fiscalia: "",
    fecha_principio_oportunidad: "",
    fecha_acuerdo_reparatorio: "",
    fecha_suspension_condicional: "",
    fecha_otro: "",
    observaciones_cierre_caso: "",
    cierre_caso_mediacion_bool: false,
    cierre_caso_penal_bool: false,
    estado: "cierre",
    adjuntos_cierre: data.adjuntos_seguimiento,
    historicoRef: data.historicoRef,
  };

  await updateDoc(doc(seguimientoCollection, data.id), {
    estado: "finalizado",
  });
  await addDoc(cierreCollection, cierre).then(async (docRef) => {
    await updateDoc(docRef, {
      id: docRef.id,
    });
    await updateDoc(data.historicoRef, {
      cierreRef: docRef,
      fecha_ultima_modificacion: Timestamp.now(),
      fecha_inicio_cierre: Timestamp.now(),
    });
  });
}

async function addCasoHistorico(data) {
  const cierreCollection = collection(firestore, "cierre");
  const historicosCollection = collection(firestore, "historicos");

  let victimas = [];
  let ofensores = [];
  let concurrentes = [];
  for (let i = 0; i < data.victimas.length; i++) {
    victimas.push({
      nombres: data.victimas[i].nombres,
      apellidos: data.victimas[i].apellidos,
      rut: data.victimas[i].rut,
    });
  }
  for (let i = 0; i < data.ofensores.length; i++) {
    ofensores.push({
      nombres: data.ofensores[i].nombres,
      apellidos: data.ofensores[i].apellidos,
      rut: data.ofensores[i].rut,
    });
  }
  if (data.concurrentes) {
    for (let i = 0; i < data.concurrentes.length; i++) {
      concurrentes.push({
        nombres: data.concurrentes[i].nombres,
        apellidos: data.concurrentes[i].apellidos,
        rut: data.concurrentes[i].rut,
      });
    }
  }

  let historicos = {
    fecha_archivado_caso: Timestamp.now(),
    ingresoRef: data.ingresoRef,
    premediacionRef: data.premediacionRef,
    mediacionRef: data.mediacionRef,
    seguimientoRef: data.seguimientoRef,
    cierreRef: doc(cierreCollection, data.id),
    ruc: data.ruc,
    delito: data.delito,
    victimas: victimas,
    ofensores: ofensores,
    concurrentes: concurrentes,
  };

  await updateDoc(doc(cierreCollection, data.id), {
    estado: "finalizado",
  });
  await addDoc(historicosCollection, historicos).then(async (docRef) => {
    await updateDoc(docRef, {
      id: docRef.id,
      fecha_ultima_modificacion: Timestamp.now(),
    });
  });
}

async function updatePremediacion(data) {
  return new Promise((resolve, reject) => {
    const premediacionCollection = collection(firestore, "premediacion");
    const premediacionRef = doc(premediacionCollection, data.id);
    const historicoRef = data.historicoRef;
    data.fecha_ultima_modificacion = Timestamp.now();

    updateDoc(premediacionRef, data).then(() => {
      updateDoc(historicoRef, {
        fecha_ultima_modificacion: Timestamp.now(),
      }).then(() => {
        resolve();
      });
    });
  });
}

async function updateMediacion(data) {
  const mediacionCollection = collection(firestore, "mediacion");
  const mediacionRef = doc(mediacionCollection, data.id);
  const historicoRef = data.historicoRef;
  data.fecha_ultima_modificacion = Timestamp.now();
  await updateDoc(mediacionRef, data);
  await updateDoc(historicoRef, { fecha_ultima_modificacion: Timestamp.now() });
}

async function updateSeguimiento(data) {
  const seguimientoCollection = collection(firestore, "seguimiento");
  const seguimientoRef = doc(seguimientoCollection, data.id);
  const historicoRef = data.historicoRef;
  data.fecha_ultima_modificacion = Timestamp.now();
  await updateDoc(seguimientoRef, data);
  await updateDoc(historicoRef, { fecha_ultima_modificacion: Timestamp.now() });
}

async function archivar(data, tipo) {
  console.log(data);
  const casoCollection = collection(firestore, tipo);
  const casoRef = doc(casoCollection, data.id);
  data.estado = "finalizado";
  data.fecha_ultima_modificacion = Timestamp.now();
  await updateDoc(casoRef, data);
  await updateDoc(data.historicoRef, {
    estado: "finalizado",
    fecha_cierre: Timestamp.now(),
    fecha_ultima_modificacion: Timestamp.now(),
  });
}

async function updateCierre(data) {
  const cierreCollection = collection(firestore, "cierre");
  const cierreRef = doc(cierreCollection, data.id);
  const historicoRef = data.historicoRef;
  data.fecha_ultima_modificacion = Timestamp.now();
  console.log(data);
  await updateDoc(cierreRef, data);
  await updateDoc(historicoRef, { fecha_ultima_modificacion: Timestamp.now() });
}

async function updateHistoricoCierrePenal(data, setIsCargandoCaso) {
  const historicoCollection = collection(firestore, "historico");
  const historicoRef = doc(historicoCollection, data.id);
  // data.fecha_cierre_penal = Timestamp.now();
  await updateDoc(historicoRef, {
    cierre_penal: data,
    fecha_ultima_modificacion: Timestamp.now(),
  });
  setIsCargandoCaso(true);
}

async function updateEncuesta(id, data, notify, error, setIsCargandoCaso) {
  try {
    const historicoCollection = collection(firestore, "historico");
    const historicoRef = doc(historicoCollection, id);
    data.fecha_ultima_modificacion = Timestamp.now();
    await updateDoc(historicoRef, data);
    setIsCargandoCaso(true);
    notify();
  } catch (e) {
    console.log(e);
    console.log(data);
    error();
  }
}

async function reparar(etapa, id, delito, setIsCargandoAllCasos) {
  const historicoCollection = collection(firestore, "historico");
  const historicoRef = doc(historicoCollection, id);
  const historicoSnap = await getDoc(historicoRef);
  let historicoData = historicoSnap.data();
  if (etapa === "Cierre") {
    const cierreDocSnap = await getDoc(historicoData.cierreRef);
    const seguimientoDocSnap = await getDoc(historicoData.seguimientoRef);
    const mediacionDocSnap = await getDoc(historicoData.mediacionRef);
    const premediacionDocSnap = await getDoc(historicoData.premediacionRef);
    const ingresoDocSnap = await getDoc(historicoData.ingresoRef);
    const refs = [
      cierreDocSnap.ref,
      seguimientoDocSnap.ref,
      mediacionDocSnap.ref,
      premediacionDocSnap.ref,
      ingresoDocSnap.ref,
    ];
    for (let ref of refs) {
      await updateDoc(ref, { delito: delito });
    }
    //setIsCargandoAllCasos(true);
    console.log("reparé");
  } else if (etapa === "Seguimiento") {
    const seguimientoDocSnap = await getDoc(historicoData.seguimientoRef);
    const mediacionDocSnap = await getDoc(historicoData.mediacionRef);
    const premediacionDocSnap = await getDoc(historicoData.premediacionRef);
    const ingresoDocSnap = await getDoc(historicoData.ingresoRef);
    const refs = [
      seguimientoDocSnap.ref,
      mediacionDocSnap.ref,
      premediacionDocSnap.ref,
      ingresoDocSnap.ref,
    ];
    for (let ref of refs) {
      await updateDoc(ref, { delito: delito });
    }
    //setIsCargandoAllCasos(true);
    console.log("reparé");
  } else if (etapa === "Mediación") {
    const mediacionDocSnap = await getDoc(historicoData.mediacionRef);
    const premediacionDocSnap = await getDoc(historicoData.premediacionRef);
    const ingresoDocSnap = await getDoc(historicoData.ingresoRef);
    const refs = [
      mediacionDocSnap.ref,
      premediacionDocSnap.ref,
      ingresoDocSnap.ref,
    ];
    for (let ref of refs) {
      await updateDoc(ref, { delito: delito });
    }
    //setIsCargandoAllCasos(true);
    console.log("reparé");
  } else if (etapa === "Premediación") {
    const premediacionDocSnap = await getDoc(historicoData.premediacionRef);
    const ingresoDocSnap = await getDoc(historicoData.ingresoRef);
    const refs = [premediacionDocSnap.ref, ingresoDocSnap.ref];
    for (let ref of refs) {
      await updateDoc(ref, { delito: delito });
    }
    // setIsCargandoAllCasos(true);
    console.log("reparé");
  } else if (etapa === "Ingreso") {
    const ingresoDocSnap = await getDoc(historicoData.ingresoRef);
    await updateDoc(ingresoDocSnap.ref, { delito: delito });
    console.log("reparé");
  }
}

async function changeCaso(data, setIsCargando) {
  const historicoCollection = collection(firestore, "historico");
  const historicoRef = doc(historicoCollection, data.idCaso);
  const historicoDocSnap = await getDoc(historicoRef);
  let historicoData = historicoDocSnap.data();
  await updateDoc(historicoData.ingresoRef, {
    delito: data.delito,
    fecha_ingreso: data.fecha_ingreso,
  }).then(async (response) => {
    await updateDoc(historicoRef, {
      fecha_ingreso: data.fecha_ingreso,
      fecha_ultima_modificacion: Timestamp.now(),
    }).then((response) => {
      console.log("Histórico actualizado");
      setIsCargando(true);
    });
  });
}

export {
  addCaso,
  addPremediacion,
  addMediacion,
  addSeguimiento,
  addCierre,
  addCasoHistorico,
  updatePremediacion,
  updateMediacion,
  updateSeguimiento,
  updateCierre,
  updateHistoricoCierrePenal,
  archivar,
  userSignIn,
  updateEncuesta,
  reparar,
  changeCaso,
};
