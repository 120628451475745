/*TODO: Generar componente de filtros y búsquedas que retorne arreglo de casos */
import classes from "./FiltrosBusquedas.module.css";
import FiltrosGenerales from "../estadistica/FiltrosGenerales";
import FiltrosParticipantes from "../estadistica/FiltrosParticipantes";
import { useMemo, useEffect } from "react";
import moment from "moment";
import {
  etapas,
  delitos,
  comunas,
  derivadores,
  mediadores,
  acuerdos,
} from "../data/opciones";
import Busquedas from "../estadistica/Busquedas";

function FiltrosBusquedas(props) {
  /*Búsqueda por RUC, RUT, Apellidos */
  //Filtros disponibles
  const activarFiltro = props.activarFiltro;
  const setActivarFiltro = props.setActivarFiltro;
  const setIsCargandoData = props.setIsCargandoData;
  const setCasosFiltrados = props.setCasosFiltrados;
  const filtros = props.filtros;
  const setFiltros = props.setFiltros;
  const filtrosVictima = props.filtrosVictima;
  const setFiltrosVictima = props.setFiltrosVictima;
  const filtrosOfensor = props.filtrosOfensor;
  const setFiltrosOfensor = props.setFiltrosOfensor;
  const filtrosConcurrente = props.filtrosConcurrente;
  const setFiltrosConcurrente = props.setFiltrosConcurrente;
  const ejeX = props.ejeX ? props.ejeX : [];
  const setEjeX = props.setEjeX ? props.setEjeX : () => {};
  const setFechaDesdeIngreso = props.setFechaDesdeIngreso
    ? props.setFechaDesdeIngreso
    : () => {};
  const setFechaHastaIngreso = props.setFechaHastaIngreso
    ? props.setFechaHastaIngreso
    : () => {};
  const setFechaDesdeCierre = props.setFechaDesdeCierre
    ? props.setFechaDesdeCierre
    : () => {};
  const setFechaHastaCierre = props.setFechaHastaCierre
    ? props.setFechaHastaCierre
    : () => {};
  const fechaDesdeIngreso = props.fechaDesdeIngreso
    ? props.fechaDesdeIngreso
    : "";
  const fechaHastaIngreso = props.fechaHastaIngreso
    ? props.fechaHastaIngreso
    : "";
  const fechaDesdeCierre = props.fechaDesdeCierre ? props.fechaDesdeCierre : "";
  const fechaHastaCierre = props.fechaHastaCierre ? props.fechaHastaCierre : "";
  /* Búsquedas disponibles */
  const rut = props.rut ? props.rut : "";
  const ruc = props.ruc ? props.ruc : "";
  const apellidos = props.apellidos ? props.apellidos : "";
  const setRut = props.setRut ? props.setRut : () => {};
  const setRuc = props.setRuc ? props.setRuc : () => {};
  const setApellidos = props.setApellidos ? props.setApellidos : () => {};

  const opcionesEjeX = props.opcionesEjeX ? props.opcionesEjeX : [];

  useEffect(() => {
    console.log("Ejecuté");
    let casosFiltrados = props.casos;
    if (fechaDesdeIngreso !== "") {
      casosFiltrados = casosFiltrados.filter((caso) => {
        if (caso.ingresoData["fecha_ingreso"] !== "") {
          return (
            moment(caso.ingresoData["fecha_ingreso"].toDate()).format(
              "YYYY-MM-DD"
            ) >= fechaDesdeIngreso
          );
        } else {
          console.log(caso.ruc);
          return false;
        }
      });
    }
    if (fechaHastaIngreso !== "") {
      casosFiltrados = casosFiltrados.filter((caso) => {
        if (caso.ingresoData["fecha_ingreso"] !== "") {
          return (
            moment(caso.ingresoData["fecha_ingreso"].toDate()).format(
              "YYYY-MM-DD"
            ) <= fechaHastaIngreso
          );
        } else {
          console.log(caso.ruc);
          return false;
        }
      });
    }
    //Filtros para fecha desde ingreso
    if (fechaDesdeCierre !== "" && filtros.estado === "finalizado") {
      casosFiltrados = casosFiltrados.filter((caso) => {
        if (caso.fecha_cierre) {
          return (
            moment(caso.fecha_cierre.toDate()).format("YYYY-MM-DD") >=
            fechaDesdeCierre
          );
        } else {
          console.log(caso.ruc);
          return false;
        }
      });
    }
    if (fechaHastaCierre !== "" && filtros.estado === "finalizado") {
      casosFiltrados = casosFiltrados.filter((caso) => {
        if (caso.fecha_cierre) {
          return (
            moment(caso.fecha_cierre.toDate()).format("YYYY-MM-DD") <=
            fechaHastaCierre
          );
        } else {
          console.log(caso.ruc);
          return false;
        }
      });
    }
    //Filtros para ingreso en general
    for (const [key, value] of Object.entries(filtros)) {
      if (key === "estado" || key === "etapa") {
        if (value !== "Todos") {
          casosFiltrados = casosFiltrados.filter((caso) => {
            return caso[key] === value;
          });
        }
      }
      if (key === "tipo_acuerdo") {
        if (value !== "Todos") {
          if (value === "Con acuerdo") {
            casosFiltrados = casosFiltrados.filter((caso) => {
              if (caso.mediacionData.length === 0) {
                return false;
              } else if (caso.mediacionData[key] === undefined) {
                return false;
              } else {
                return true;
              }
            });
          } else {
            casosFiltrados = casosFiltrados.filter((caso) => {
              if (caso.mediacionData.length === 0) {
                return false;
              } else if (caso.mediacionData[key] === undefined) {
                return false;
              } else {
                return caso.mediacionData[key].includes(value);
              }
            });
          }
        }
      } else if (value !== "Todos" && !(key === "estado" || key === "etapa")) {
        casosFiltrados = casosFiltrados.filter((caso) => {
          return caso.ingresoData[key] === value;
        });
      }
    }

    //Filtros para victimas
    for (const [key, value] of Object.entries(filtrosVictima)) {
      if (value !== "Todos") {
        const condicionFiltro = (victima) => victima[key] === value;
        casosFiltrados = casosFiltrados.filter((caso) =>
          caso.ingresoData["victimas"] !== ""
            ? caso.ingresoData["victimas"].some(condicionFiltro)
            : false
        );
      }
    }

    //Filtros para ofensores
    for (const [key, value] of Object.entries(filtrosOfensor)) {
      if (value !== "Todos") {
        const condicionFiltro = (ofensor) => ofensor[key] === value;
        casosFiltrados = casosFiltrados.filter((caso) =>
          caso.ingresoData["ofensores"] !== ""
            ? caso.ingresoData["ofensores"].some(condicionFiltro)
            : false
        );
      }
    }

    //Filtros para concurrentes
    for (const [key, value] of Object.entries(filtrosConcurrente)) {
      if (value !== "Todos") {
        const condicionFiltro = (concurrente) => concurrente[key] === value;
        casosFiltrados = casosFiltrados.filter((caso) =>
          caso.ingresoData["concurrentes"] !== ""
            ? caso.ingresoData["concurrentes"].some(condicionFiltro)
            : false
        );
      }
    }

    if (rut !== "") {
      let coincideRut = false;
      casosFiltrados = casosFiltrados.filter((caso, i) => {
        for (let victima of caso.ingresoData.victimas) {
          coincideRut = false;
          if (victima.rut.includes(rut.toString())) {
            coincideRut = true;
          }
        }
        for (let ofensor of caso.ingresoData.ofensores) {
          if (ofensor.rut.includes(rut.toString())) {
            coincideRut = true;
          }
        }
        for (let concurrente of caso.ingresoData.concurrentes) {
          if (concurrente.rut.includes(rut.toString())) {
            coincideRut = true;
          }
        }
        return coincideRut;
      });
    }
    if (ruc !== "") {
      casosFiltrados = casosFiltrados.filter((caso) => {
        return caso.ingresoData.ruc.includes(ruc);
      });
    }

    if (apellidos !== "") {
      let coincideApellidos = false;
      casosFiltrados = casosFiltrados.filter((caso, i) => {
        for (let victima of caso.ingresoData.victimas) {
          coincideApellidos = false;
          if (
            victima.apellidos
              .toUpperCase()
              .trim()
              .includes(apellidos.toUpperCase().trim())
          ) {
            coincideApellidos = true;
          }
        }
        for (let ofensor of caso.ingresoData.ofensores) {
          if (
            ofensor.apellidos
              .toUpperCase()
              .trim()
              .includes(apellidos.toUpperCase().trim())
          ) {
            coincideApellidos = true;
          }
        }
        for (let concurrente of caso.ingresoData.concurrentes) {
          if (
            concurrente.apellidos
              .toUpperCase()
              .trim()
              .includes(apellidos.toUpperCase().trim())
          ) {
            coincideApellidos = true;
          }
        }
        return coincideApellidos;
      });
    }
    setCasosFiltrados(casosFiltrados);
    setActivarFiltro(false);
  }, [
    activarFiltro,
    setActivarFiltro,
    filtros,
    filtrosVictima,
    filtrosOfensor,
    filtrosConcurrente,
    setCasosFiltrados,
    fechaDesdeIngreso,
    fechaHastaIngreso,
    fechaDesdeCierre,
    fechaHastaCierre,
    rut,
    ruc,
    apellidos,
    props.casos,
  ]);

  function addFiltro(tipo, campo, value) {
    if (tipo === "general") {
      let filtrosCopy = { ...filtros };
      filtrosCopy[campo] = value;
      setFiltros(filtrosCopy);
    }
    if (tipo === "victima") {
      let filtrosCopy = { ...filtrosVictima };
      filtrosCopy[campo] = value;
      setFiltrosVictima(filtrosCopy);
    }
    if (tipo === "ofensor") {
      let filtrosCopy = { ...filtrosOfensor };
      filtrosCopy[campo] = value;
      setFiltrosOfensor(filtrosCopy);
    }
    if (tipo === "concurrente") {
      let filtrosCopy = { ...filtrosConcurrente };
      filtrosCopy[campo] = value;
      setFiltrosConcurrente(filtrosCopy);
    }
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.filtrosColumn}>
        {props.estadistica ? (
          ""
        ) : (
          <Busquedas
            setRuc={setRuc}
            setRut={setRut}
            setApellidos={setApellidos}
          ></Busquedas>
        )}
        <FiltrosGenerales
          estadistica={props.estadistica}
          opcionesEjeX={opcionesEjeX}
          ejeX={ejeX}
          setIsCargandoData={setIsCargandoData}
          setEjeX={setEjeX}
          setFechaDesdeIngreso={setFechaDesdeIngreso}
          setFechaHastaIngreso={setFechaHastaIngreso}
          setFechaDesdeCierre={setFechaDesdeCierre}
          setFechaHastaCierre={setFechaHastaCierre}
          addFiltro={addFiltro}
        ></FiltrosGenerales>
        <FiltrosParticipantes
          titulo="Filtros Víctima"
          tipoParticipante="victima"
          addFiltro={addFiltro}
        ></FiltrosParticipantes>
        <FiltrosParticipantes
          titulo="Filtros Ofensor"
          tipoParticipante="ofensor"
          addFiltro={addFiltro}
        ></FiltrosParticipantes>
        <FiltrosParticipantes
          titulo="Filtros Concurrentes"
          tipoParticipante="concurrente"
          addFiltro={addFiltro}
        ></FiltrosParticipantes>
      </div>
    </div>
  );
}

export default FiltrosBusquedas;
