import classes from "./Cierre.module.css";
import { useState, useRef } from "react";
import { Drawer } from "antd";
import { postStorage } from "../../firebase/firebaseStorage";
import save from "../../img/icono_saved.png";
import attach from "../../img/icono_attach.png";
import black_clip from "../../img/black_clip.png";
import { Timestamp } from "firebase/firestore";
import download from "../../img/download.png";
import { toast } from "react-toastify";
import {
  updateCierre,
  archivar,
} from "../../firebase/firebasePostData";
import axios from "axios";
import fileDownload from "js-file-download";

function FormularioCierre(props) {
  const ingresoRef = props.caso.ingresoRef;
  const premediacionRef = props.caso.premediacionRef;
  const mediacionRef = props.caso.mediacionRef;
  const seguimientoRef = props.caso.seguimientoRef;
  const historicoRef = props.caso.historicoRef;
  const delito = props.caso.delito;
  const id = props.caso.id;
  const estado = props.caso.estado;
  const spanSubirRef = useRef(null);
  const inputNombreNuevoArchivoRef = useRef(null);
  const [fileNuevo, setFileNuevo] = useState("");

  const [nombreNuevoArchivo, setNombreNuevoArchivo] = useState("");

  const [adjuntos_cierre, setAdjuntos_cierre] = useState(
    props.caso.adjuntos_cierre ? props.caso.adjuntos_cierre : []
  );

  const [ruc, setRuc] = useState(props.caso.ruc ? props.caso.ruc : "");

  const [fecha_inicio_cierre, setFecha_inicio_cierre] = useState(
    props.caso.fecha_inicio_cierre ? props.caso.fecha_inicio_cierre : ""
  );
  const [victimas, setVictimas] = useState(
    props.caso.victimas ? props.caso.victimas : ""
  );
  const [ofensores, setOfensores] = useState(
    props.caso.ofensores ? props.caso.ofensores : ""
  );
  const [
    fecha_envio_antecedentes_fiscalia,
    setFecha_envio_antecedentes_fiscalia,
  ] = useState(
    props.caso.fecha_envio_antecedentes_fiscalia
      ? props.caso.fecha_envio_antecedentes_fiscalia
      : ""
  );
  const [se_envia_con_acuerdo, setSe_envia_con_acuerdo] = useState(
    props.caso.se_envia_con_acuerdo ? props.caso.se_envia_con_acuerdo : ""
  );
  const [situacion_caso_mediacion, setSituacion_caso_mediacion] = useState(
    props.caso.situacion_caso_mediacion
      ? props.caso.situacion_caso_mediacion
      : "Inicio proceso de cierre"
  );
  const [situacion_caso_penal, setSituacion_caso_penal] = useState(
    props.caso.situacion_caso_penal
      ? props.caso.situacion_caso_penal
      : "Inicio proceso de cierre"
  );
  const [adjunto_ratificacion, setAdjunto_ratificacion] = useState(
    props.caso.adjunto_ratificacion ? props.caso.adjunto_ratificacion : ""
  );
  const [adjunto_acta_cumplimiento, setAdjunto_acta_cumplimiento] = useState(
    props.caso.adjunto_acta_cumplimiento
      ? props.caso.adjunto_acta_cumplimiento
      : ""
  );
  const [medida_tomada_por_fiscalia, setMedida_tomada_por_fiscalia] = useState(
    props.caso.medida_tomada_por_fiscalia
      ? props.caso.medida_tomada_por_fiscalia
      : ""
  );
  const [fecha_principio_oportunidad, setFecha_principio_oportunidad] =
    useState(
      props.caso.fecha_principio_oportunidad
        ? props.caso.fecha_principio_oportunidad
        : ""
    );
  const [fecha_acuerdo_reparatorio, setFecha_acuerdo_reparatorio] = useState(
    props.caso.fecha_acuerdo_reparatorio
      ? props.caso.fecha_acuerdo_reparatorio
      : ""
  );
  const [fecha_suspension_condicional, setFecha_suspension_condicional] =
    useState(
      props.caso.fecha_suspension_condicional
        ? props.caso.fecha_suspension_condicional
        : ""
    );
  const [fecha_otro, setFecha_otro] = useState(
    props.caso.fecha_otro ? props.caso.fecha_otro : ""
  );
  const [observaciones_cierre_caso, setObservaciones_cierre_caso] = useState(
    props.caso.observaciones_cierre_caso
      ? props.caso.observaciones_cierre_caso
      : ""
  );
  const [cierre_caso_mediacion_bool, setCierre_caso_mediacion_bool] = useState(
    props.caso.cierre_caso_mediacion_bool
      ? props.caso.cierre_caso_mediacion_bool
      : ""
  );
  const [cierre_caso_penal_bool, setCierre_caso_penal_bool] = useState(
    props.caso.cierre_caso_penal_bool ? props.caso.cierre_caso_penal_bool : ""
  );

  const [mediador_asignado, setMediador_asignado] = useState(
    props.caso ? props.caso.mediador_asignado : ""
  );

  const [comediador_asignado, setComediador_asignado] = useState(
    props.caso ? props.caso.comediador_asignado : ""
  );

  // STATES MANEJO FRONTEND

  const [selectedOptionMedidaFiscalia, setSelectedOptionMedidaFiscalia] =
    useState(null);

  const handleOptionChangeMedidaFiscalia = (option) => {
    setSelectedOptionMedidaFiscalia(option);
    setMedida_tomada_por_fiscalia(option);
  };

  // STATES Y FUNCIONES DRAWER

  const [visible, setVisible] = useState(false);

  // STATES Y FUNCIONES MODALES

  const [
    mostrar_modal_cierre_caso_mediacion,
    setMostrar_modal_cierre_caso_mediacion,
  ] = useState(false);
  const [mostrar_modal_guardar, setMostrar_modal_guardar] = useState(false);

  const [mostrar_modal_cierre_caso_penal, setMostrar_modal_cierre_caso_penal] =
    useState(false);
  const [
    mostrar_modal_cierre_archivar_caso_historico,
    setMostrar_modal_cierre_archivar_caso_historico,
  ] = useState(false);

  const notify = () => {
    toast.success("Datos de Cierre guardados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const cierre_proceso_mediacion = () => {
    toast.success("Fase de Mediación del caso cierra con éxito", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const cierre_proceso_penal = () => {
    toast.success("Fase Penal del caso cierra con éxito", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const cierre_archivado = () => {
    toast.success("Caso cerrado y archivado con éxito", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const caso_mediacion_cerrado = () => {
    toast.error("Fase de Mediación del caso ya ha cerrado su proceso", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const caso_penal_cerrado = () => {
    toast.error("Fase Penal del caso ya ha cerrado su proceso", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const falta_para_archivado = () => {
    toast.error("Aún falta cerrar procesos para archivar el caso", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const falta_para_cierre_mediacion = () => {
    toast.error(
      "Para cerrar proceso de mediación debe llenar todos los campos obligatorios",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const falta_para_cierre_penal = () => {
    toast.error(
      "Para cerrar proceso penal debe llenar todos los campos obligatorios",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const documento_agregado = () => {
    toast.success(
      "Documento agregado con éxito, recuerda guardar el caso para mantener estos cambios",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const mostrarCierreMediacion = async () => {
    setMostrar_modal_cierre_caso_mediacion(true);
  };

  const mostrarCierrePenal = async () => {
    setMostrar_modal_cierre_caso_penal(true);
  };

  const mostrarCierreArchivado = async () => {
    setMostrar_modal_cierre_archivar_caso_historico(true);
  };

  const mostrarModalGuardado = async () => {
    setMostrar_modal_guardar(true);
  };

  function handleDate(timestamp) {
    if (timestamp) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "Sin información";
    }
  }

  let cierreData = {
    id: id,
    ruc: ruc,
    delito: delito,
    fecha_inicio_cierre: fecha_inicio_cierre,
    victimas: victimas,
    ofensores: ofensores,
    adjuntos_cierre: adjuntos_cierre,
    fecha_envio_antecedentes_fiscalia: fecha_envio_antecedentes_fiscalia,
    se_envia_con_acuerdo: se_envia_con_acuerdo,
    situacion_caso_mediacion: situacion_caso_mediacion,
    situacion_caso_penal: situacion_caso_penal,
    adjunto_ratificacion: adjunto_ratificacion,
    adjunto_acta_cumplimiento: adjunto_acta_cumplimiento,
    medida_tomada_por_fiscalia: medida_tomada_por_fiscalia,
    fecha_principio_oportunidad: fecha_principio_oportunidad,
    fecha_acuerdo_reparatorio: fecha_acuerdo_reparatorio,
    fecha_suspension_condicional: fecha_suspension_condicional,
    fecha_otro: fecha_otro,
    observaciones_cierre_caso: observaciones_cierre_caso,
    cierre_caso_mediacion_bool: cierre_caso_mediacion_bool,
    cierre_caso_penal_bool: cierre_caso_penal_bool,
    estado: estado,
    ingresoRef: ingresoRef,
    premediacionRef: premediacionRef,
    mediacionRef: mediacionRef,
    seguimientoRef: seguimientoRef,
    historicoRef: historicoRef,
    mediador_asignado: mediador_asignado,
    comediador_asignado: comediador_asignado,
  };

  function handleDownload(url, filename) {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  }

  const handleOpcionEnviar = (opcion) => {
    if (opcion !== se_envia_con_acuerdo) {
      setSe_envia_con_acuerdo(opcion);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.save} onClick={mostrarModalGuardado}>
        <img className={classes.saveIcon} src={save} alt="" />
      </div>
      <div
        onClick={() => {
          setVisible(true);
        }}
      >
        <img className={classes.attachIcon} src={attach} alt="" />
      </div>
      <div className={[classes.encabezado, classes.border1].join(" ")}>
        CIERRE
      </div>
      {/* Modal de guardado */}
      {mostrar_modal_guardar === true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>GUARDAR DATOS</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de guardar los datos del formulario, ¿como quieres
              continuar?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                await updateCierre(cierreData);
                notify();
                setMostrar_modal_guardar(false);
              }}
              className={classes.btn_seguir_en_form}
            >
              Guardar y seguir
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                await updateCierre(cierreData);
                notify();
                props.closeModal();
              }}
            >
              Guardar y salir
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {props.modal_cierre_visible == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>CERRAR FORMULARIO</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de cerrar el formulario, recuerda que cualquier
              cambio que no hayas guardado se perderá. Por favor, asegúrate de
              haber guardado tus cambios.
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                props.setModal_cierre_visible(false);
              }}
              className={classes.btn_seguir_en_form}
            >
              Seguir editando
            </button>
            <button
              onClick={async () => {
                await updateCierre(cierreData);
                notify();
                props.closeModal();
              }}
              className={classes.btn_guardar_en_form}
            >
              Guardar y salir
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                props.closeModal();
              }}
            >
              Salir sin guardar
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className={[classes.infoInicial]}>
        <div className={[classes.infoInicial_columna1]}>
          <div className={[classes.etiquetaCaso]}>
            <p>
              CASO: <span>{props.caso.ruc ? props.caso.ruc : "000"}</span>
            </p>
          </div>
          {/* <div>
            <p>
              Fecha de Ingreso{" "}
              <span className={[classes.etiquetaFechas]}>14/01/2023</span>
            </p>
          </div> */}
        </div>
        <div className={[classes.infoInicial_columna2]}>
          <div>
            <p>
              Situación caso mediación
              <span className={[classes.etiqueta_estado_cierre]}>
                {situacion_caso_mediacion}
              </span>
            </p>
          </div>
          <div>
            <p>
              Situación caso penal
              <span className={[classes.etiqueta_estado_cierre]}>
                {situacion_caso_penal}
              </span>
            </p>
          </div>
        </div>
      </div>
      <hr />
      {mostrar_modal_cierre_caso_mediacion ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>CIERRE CASO MEDIACION</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de cerrar el proceso del caso de Mediación, esto
              significa que ya no podrás realizar cambios con respecto a éste,
              ¿tienes seguridad de proceder?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                await archivar(cierreData, "cierre");
                setSituacion_caso_mediacion("Proceso de mediación cerrado");
                cierre_proceso_mediacion();
                setMostrar_modal_cierre_caso_mediacion(false);
                props.closeModal();
              }}
              className={classes.btn_seguir_en_form}
            >
              Cerrar Proceso de Mediación del Caso
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                setMostrar_modal_cierre_caso_mediacion(false);
              }}
            >
              Continuar editando
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {mostrar_modal_cierre_caso_penal ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>CIERRE CASO PENAL</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de cerrar el caso en su etapa Penal, esto significa
              que ya no podrás realizar cambios con respecto a éste, ¿tienes
              seguridad de proceder?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                setSituacion_caso_penal("Proceso penal cerrado");
                cierre_proceso_penal();
                setMostrar_modal_cierre_caso_penal(false);
              }}
              className={classes.btn_seguir_en_form}
            >
              Cerrar Proceso Penal del Caso
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                setMostrar_modal_cierre_caso_penal(false);
              }}
            >
              Continuar editando
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {mostrar_modal_cierre_archivar_caso_historico ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>
            CIERRE Y ARCHIVADO DEL CASO
          </div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de archivar el caso, esto significa que dejará de
              ser un caso activo para psar al set de históricos, ¿tienes
              seguridad de proceder?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                await updateCierre(cierreData);
                cierre_archivado();
                setMostrar_modal_cierre_archivar_caso_historico(false);
              }}
              className={classes.btn_seguir_en_form}
            >
              Archivar el caso
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                setMostrar_modal_cierre_archivar_caso_historico(false);
              }}
            >
              Continuar editando
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div id="contenido_cierre" className={classes.contenido_cierre}>
        <Drawer
          open={visible}
          title="Archivos Adjuntos Cierre"
          onClose={() => {
            setVisible(false);
          }}
        >
          <div className={classes.div_principal_drawer}>
            {/* Archivos provenientes de Database */}
            {adjunto_ratificacion != "" ? (
              <div>
                <p>Documento Firma/Ratificación Acuerdo</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      adjunto_ratificacion,
                      `Ratificacion_Acuerdo_Caso_${ruc}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
            ) : (
              ""
            )}
            {adjunto_acta_cumplimiento != "" ? (
              <div>
                <p>Documento Acta Cumplimiento</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      adjunto_acta_cumplimiento,
                      `Acta_Cumplimiento_Caso_${ruc}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
            ) : (
              ""
            )}
            <hr />
            <div className={[`${classes.nuevos_documentos}`]}>
              {adjuntos_cierre != undefined ? (
                <div>
                  {adjuntos_cierre.map((documento, index) => (
                    <div key={index}>
                      <p>{documento.nombre}</p>
                      <button
                        className={classes.btn_descarga_drawer}
                        onClick={() => {
                          handleDownload(
                            documento.archivo,
                            `${documento.nombre}.pdf`
                          );
                        }}
                      >
                        Descargar
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={classes.div_subir_nuevos_documentos}>
              <hr />
              <div className={classes.columna_subir_nuevo}>
                <p className={classes.subtitulo}>Subir Nuevo Documento</p>
                <label htmlFor="file-nuevo" className={classes.fileInputLabel}>
                  Seleccionar
                </label>
                <input
                  className={classes.fileInput}
                  type="file"
                  id="file-nuevo"
                  onChange={(e) => {
                    e.target.nextElementSibling.innerText =
                      e.target.files[0].name;
                    setFileNuevo(e.target.files[0]);
                  }}
                />
                <span
                  className={classes.fileName}
                  id="span_subir"
                  ref={spanSubirRef}
                ></span>
                <input
                  type="text"
                  placeholder="Ingrese nombre documento"
                  value={nombreNuevoArchivo}
                  ref={inputNombreNuevoArchivoRef}
                  onChange={(e) => setNombreNuevoArchivo(e.target.value)}
                />
                <button
                  className={classes.subir_nuevo_btn}
                  onClick={async () => {
                    if (fileNuevo !== "") {
                      let urlNuevo;
                      await postStorage(
                        "cierre/" + fileNuevo.name,
                        fileNuevo
                      ).then((archivoRutaDescarga) => {
                        urlNuevo = archivoRutaDescarga;

                        setAdjuntos_cierre([
                          ...adjuntos_cierre,
                          { nombre: nombreNuevoArchivo, archivo: urlNuevo },
                        ]);
                      });
                      await updateCierre(cierreData);

                      documento_agregado();
                    }
                  }}
                >
                  Subir documento
                </button>
              </div>
            </div>
          </div>
        </Drawer>

        <div>
          <h3>Cierre caso mediación</h3>
          <input
            type="date"
            onChange={(event) => {
              let newDate = new Date(event.target.value + "T00:00");
              let newTimestamp = Timestamp.fromDate(newDate);
              setFecha_envio_antecedentes_fiscalia(newTimestamp);
              /* setGuardado(false); */
            }}
            defaultValue={handleDate(fecha_envio_antecedentes_fiscalia)
              .split("-")
              .reverse()
              .join("-")}
          />
        </div>

        <div className={classes.dosPartes}>
          <div>
            <p className={classes.titulos_div}>Se envía</p>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={se_envia_con_acuerdo === "Si"}
                onChange={() => handleOpcionEnviar("Si")}
              />
              <span>Con acuerdo cumplido</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={se_envia_con_acuerdo === "No"}
                onChange={() => handleOpcionEnviar("No")}
              />
              <span>Con acuerdo sin cumplir</span>
            </div>
          </div>
          {adjunto_ratificacion != "" || adjunto_acta_cumplimiento != "" ? (
            <div>
              <p className={classes.titulos_div}>Adjuntos</p>
              <div
                className={classes.acuerdoPartes}
                onClick={() => {
                  handleDownload(
                    adjunto_ratificacion,
                    `Ratificacion_Acuerdo_Caso_${ruc}.pdf`
                  );
                }}
              >
                <img className={classes.blackClip} src={black_clip} alt="" />
                <p>
                  <i>Documento ratificación</i>{" "}
                </p>
                <img className={classes.download} src={download} alt="" />
              </div>
              <div
                className={classes.acuerdoPartes}
                onClick={() => {
                  handleDownload(
                    adjunto_acta_cumplimiento,
                    `Acta_Cumplimiento_Caso_${ruc}.pdf`
                  );
                }}
              >
                <img className={classes.blackClip} src={black_clip} alt="" />
                <p>
                  <i>Documento acta de cumplimiento</i>{" "}
                </p>
                <img className={classes.download} src={download} alt="" />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {situacion_caso_mediacion == "Proceso de mediación cerrado" ? (
          <div className={classes.div_boton_cierre}>
            <button
              className={classes.boton_cierre1_desactivado}
              onClick={caso_mediacion_cerrado}
            >
              CASO DE MEDIACIÓN CERRADO
            </button>
          </div>
        ) : fecha_envio_antecedentes_fiscalia != "" &&
          se_envia_con_acuerdo != "" ? (
          <div className={classes.div_boton_cierre}>
            <button
              className={classes.boton_cierre1}
              onClick={mostrarCierreMediacion}
            >
              CIERRE DEL CASO DE MEDIACIÓN
            </button>
          </div>
        ) : (
          <div className={classes.div_boton_cierre}>
            <button
              className={classes.boton_cierre1_desactivado}
              onClick={falta_para_cierre_mediacion}
            >
              CIERRE DEL CASO DE MEDIACIÓN
            </button>
          </div>
        )}

        <hr />
        {/* 
          <h2>Cierre caso penal</h2>
          <p className={classes.titulos_div}>
            Medida tomada por la Fiscalía en el caso
          </p>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={selectedOptionMedidaFiscalia === "principio_oportunidad"}
              onChange={() =>
                handleOptionChangeMedidaFiscalia("principio_oportunidad")
              }
            />
            <span>Principio de oportunidad</span>
          </div>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={selectedOptionMedidaFiscalia === "acuerdo_reparatorio"}
              onChange={() =>
                handleOptionChangeMedidaFiscalia("acuerdo_reparatorio")
              }
            />
            <span>Acuerdo reparatorio</span>
          </div>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={
                selectedOptionMedidaFiscalia === "suspension_condicional"
              }
              onChange={() =>
                handleOptionChangeMedidaFiscalia("suspension_condicional")
              }
            />
            <span>Suspensión condicional</span>
          </div>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={selectedOptionMedidaFiscalia === "otro"}
              onChange={() => handleOptionChangeMedidaFiscalia("otro")}
            />
            <span>Otro</span>
          </div>
         */}
        {selectedOptionMedidaFiscalia == "principio_oportunidad" ? (
          <div>
            <p className={classes.titulos_div}>
              Principio de oportunidad - Fecha de cierre Fiscalía
            </p>
            <input
              type="date"
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_principio_oportunidad(newTimestamp);
                /* setGuardado(false); */
              }}
              defaultValue={handleDate(fecha_principio_oportunidad)
                .split("-")
                .reverse()
                .join("-")}
            />
          </div>
        ) : (
          <div></div>
        )}
        {selectedOptionMedidaFiscalia == "acuerdo_reparatorio" ? (
          <div>
            <p className={classes.titulos_div}>
              Acuerdo reparatorio - Fecha audiencia
            </p>
            <input
              type="date"
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_acuerdo_reparatorio(newTimestamp);
                /* setGuardado(false); */
              }}
              defaultValue={handleDate(fecha_acuerdo_reparatorio)
                .split("-")
                .reverse()
                .join("-")}
            />
          </div>
        ) : (
          <div></div>
        )}
        {selectedOptionMedidaFiscalia == "suspension_condicional" ? (
          <div>
            <p className={classes.titulos_div}>
              Suspensión condicional - Fecha audiencia
            </p>
            <input
              type="date"
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_suspension_condicional(newTimestamp);
                /* setGuardado(false); */
              }}
              defaultValue={handleDate(fecha_suspension_condicional)
                .split("-")
                .reverse()
                .join("-")}
            />
          </div>
        ) : (
          <div></div>
        )}
        {selectedOptionMedidaFiscalia == "otro" ? (
          <div>
            <p className={classes.titulos_div}>
              Otro - Fecha de cierre del caso para Fiscalía
            </p>
            <input
              type="date"
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_otro(newTimestamp);
                /* setGuardado(false); */
              }}
              defaultValue={handleDate(fecha_otro)
                .split("-")
                .reverse()
                .join("-")}
            />
          </div>
        ) : (
          <div></div>
        )}

        {/* <div>
          <p className={classes.titulos_div}>Observaciones cierre</p>
          <textarea cols="40" rows="10"></textarea>
        </div> */}
        {/* <div>
          <button>GUARDAR</button>
        </div> */}
        {situacion_caso_penal == "Proceso penal cerrado" ? (
          <div className={classes.div_boton_cierre}>
            <button
              className={classes.boton_cierre1_desactivado}
              onClick={caso_penal_cerrado}
            >
              CASO PENAL CERRADO
            </button>
          </div>
        ) : medida_tomada_por_fiscalia != "" &&
          (fecha_acuerdo_reparatorio != "" ||
            fecha_principio_oportunidad != "" ||
            fecha_suspension_condicional != "" ||
            fecha_otro != "") ? (
          <div className={classes.div_boton_cierre}>
            {/* <button
              className={classes.boton_cierre1}
              onClick={mostrarCierrePenal}
            >
              CIERRE DEL CASO PENAL
            </button> */}
          </div>
        ) : (
          <div className={classes.div_boton_cierre}>
            {/* <button
              className={classes.boton_cierre1_desactivado}
              onClick={falta_para_cierre_penal}
            >
              CIERRE DEL CASO PENAL
            </button> */}
          </div>
        )}

        {/* <hr /> */}
        {situacion_caso_penal == "Proceso penal cerrado" &&
        situacion_caso_mediacion == "Proceso de mediación cerrado" ? (
          <div className={classes.div_boton_cierre_final}>
            <button
              className={classes.boton_cierre_final}
              onClick={mostrarCierreArchivado}
            >
              CERRAR Y ARCHIVAR CASO
            </button>
          </div>
        ) : (
          <div className={classes.div_boton_cierre_final}>
            {/* <button
              className={classes.boton_cierre_final_desactivado}
              onClick={falta_para_archivado}
            >
              CERRAR Y ARCHIVAR CASO
            </button> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default FormularioCierre;
