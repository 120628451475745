import { useState, useEffect } from "react";
import classes from "./CasosTrack.module.css";
import IngresoCard from "../tarjetas/IngresoCard";
import PremediacionCard from "../tarjetas/PremediacionCard";
import MediacionCard from "../tarjetas/MediacionCard";
import SeguimientoCard from "../tarjetas/SeguimientoCard";
import CierreCard from "../tarjetas/CierreCard";
import ModalIngreso from "../modal/IngresoModal";
import { useWhatChanged } from "@simbathesailor/use-what-changed";

function CasosTrack(props) {
  const isIngreso = props.tipoCaso === "panelIngreso";
  const isPremediacion = props.tipoCaso === "panelPremediacion";
  const isMediacion = props.tipoCaso === "panelMediacion";
  const isSeguimiento = props.tipoCaso === "panelSeguimiento";
  const isCierre = props.tipoCaso === "panelCierre";
  const hasCases = props.casos !== undefined;
  const [modalIngreso, setShowModalIngreso] = useState(false);
  const [ingresos, setIngresos] = useState([]);
  const [premediacion, setPremediacion] = useState([]);
  const [mediacion, setMediacion] = useState([]);
  const [seguimiento, setSeguimiento] = useState([]);
  const [cierre, setCierre] = useState([]);
  
  useEffect(() => {
    let casoArray = [];
    if (props.casos && isIngreso) {
      for (let i = 0; i < props.casos.length; i++) {
        casoArray.push(
          <IngresoCard
            key={"ingresoCard_" + props.casos[i]["id"]}
            caso={props.casos[i]}
          ></IngresoCard>
        );
      }
      setIngresos(casoArray);
    }
    if (props.casos && isPremediacion) {
      for (let i = 0; i < props.casos.length; i++) {
        casoArray.push(
          <PremediacionCard
            key={
              "premediacionCard_" + props.casos[i]["id"]
            }
            caso={props.casos[i]}
          ></PremediacionCard>
        );
      }
      setPremediacion(casoArray);
    }
    if (props.casos && isMediacion) {
      for (let i = 0; i < props.casos.length; i++) {
        casoArray.push(
          <MediacionCard
            key={
              "mediacionCard_" + props.casos[i]["id"]
            }
            caso={props.casos[i]}
          ></MediacionCard>
        );
      }
      setMediacion(casoArray);
    }
    if (props.casos && isSeguimiento) {
      for (let i = 0; i < props.casos.length; i++) {
        casoArray.push(
          <SeguimientoCard
            key={
              "seguimientoCard_" + props.casos[i]["id"]
            }
            caso={props.casos[i]}
          ></SeguimientoCard>
        );
      }
      setSeguimiento(casoArray);
    }
    if (props.casos && isCierre) {
      for (let i = 0; i < props.casos.length; i++) {
        casoArray.push(
          <CierreCard
            key={"cierreCard_" + props.casos[i]["id"]}
            caso={props.casos[i]}
          ></CierreCard>
        );
      }
      setCierre(casoArray);
    }
  }, [
    isCierre,
    isIngreso,
    isMediacion,
    isPremediacion,
    isSeguimiento,
    props.casos,
    props.casosFiltrados,
  ]);

  return (
    <div className={classes[props.tipoCaso]}>
      <div className={classes.panelContent}>
        <div className={classes.titleCard}>
          <p className={classes.title}>{props.title}</p>
          {hasCases ? (
            <div className={classes.notification}>{props.casos.length}</div>
          ) : (
            ""
          )}
        </div>
        <div className={classes.cardScroller} tipo-caso={props.tipoCaso}>
          {isIngreso
            ? ingresos
            : isPremediacion
            ? premediacion
            : isMediacion
            ? mediacion
            : isSeguimiento
            ? seguimiento
            : cierre}
        </div>
        {isIngreso ? (
          <div
            className={classes.agregarCaso}
            onClick={() => {
              setShowModalIngreso(true);
            }}
          >
            Agregar caso
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={classes.modalContainer}>
        {modalIngreso ? (
          <ModalIngreso
            setShowModalIngreso={setShowModalIngreso}
            caso={props.caso}
            key={props.caso ? props.caso.ruc : "ingresoEnProceso"}
          ></ModalIngreso>
        ) : null}
      </div>
    </div>
  );
}

export default CasosTrack;
