import classes from "./Graficos.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  etapas,
  delitos,
  comunas,
  derivadores,
  mediadores,
  acuerdos,
} from "../../components/data/opciones";
import {
  filtrosDefault,
  filtrosParticipanteDefault,
} from "../../components/data/opciones";
import { HashLoader } from "react-spinners";
import moment from "moment";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
  dateArray,
  monthArrayGenerator,
} from "../../funciones/funcionesAuxiliares";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import {
  defEtapa,
  defGradientes,
  fillEtapa,
  fillGradientes,
  keysChart,
  gradientesTitulos,
} from "./coloresBarras";
import { toPng } from "html-to-image";
import download from "downloadjs";
import { Button, Menu, MenuItem } from "@mui/material";
import FiltrosBusquedas from "../layout/FiltrosBusquedas";

function Graficos(props) {
  const activarFiltro = props.activarFiltro;
  const setActivarFiltro = props.setActivarFiltro;
  //Opciones para ordenar casos, estoy usando useMemo para que no moleste React, básicamente renderiza esto sólo una vez
  const opcionesEjeX = useMemo(() => {
    let acuerdosExtra = [...acuerdos, "Sin acuerdo", "En curso"];
    acuerdosExtra.push(
      acuerdosExtra.splice(acuerdosExtra.indexOf("Otro"), 1)[0]
    );
    return {
      Etapa: {
        opciones: etapas,
        campo: "etapa",
      },
      "Ingreso diario": { opciones: "", campo: "fecha_ingreso" },
      "Ingreso mensual": { opciones: "", campo: "fecha_ingreso" },
      Delitos: { opciones: delitos, campo: "delito" },
      Comunas: { opciones: comunas, campo: "comuna_hecho" },
      Derivadores: {
        opciones: derivadores,
        campo: "derivador_caso",
      },
      Mediadores: {
        opciones: mediadores,
        campo: "mediador_asignado",
      },
      Actividades: {
        opciones: ["Convocatorias", "Entrevistas", "Sesiones Mediación"],
      },
      "Tipo Acuerdo": {
        opciones: acuerdosExtra,
        campo: "tipo_acuerdo",
      },
      "Actividad Mensual - Convocatorias": {
        opciones: "",
      },
      "Actividad Mensual - Entrevistas": {
        opciones: "",
      },
      "Actividad Mensual - Sesiones mediación": {
        opciones: "",
      },
      "Actividad Mensual - Actividades mediación": {
        opciones: "",
      },
      "Actividad Mensual - Premediación": {
        opciones: "",
      },
      "Actividad Mensual - Mediación": {
        opciones: "",
      },
      "Actividad Mensual - Total": {
        opciones: "",
      },
    };
  }, []);

  const colorScaleEtapa = useMemo(() => {
    return gradientesTitulos;
  }, []);

  //Contenedor de casos y manejo de autenticación
  const [casosFiltrados, setCasosFiltrados] = useState(props.casos);
  const [isFiltrandoCasos, setIsFiltradoCasos] = useState(true);
  const [isCargandoData, setIsCargandoData] = useState(false);

  //Filtros disponibles
  const [filtros, setFiltros] = useState({
    estado: "Todos",
    etapa: "Todos",
    delito: "Todos",
    comuna_hecho: "Todos",
    derivador_caso: "Todos",
    causal_devolucion: "Todos",
    mediador_asignado: "Todos",
  });
  const [filtrosVictima, setFiltrosVictima] = useState(
    filtrosParticipanteDefault
  );
  const [filtrosOfensor, setFiltrosOfensor] = useState(
    filtrosParticipanteDefault
  );
  const [filtrosConcurrente, setFiltrosConcurrente] = useState(
    filtrosParticipanteDefault
  );
  const [fechaDesdeIngreso, setFechaDesdeIngreso] = useState(
    moment(new Date("2023-05-01")).format("YYYY-MM-DD")
  );
  const [fechaHastaIngreso, setFechaHastaIngreso] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [fechaDesdeCierre, setFechaDesdeCierre] = useState(
    moment(new Date("2023-05-01")).format("YYYY-MM-DD")
  );
  const [fechaHastaCierre, setFechaHastaCierre] = useState(
    moment().format("YYYY-MM-DD")
  );

  //Resultado de filtros y orden
  const casos = props.casos;
  const [ejeX, setEjeX] = useState("Etapa");
  const [conteo, setConteo] = useState({});
  const [data, setData] = useState([]);

  //Para menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Arreglo de días o meses
  const [dayArray, setDayArray] = useState(
    dateArray(moment(new Date("2023-05-01")), moment())
  );

  const [monthArray, setMonthArray] = useState(
    monthArrayGenerator(moment(new Date("2023-05-01")), moment())
  );

  //Generación eje X
  useEffect(() => {
    let result = {};
    let data = [];
    let containerDiario = [];
    if (ejeX !== "Actividades") {
      for (const alternativa of opcionesEjeX[ejeX]["opciones"]) {
        let campo = opcionesEjeX[ejeX]["campo"];
        //Caso Etapa
        if (ejeX === "Etapa") {
          result[alternativa] = casosFiltrados.filter(
            (caso) => caso[campo] === alternativa
          ).length;
        } else {
          //Cualquier otra alternativa
          result[alternativa] = casosFiltrados.filter(
            (caso) => caso.ingresoData[campo] === alternativa
          ).length;
        }
        if (ejeX === "Tipo Acuerdo") {
          result[alternativa] = casosFiltrados.filter((caso) => {
            if (Object.keys(caso.mediacionData).length !== 0) {
              if (alternativa === "Sin acuerdo") {
                return (
                  caso.mediacionData["estado_actual_mediacion"].includes(
                    "sin acuerdo"
                  ) ||
                  caso.mediacionData.estado_actual_mediacion.includes(
                    "Frustrada"
                  )
                );
              }
              if (
                alternativa === "En curso" &&
                !(
                  caso.mediacionData["estado_actual_mediacion"].includes(
                    "sin acuerdo"
                  ) ||
                  caso.mediacionData.estado_actual_mediacion.includes(
                    "Frustrada"
                  )
                )
              ) {
                return (
                  caso.mediacionData[campo] === undefined ||
                  caso.mediacionData[campo] === "Sin iniciar" ||
                  caso.mediacionData[campo] === "Agenda nueva sesión"
                );
              } else {
                if (caso.mediacionData === undefined) {
                  return false;
                } else if (caso.mediacionData[campo] === undefined) {
                  return false;
                } else {
                  return caso.mediacionData[campo].includes(alternativa);
                }
              }
            } else {
              return false;
            }
          }).length;
        }
      }
      if (ejeX === "Ingreso diario") {
        for (const alternativa of dayArray) {
          let value = casosFiltrados.filter((caso) => {
            try {
              return (
                moment(caso.ingresoData["fecha_ingreso"].toDate()).format(
                  "YYYY-MM-DD"
                ) === alternativa
              );
            } catch {
              console.log(caso.ruc);
              return false;
            }
          }).length;
          result[alternativa] = value === 0 ? null : value;
        }
      }
      if (ejeX === "Ingreso mensual") {
        for (const alternativa of monthArray) {
          let value = casosFiltrados.filter((caso) => {
            try {
              return (
                moment(caso.ingresoData["fecha_ingreso"].toDate())
                  .format("YYYY-MM-DD")
                  .slice(0, 7) === alternativa
              );
            } catch {
              return false;
            }
          }).length;
          result[alternativa] = value === 0 ? null : value;
        }
      }
    } else {
      let convocatorias = 0;
      let entrevistas = 0;
      let sesiones = 0;
      let convocatoriasCaso = 0;
      let entrevistasCaso = 0;
      let sesionesCaso = 0;
      for (const caso of casosFiltrados) {
        try {
          if (caso.etapa !== "Ingreso") {
            if (caso.premediacionData.actividades !== "") {
              convocatoriasCaso = caso.premediacionData.actividades.filter(
                (actividad) =>
                  actividad.tipo === "convocatoria" &&
                  moment(actividad.fecha_interaccion.toDate()).format(
                    "YYYY-MM-DD"
                  ) <= fechaHastaIngreso &&
                  moment(actividad.fecha_interaccion.toDate()).format(
                    "YYYY-MM-DD"
                  ) >= fechaDesdeIngreso
              ).length;
              entrevistasCaso = caso.premediacionData.actividades.filter(
                (actividad) =>
                  actividad.tipo === "entrevista" &&
                  moment(actividad.fecha_interaccion.toDate()).format(
                    "YYYY-MM-DD"
                  ) <= fechaHastaIngreso &&
                  moment(actividad.fecha_interaccion.toDate()).format(
                    "YYYY-MM-DD"
                  ) >= fechaDesdeIngreso
              ).length;
              convocatorias = convocatorias + convocatoriasCaso;
              entrevistas = entrevistas + entrevistasCaso;
            }
          }
          if (caso.etapa !== "Ingreso" && caso.etapa !== "Premediación") {
            //Para calcular la cantidad de sesiones
            sesionesCaso = caso.mediacionData.sesiones.filter(
              (sesion) =>
                moment(sesion.fecha_sesion_mediacion.toDate()).format(
                  "YYYY-MM-DD"
                ) <= fechaHastaIngreso &&
                moment(sesion.fecha_sesion_mediacion.toDate()).format(
                  "YYYY-MM-DD"
                ) >= fechaDesdeIngreso
            ).length;
            sesiones = sesiones + sesionesCaso;
          }
        } catch (error) {
          console.log(caso.id);
        }
      }
      result["Convocatorias"] = convocatorias;
      result["Entrevistas"] = entrevistas;
      result["Sesiones Mediación"] = sesiones;
    }

    for (const [key, value] of Object.entries(result)) {
      let dataKey =
        ejeX === "Delitos" ? key.charAt(0) + key.slice(1).toLowerCase() : key;
      if (ejeX === "Ingreso diario" || ejeX === "Ingreso mensual") {
        containerDiario.push({
          x: dataKey,
          y: value,
        });
      } else {
        data.push({
          [dataKey]: value,
          campo: dataKey,
          value: value,
        });
      }
    }
    if (ejeX === "Ingreso diario" || ejeX === "Ingreso mensual") {
      data = [
        {
          id: "diario",
          data: containerDiario,
        },
      ];
    }

    if (ejeX.includes("Actividad Mensual")) {
      //Se obtienen todas las actividades disponibles
      const convocatorias = [];
      const entrevistas = [];
      const sesiones = [];
      const actividadesMediacion = [];

      for (const caso of casosFiltrados) {
        if (caso.etapa !== "Ingreso") {
          if (caso.premediacionData.actividades !== "") {
            for (const actividad of caso.premediacionData.actividades) {
              if (actividad.tipo === "convocatoria") {
                convocatorias.push({
                  mediador_asignado: caso.ingresoData.mediador_asignado,
                  fecha_interaccion: actividad.fecha_interaccion,
                });
              }
              if (actividad.tipo === "entrevista") {
                entrevistas.push({
                  mediador_asignado: caso.ingresoData.mediador_asignado,
                  fecha_interaccion: actividad.fecha_interaccion,
                });
              }
            }
          }
        }
        if (caso.etapa !== "Ingreso" && caso.etapa !== "Premediación") {
          if (caso.mediacionData.sesiones.length !== 0) {
            for (const sesion of caso.mediacionData.sesiones) {
              sesiones.push({
                mediador_asignado: caso.ingresoData.mediador_asignado,
                fecha_interaccion: sesion.fecha_sesion_mediacion,
              });
            }
          }
          if (caso.mediacionData.actividades) {
            if (caso.mediacionData.actividades.length !== 0) {
              for (const actividad of caso.mediacionData.actividades) {
                actividadesMediacion.push({
                  mediador_asignado: caso.ingresoData.mediador_asignado,
                  fecha_interaccion: actividad.fecha_actividad,
                });
              }
            }
          }
        }
      }
      for (const mediador of mediadores) {
        let monthData = [];
        for (const mes of monthArray) {
          let convocatoriasMediador = convocatorias.filter((actividad) => {
            try {
              return (
                actividad.mediador_asignado === mediador &&
                moment(actividad.fecha_interaccion.toDate()).format(
                  "YYYY-MM"
                ) === mes
              );
            } catch (error) {
              return false;
            }
          }).length;
          let entrevistasMediador = entrevistas.filter((actividad) => {
            try {
              return (
                actividad.mediador_asignado === mediador &&
                moment(actividad.fecha_interaccion.toDate()).format(
                  "YYYY-MM"
                ) === mes
              );
            } catch (error) {
              return false;
            }
          }).length;
          let sesionesMediador = sesiones.filter((actividad) => {
            try {
              return (
                actividad.mediador_asignado === mediador &&
                moment(actividad.fecha_interaccion.toDate()).format(
                  "YYYY-MM"
                ) === mes
              );
            } catch (error) {
              return false;
            }
          }).length;
          let actividadesMediador = actividadesMediacion.filter((actividad) => {
            try {
              return (
                actividad.mediador_asignado === mediador &&
                moment(actividad.fecha_interaccion.toDate()).format(
                  "YYYY-MM"
                ) === mes
              );
            } catch (error) {
              return false;
            }
          }).length;
          if (ejeX === "Actividad Mensual - Actividades mediación")
            monthData.push({
              x: mes,
              y: actividadesMediador,
            });
          if (ejeX === "Actividad Mensual - Sesiones mediación")
            monthData.push({
              x: mes,
              y: sesionesMediador,
            });
          if (ejeX === "Actividad Mensual - Convocatorias")
            monthData.push({
              x: mes,
              y: convocatoriasMediador,
            });
          if (ejeX === "Actividad Mensual - Entrevistas")
            monthData.push({
              x: mes,
              y: entrevistasMediador,
            });
          if (ejeX === "Actividad Mensual - Total")
            monthData.push({
              x: mes,
              y:
                convocatoriasMediador +
                entrevistasMediador +
                sesionesMediador +
                actividadesMediador,
            });
          if (ejeX === "Actividad Mensual - Premediación")
            monthData.push({
              x: mes,
              y: convocatoriasMediador + entrevistasMediador,
            });
          if (ejeX === "Actividad Mensual - Mediación")
            monthData.push({
              x: mes,
              y: sesionesMediador + actividadesMediador,
            });
        }
        data.push({
          id: mediador,
          data: monthData,
        });
      }
      for (const mediadorData of data) {
        for (const monthData of mediadorData.data) {
          if (result[monthData.x] === undefined) {
            result[monthData.x] = monthData.y;
          } else {
            result[monthData.x] = result[monthData.x] + monthData.y;
          }
        }
      }
    }

    setConteo(result);
    setData(data);
    /* setTimeout(() => {
      setIsCargandoData(false);
    }, 1000); */
  }, [
    casosFiltrados,
    colorScaleEtapa,
    dayArray,
    ejeX,
    fechaDesdeIngreso,
    fechaHastaIngreso,
    monthArray,
    opcionesEjeX,
  ]);

  const exportToExcel = async (excelData, fileName, fileExtension) => {
    let cleanedData = [];
    if (ejeX === "Ingreso diario" || ejeX === "Ingreso mensual") {
      excelData[0]["data"].map((entry) => {
        cleanedData.push({
          fecha: entry.x,
          cantidad: entry.y,
        });
        return cleanedData;
      });
    }
    excelData.map((entry) => {
      cleanedData.push({
        campo: entry.campo,
        cantidad: entry.value,
      });
      return cleanedData;
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charet=UTF-8";
    const ws = XLSX.utils.json_to_sheet(cleanedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    if (fileExtension === ".xlsx") {
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataToExport = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(dataToExport, fileName + fileExtension);
    } else {
      const csv = XLSX.utils.sheet_to_csv(ws);
      const csvBuffer = new ArrayBuffer(csv.length);
      const view = new Uint8Array(csvBuffer);
      for (let i = 0; i !== csv.length; ++i) view[i] = csv.charCodeAt(i) & 0xff;
      const dataToExport = new Blob([csvBuffer], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(dataToExport, fileName + fileExtension);
    }
  };
  const exportToImage = async (filename, extension) => {
    if (!chart.current) {
      return;
    }
    const dataUrl = await toPng(chart.current);
    download(dataUrl, filename + extension);
  };

  const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);
  const chart = useRef(null);

  return (
    <div className={classes.mainContainer}>
      {/* Sección de filtros */}
      <FiltrosBusquedas
        opcionesEjeX={opcionesEjeX}
        estadistica={true}
        setIsCargandoData={props.setIsCargandoCasos}
        casos={props.casos}
        setCasosFiltrados={setCasosFiltrados}
        setIsFiltradoCasos={setIsFiltradoCasos}
        filtros={filtros}
        setFiltros={setFiltros}
        filtrosVictima={filtrosVictima}
        setFiltrosVictima={setFiltrosVictima}
        filtrosOfensor={filtrosOfensor}
        setFiltrosOfensor={setFiltrosOfensor}
        filtrosConcurrente={filtrosConcurrente}
        setFiltrosConcurrente={setFiltrosConcurrente}
        ejeX={ejeX}
        setEjeX={setEjeX}
        fechaDesdeIngreso={fechaDesdeIngreso}
        fechaHastaIngreso={fechaHastaIngreso}
        fechaDesdeCierre={fechaDesdeCierre}
        fechaHastaCierre={fechaHastaCierre}
        setFechaDesdeIngreso={setFechaDesdeIngreso}
        setFechaHastaIngreso={setFechaHastaIngreso}
        setFechaDesdeCierre={setFechaDesdeCierre}
        setFechaHastaCierre={setFechaHastaCierre}
        activarFiltro={activarFiltro}
        setActivarFiltro={setActivarFiltro}
      ></FiltrosBusquedas>
      <div className={classes.graphContainer}>
        <div className={classes.botonDescarga}>
          <Button
            id="botonDescarga"
            variant="contained"
            className={classes.botonDescarga2}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Descargar
          </Button>
          <Menu
            id="menuDescarga"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                exportToExcel(
                  data,
                  "Estadisticas_" + ejeX + "_" + moment().format("YYYY-MM-DD"),
                  ".xlsx"
                );
                handleClose();
              }}
            >
              Data en Excel
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportToExcel(
                  data,
                  "Estadisticas_" + ejeX + "_" + moment().format("YYYY-MM-DD"),
                  ".csv"
                );
                handleClose();
              }}
            >
              Data en CSV
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportToImage(
                  "Estadisticas_" + ejeX + "_" + moment().format("YYYY-MM-DD"),
                  ".png"
                );
                handleClose();
              }}
            >
              Gráfico PNG
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportToImage(
                  "Estadisticas_" + ejeX + "_" + moment().format("YYYY-MM-DD"),
                  ".jpg"
                );
                handleClose();
              }}
            >
              Gráfico JPG
            </MenuItem>
          </Menu>
        </div>
        {isCargandoData ? (
          <div className={classes.loadingGraph}>
            <HashLoader color="#36d7b7" size={80} />
          </div>
        ) : (
          /* Sección de gráfico */
          <div className={classes.grafico}>
            <div className={classes.tituloGrafico}>{ejeX}</div>
            {ejeX === "Ingreso diario" ||
            ejeX === "Ingreso mensual" ||
            ejeX.includes("Actividad Mensual") ? (
              <ResponsiveLine
                enableArea={
                  !(ejeX === "Ingreso diario" || ejeX === "Ingreso mensual")
                }
                data={data}
                margin={{ top: 50, right: 140, bottom: 50, left: 60 }}
                curve={
                  ejeX === "Ingreso diario" || ejeX === "Ingreso mensual"
                    ? "monotoneX"
                    : "step"
                }
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: true,
                  reverse: false,
                }}
                axisLeft={{ tickSize: 1 }}
                axisBottom={{
                  format: function (value) {
                    if (ejeX === "Ingreso diario") {
                      return value.slice(-2) === "01" ? value : "";
                    } else {
                      return value;
                    }
                  },
                  tickRotation: 15,
                }}
                pointSize={10}
                pointColor="#f54107"
                pointBorderWidth={2}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={
                  ejeX === "Ingreso diario" || ejeX === "Ingreso mensual"
                    ? []
                    : [
                        {
                          anchor: "right",
                          direction: "column",
                          justify: false,
                          translateX: 100,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemDirection: "left-to-right",
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: "circle",
                          symbolBorderColor: "rgba(0, 0, 0, .5)",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]
                }
              ></ResponsiveLine>
            ) : (
              <ResponsiveBar
                //ref={chart}
                data={data}
                keys={keysChart[ejeX]}
                indexBy="campo"
                enableLabel={false}
                tooltip={(d) => {
                  return <div>{d.value}</div>;
                }}
                defs={ejeX === "Etapa" ? defEtapa : defGradientes}
                fill={ejeX === "Etapa" ? fillEtapa : fillGradientes}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                padding={0.3}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={"#FFFFFF"}
                role="application"
                axisBottom={{
                  tickRotation:
                    ejeX === "Delitos" ||
                    ejeX === "Comunas" ||
                    ejeX === "Tipo Acuerdo"
                      ? 10
                      : 0,
                }}
              ></ResponsiveBar>
            )}
          </div>
        )}
      </div>
      {/* Sección de resumen */}
      <div className={classes.summaryContainer}>
        {/* Selector de fechas de ingreso */}
        <div className={classes.dateContainer}>
          <div>
            <strong>Fecha de ingreso</strong>
          </div>
          <hr className={classes.divider} />
          <div className={classes.date}>
            <div>
              <strong>Desde</strong>
            </div>
            <div>
              <input
                className={classes.actionBox}
                type="date"
                name=""
                id=""
                defaultValue={fechaDesdeIngreso}
                onChange={(event) => {
                  //setIsCargandoData(true);
                  event.target.value === ""
                    ? setFechaDesdeIngreso(
                        moment(new Date("2023-05-01")).format("YYYY-MM-DD")
                      )
                    : setFechaDesdeIngreso(
                        moment(event.target.value).format("YYYY-MM-DD")
                      );
                  event.target.value === ""
                    ? setDayArray(
                        dateArray(
                          moment(new Date("2023-05-01")),
                          moment(new Date(fechaHastaIngreso))
                        )
                      )
                    : setDayArray(
                        dateArray(
                          moment(event.target.value),
                          moment(new Date(fechaHastaIngreso))
                        )
                      );
                  event.target.value === ""
                    ? setMonthArray(
                        monthArrayGenerator(
                          moment(new Date("2023-05-01")),
                          moment(new Date(fechaHastaIngreso))
                        )
                      )
                    : setMonthArray(
                        monthArrayGenerator(
                          moment(event.target.value),
                          moment(new Date(fechaHastaIngreso))
                        )
                      );
                }}
              />
            </div>
          </div>
          <div className={classes.date}>
            <div>
              <strong>Hasta</strong>
            </div>
            <div>
              <input
                className={classes.actionBox}
                type="date"
                name=""
                id=""
                defaultValue={fechaHastaIngreso}
                onChange={(event) => {
                  //setIsCargandoData(true);
                  event.target.value === ""
                    ? setFechaHastaIngreso(moment().format("YYYY-MM-DD"))
                    : setFechaHastaIngreso(
                        moment(event.target.value).format("YYYY-MM-DD")
                      );
                  event.target.value === ""
                    ? setDayArray(
                        dateArray(moment(new Date(fechaDesdeIngreso)), moment())
                      )
                    : setDayArray(
                        dateArray(
                          moment(new Date(fechaDesdeIngreso)),
                          moment(event.target.value)
                        )
                      );
                  event.target.value === ""
                    ? setMonthArray(
                        monthArrayGenerator(
                          moment(new Date(fechaDesdeIngreso)),
                          moment()
                        )
                      )
                    : setMonthArray(
                        monthArrayGenerator(
                          moment(new Date(fechaDesdeIngreso)),
                          moment(event.target.value)
                        )
                      );
                }}
              />
            </div>
          </div>
        </div>
        {/* Selector de fecha de finalización*/}
        {filtros.estado === "finalizado" ? (
          <div className={classes.dateContainer}>
            <div>Fecha de cierre</div>
            <div className={classes.date}>
              <div>Desde:</div>
              <div>
                <input
                  className={classes.actionBox}
                  type="date"
                  name=""
                  id=""
                  defaultValue={fechaDesdeCierre}
                  onChange={(event) => {
                    //setIsCargandoData(true);
                    event.target.value === ""
                      ? setFechaDesdeCierre("")
                      : setFechaDesdeCierre(
                          moment(event.target.value).format("YYYY-MM-DD")
                        );
                  }}
                />
              </div>
            </div>
            <div className={classes.date}>
              <div>Hasta:</div>
              <div>
                <input
                  className={classes.actionBox}
                  type="date"
                  name=""
                  id=""
                  defaultValue={fechaHastaCierre}
                  onChange={(event) => {
                    //setIsCargandoData(true);
                    event.target.value === ""
                      ? setFechaHastaCierre("")
                      : setFechaHastaCierre(
                          moment(event.target.value).format("YYYY-MM-DD")
                        );
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* Sección resumen */}
        <div className={classes.resumen}>
          {Object.keys(conteo).map(function (key, i) {
            return (
              <div key={key} className={classes.summaryItem}>
                <div>
                  {ejeX === "Delitos" ? (
                    <div className={classes.summaryLineContainer}>
                      <div
                        style={{
                          background: `linear-gradient(to right, ${
                            colorScaleEtapa[
                              key.charAt(0) + key.slice(1).toLowerCase()
                            ]
                          }, ${
                            colorScaleEtapa[
                              key.charAt(0) + key.slice(1).toLowerCase() + "2"
                            ]
                          })`,
                        }}
                        className={classes.summaryColor}
                      ></div>
                      <div>{key.charAt(0) + key.slice(1).toLowerCase()}</div>
                    </div>
                  ) : (
                    <div className={classes.summaryLineContainer}>
                      <div
                        style={{
                          background: `linear-gradient(to right, ${
                            colorScaleEtapa[key]
                          }, ${colorScaleEtapa[key + "2"]})`,
                        }}
                        className={classes.summaryColor}
                      ></div>
                      <div>{key}</div>
                    </div>
                  )}
                </div>
                <div className={classes.keyValue}>{conteo[key]}</div>
                <hr className={classes.separator} />
              </div>
            );
          })}
          <div className={classes.summaryItem}>
            <div>Total: </div>
            <div>{sumValues(conteo)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Graficos;
