import { storage } from "./firebase_init";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

let archivoRutaDescarga = "";
//   path es la ruta donde se desea dejar el archivo, hay que incluir el nombre del archivo para que
//   infiera el tipo.
/* function postStorage(path, file) {
  const storageRef = ref(storage, path);
  return new Promise((resolve) => {
    try {
      //TODO: Mensaje de que el archivo se subió con éxito
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("El progreso es de " + progress + "%");
          switch (snapshot.state) {
            case "paused":
              console.log("La subida está pausada");
              break;
            case "running":
              console.log("Se está subiendo el archivo");
              break;
            default:
              console.log("Iniciando subida")
          }
        },
        (error) => {
          console.log("Ocurrió un error en la subida");
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("El archivo está disponible en ", downloadURL);
            archivoRutaDescarga = downloadURL;
            resolve(archivoRutaDescarga);
          });
        }
      );
      console.log("Archivo subido con éxito");
      //TODO mostrar alerta de éxito
    } catch (e) {
      console.log(e);
    }
  });
} */

async function postStorage(path, file) {
  try {
    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error("No logró subirse el archivo");
    throw error;
  }
}

export { postStorage };
