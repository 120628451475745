import classes from "./CasosMain.module.css";
import CasosTrack from "../components/layout/CasosTrack";
import { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import FiltrosBusquedas from "../components/layout/FiltrosBusquedas";
import { Button } from "@mui/material";
import {
  filtrosDefault,
  filtrosParticipanteDefault,
} from "../components/data/opciones";
import { Drawer } from "antd";

function CasosMain(props) {
  const [isCargandoDatosIngreso, setIsCargandoCasosIngreso] = useState(true);
  const [casosIngreso, setCasosIngreso] = useState([]);
  const [isCargandoDatosPremediacion, setIsCargandoCasosPremediacion] =
    useState(true);
  const [casosPremediacion, setCasosPremediacion] = useState([]);
  const [isCargandoDatosMediacion, setIsCargandoCasosMediacion] =
    useState(true);
  const [casosMediacion, setCasosMediacion] = useState([]);
  const [isCargandoDatosSeguimiento, setIsCargandoCasosSeguimiento] =
    useState(true);
  const [casosSeguimiento, setCasosSeguimiento] = useState([]);
  const [isCargandoDatosCierre, setIsCargandoCasosCierre] = useState(true);
  const [casosCierre, setCasosCierre] = useState([]);
  const [casosFiltrados, setCasosFiltrados] = useState(props.casos);
  const [filtros, setFiltros] = useState(filtrosDefault);
  const [filtrosVictima, setFiltrosVictima] = useState(
    filtrosParticipanteDefault
  );
  const [filtrosOfensor, setFiltrosOfensor] = useState(
    filtrosParticipanteDefault
  );
  const [filtrosConcurrente, setFiltrosConcurrente] = useState(
    filtrosParticipanteDefault
  );
  const activarFiltro = props.activarFiltro;
  const setActivarFiltro = props.setActivarFiltro;
  //Busquedas disponibles
  const [rut, setRut] = useState("");
  const [ruc, setRuc] = useState("");
  const [apellidos, setApellidos] = useState("");

  useEffect(() => {
    console.log("Cambio casos");
    let helperIngreso = [];
    let helperPremediacion = [];
    let helperMediacion = [];
    let helperSeguimiento = [];
    let helperCierre = [];
    if (props.user) {
      for (let caso of casosFiltrados) {
        if (caso.etapa === "Ingreso" && caso.estado !== "finalizado")
          helperIngreso.push(caso.ingresoData);
        if (caso.etapa === "Premediación" && caso.estado !== "finalizado")
          helperPremediacion.push(caso.premediacionData);
        if (caso.etapa === "Mediación" && caso.estado !== "finalizado")
          helperMediacion.push(caso.mediacionData);
        if (caso.etapa === "Seguimiento" && caso.estado !== "finalizado")
          helperSeguimiento.push(caso.seguimientoData);
        if (caso.etapa === "Cierre" && caso.estado !== "finalizado")
          helperCierre.push(caso.cierreData);
      }
      setCasosIngreso(helperIngreso);
      setCasosPremediacion(helperPremediacion);
      setCasosMediacion(helperMediacion);
      setCasosSeguimiento(helperSeguimiento);
      setCasosCierre(helperCierre);
      setIsCargandoCasosIngreso(false);
      setIsCargandoCasosPremediacion(false);
      setIsCargandoCasosMediacion(false);
      setIsCargandoCasosSeguimiento(false);
      setIsCargandoCasosCierre(false);
    }
  }, [casosFiltrados, props.user, props.casos]);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };

  return isCargandoDatosIngreso ||
    isCargandoDatosPremediacion ||
    isCargandoDatosMediacion ||
    isCargandoDatosSeguimiento ||
    isCargandoDatosCierre ? (
    <div className={classes.loading}>
      <HashLoader color="#36d7b7" size={80} />
    </div>
  ) : (
    <div>
      <Drawer
        placement="left"
        open={open}
        onClose={closeDrawer}
        forceRender={true}
      >
        <FiltrosBusquedas
          rut={rut}
          setRut={setRut}
          ruc={ruc}
          setRuc={setRuc}
          apellidos={apellidos}
          setApellidos={setApellidos}
          estadisticas={false}
          setIsCargandoData={props.setIsCargandoCasos}
          casos={props.casos}
          casosFiltrados={casosFiltrados}
          setCasosFiltrados={setCasosFiltrados}
          filtros={filtros}
          setFiltros={setFiltros}
          filtrosVictima={filtrosVictima}
          setFiltrosVictima={setFiltrosVictima}
          filtrosOfensor={filtrosOfensor}
          setFiltrosOfensor={setFiltrosOfensor}
          filtrosConcurrente={filtrosConcurrente}
          setFiltrosConcurrente={setFiltrosConcurrente}
          activarFiltro={activarFiltro}
          setActivarFiltro={setActivarFiltro}
        ></FiltrosBusquedas>
      </Drawer>
      <div className={classes.CasosMain}>
        <div className={classes.CasosFilterBar}>
          <Button
            id="botonDescarga"
            variant="contained"
            className={classes.botonDescarga2}
            onClick={showDrawer}
          >
            Filtros
          </Button>
          <Button
            id="botonDescarga"
            variant="contained"
            className={classes.botonDescarga2}
            onClick={() => {
              setFiltros(filtrosDefault);
              setFiltrosVictima(filtrosParticipanteDefault);
              setFiltrosOfensor(filtrosParticipanteDefault);
              setFiltrosConcurrente(filtrosParticipanteDefault);
              setApellidos("");
              setRuc("");
              setRut("");
            }}
          >
            Restaurar
          </Button>
        </div>
        <div
          className={[
            classes.CasosPanel /* , isModalActive ? "blur" : "" */,
          ].join(" ")}
        >
          <CasosTrack
            tipoCaso="panelIngreso"
            title="INGRESO"
            casos={casosIngreso}
            casosFiltrados={casosFiltrados}
            user={props.user}
          ></CasosTrack>
          <CasosTrack
            tipoCaso="panelPremediacion"
            title="PREMEDIACIÓN"
            casos={casosPremediacion}
            casosFiltrados={casosFiltrados}
            user={props.user}
          ></CasosTrack>
          <CasosTrack
            tipoCaso="panelMediacion"
            title="MEDIACIÓN"
            casos={casosMediacion}
            casosFiltrados={casosFiltrados}
            user={props.user}
          ></CasosTrack>
          <CasosTrack
            tipoCaso="panelSeguimiento"
            title="SEGUIMIENTO"
            casos={casosSeguimiento}
            casosFiltrados={casosFiltrados}
            user={props.user}
          ></CasosTrack>
          <CasosTrack
            tipoCaso="panelCierre"
            title="CIERRE DE SEGUIMIENTO"
            casos={casosCierre}
            casosFiltrados={casosFiltrados}
            user={props.user}
          ></CasosTrack>
        </div>
      </div>
    </div>
  );
}

export default CasosMain;
