import { useState } from "react";

import Premediacion from "../formularios/Premediacion";
import classes from "./PremediacionModal.module.css";
import cancel from "../../img/icono_cancel.png";

function ModalPremediacion(props) {

  const [modal_cierre_visible, setModal_cierre_visible] = useState(
    false
  );

  function closeModal() {
    props.setShowModalPremediacion(false);
  }
 
  
  function funcionesCierre(event) {
    event.stopPropagation();
    setModal_cierre_visible(true);

  }

  return (
    <div
      className={classes.modalContainer}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div
        className={classes.modal}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div
          className={classes.closeModal}
          onClick={funcionesCierre}
        >
          <img className={classes.closeModal} src={cancel} alt="" />
        </div>
        <div>
          <Premediacion
            closeModal={closeModal}
            caso={props.caso}
            key={props.caso ? props.caso.ruc : Date.now()}
            modal_cierre_visible= {modal_cierre_visible}
            setModal_cierre_visible = {setModal_cierre_visible}
          ></Premediacion>
        </div>
      </div>
    </div>
  );
}

export default ModalPremediacion;
