import { useState, createElement } from "react";
import classes from "./IndicadorCard.module.css";
import { ResponsivePie } from "@nivo/pie";
import { linearGradientDef } from "@nivo/core";

function IndicadorCard(props) {
  const titulo = props.titulo;
  const color = props.color;
  const color1 = props.color === "Azul" ? "#217efc" : "#ff7c26";
  const indicador = props.indicador;
  const porcentajeBool = props.porcentajeBool;
  const negrita = props.negrita;
  const label = props.label;
  const indicadorClass = {
    Azul: classes.indicadorAzul,
    Naranja: classes.indicadorNaranja,
    Morado: classes.indicadorMorado,
  };
  return (
    <div className={classes.card}>
      {porcentajeBool ? (
        <>
          <div
            className={
              props.color === "Azul"
                ? classes.porcentajeIndicadorAzul
                : classes.porcentajeIndicadorNaranja
            }
          >
            {+indicador.slice(0, -1) + " %"}
          </div>
          <div className={classes.pieChart}>
            <ResponsivePie
              data={[
                {
                  id: titulo.split(" ")[0],
                  label: label,
                  value: +indicador.slice(0, -1) / 100,
                  color: color,
                },
                {
                  id: color === "Azul" ? "No satisfechos" : "",
                  label: "",
                  value: 1 - +indicador.slice(0, -1) / 100,
                },
              ]}
              defs={[
                linearGradientDef("gradienteAzul", [
                  { offset: 0, color: "#217efc" },
                  { offset: 100, color: "#1b55b5" },
                ]),
                linearGradientDef("gradienteNaranja", [
                  { offset: 0, color: "#ff7c26" },
                  { offset: 100, color: "#fc5164" },
                ]),
              ]}
              fill={[
                { match: { color: "Azul" }, id: "gradienteAzul" },
                { match: { color: "Naranja" }, id: "gradienteNaranja" },
              ]}
              colors={[color1, "#F2F2F2"]}
              margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
              valueFormat=" >-~%"
              padAngle={0}
              innerRadius={0.6}
              cornerRadius={3}
              activeOuterRadiusOffset={7}
              startAngle={360}
              endAngle={0}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLinkLabels={false}
              enableArcLabels={false}
              tooltip={(e) => {
                let { datum: t } = e;
                if (t.color.length > 0) {
                  return createElement(
                    "div",
                    { style: { color: "#000000" } },
                    createElement("div", null, t.label)
                  );
                }
              }}
              arcLabelsSkipAngle={1}
              arcLabelsTextColor={"#FFFFFF"}
            ></ResponsivePie>
          </div>
          <div className={classes.tituloIndicador}>
            {titulo}
            <br></br>
            <strong>{negrita}</strong>
          </div>
        </>
      ) : (
        <div className={classes.noPercentContainer}>
          <div className={classes.title}>{titulo}</div>
          <div className={indicadorClass[color]}>{indicador}</div>
        </div>
      )}
    </div>
  );
}

export default IndicadorCard;
