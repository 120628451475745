//CSS
import classes from "./Estadistica.module.css";
//Librerías react
import { useState } from "react";
//Librerías externas
import { HashLoader } from "react-spinners";
import Graficos from "../components/estadistica/Graficos";
import Indicadores from "../components/estadistica/Indicadores";

function Estadistica(props) {
  const [tab, setTab] = useState("Gráficos");
  const activarFiltro = props.activarFiltro;
  const setActivarFiltro = props.setActivarFiltro;
  return props.isCargandoCasos ? (
    <div className={classes.loading}>
      <HashLoader color="#36d7b7" size={80} />
    </div>
  ) : (
    <div className={classes.mainContainer}>
      <div className={classes.tabContainer}>
        <div
          className={
            tab === "Gráficos" ? classes.selectedTab : classes.unselectedTab
          }
          onClick={() => setTab("Gráficos")}
        >
          Gráficos
        </div>
        <div
          className={
            tab === "Indicadores" ? classes.selectedTab : classes.unselectedTab
          }
          onClick={() => setTab("Indicadores")}
        >
          Indicadores
        </div>
      </div>
      <hr className={classes.separator} />
      <div>
        {tab === "Gráficos" ? (
          <Graficos
            casos={props.casos}
            activarFiltro={activarFiltro}
            setActivarFiltro={setActivarFiltro}
          />
        ) : (
          <Indicadores
            casos={props.casos}
            activarFiltro={activarFiltro}
            setActivarFiltro={setActivarFiltro}
          />
        )}
      </div>
    </div>
  );
}

export default Estadistica;
