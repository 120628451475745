import classes from "./Likert.module.css";

function Likert(props) {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.option}>
        <input
          type="radio"
          name=""
          value=" "
          checked={props.value === " "}
          onChange={() => {
            props.setValue(" ");
          }}
        />
        <div>NA</div>
      </div>
      <div className={classes.option}>
        <input
          type="radio"
          name=""
          value="0"
          checked={props.value === 0}
          onChange={() => {
            props.setValue(0);
          }}
        />
        <div>NS/NC</div>
      </div>
      <div className={classes.option}>
        <input
          className={classes.checkBad}
          type="radio"
          name=""
          value="1"
          checked={props.value === 1}
          onChange={() => {
            props.setValue(1);
          }}
        />
        <div>1</div>
      </div>
      <div className={classes.option}>
        <input
          className={classes.checkBad}
          type="radio"
          name=""
          value="2"
          checked={props.value === 2}
          onChange={() => {
            props.setValue(2);
          }}
        /><div>2</div>
      </div>
      <div className={classes.option}>
        <input
          className={classes.checkNeutral}
          type="radio"
          name=""
          value="3"
          checked={props.value === 3}
          onChange={() => {
            props.setValue(3);
          }}
        /><div>3</div>
      </div>
      <div className={classes.option}>
        <input
          className={classes.checkNeutral}
          type="radio"
          name=""
          value="4"
          checked={props.value === 4}
          onChange={() => {
            props.setValue(4);
          }}
        /><div>4</div>
      </div>
      <div className={classes.option}>
        <input
          className={classes.checkNeutral}
          type="radio"
          name=""
          value="5"
          checked={props.value === 5}
          onChange={() => {
            props.setValue(5);
          }}
        /><div>5</div>
      </div>
      <div className={classes.option}>
        <input
          className={classes.checkGood}
          type="radio"
          name=""
          value="6"
          checked={props.value === 6}
          onChange={() => {
            props.setValue(6);
          }}
        /><div>6</div>
      </div>
      <div className={classes.option}>
        <input
          className={classes.checkGood}
          type="radio"
          name=""
          value="7"
          checked={props.value === 7}
          onChange={() => {
            props.setValue(7);
          }}
        /><div>7</div>
      </div>
    </div>
  );
}

export default Likert;
