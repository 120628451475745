import axios from "axios";
import fileDownload from "js-file-download";

function handleDate(timestamp) {
  if (timestamp) {
    let dateData = new Date(timestamp.seconds * 1000);
    let day =
      dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
    let month =
      dateData.getMonth() + 1 > 9
        ? dateData.getMonth() + 1
        : "0" + (dateData.getMonth() + 1);
    let year = dateData.getFullYear();
    let parsedString = day + "-" + month + "-" + year;
    return parsedString;
  } else {
    return "Sin información";
  }
}

function handleDateYMD(timestamp) {
  if (timestamp) {
    let dateData = new Date(timestamp.seconds * 1000);
    let day =
      dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
    let month =
      dateData.getMonth() + 1 > 9
        ? dateData.getMonth() + 1
        : "0" + (dateData.getMonth() + 1);
    let year = dateData.getFullYear();
    let parsedString = year + "-" + month + "-" + day;
    return parsedString;
  } else {
    return "Sin información";
  }
}

function handleDownload(url, filename) {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
}

function compareArrays(array1, array2) {
  let diferences = [];
  for (let element of array1) {
    if (array2.indexOf(element) === -1) {
      diferences.push(element);
    }
  }
  return diferences;
}

export { handleDate, handleDownload, compareArrays, handleDateYMD };
