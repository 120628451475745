import classes from "./Admin.module.css";
import { handleDateYMD, handleDate } from "../helpers/funciones";
import { useState, useEffect, cloneElement } from "react";
import { delitos, etapas } from "../components/data/opciones";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  styled,
  TextField,
  Autocomplete,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { changeCaso } from "../firebase/firebasePostData";

function Admin(props) {
  const casos = props.casos;
  const isCargandoDatos = props.isCargandoDatos;
  const [etapaActual, setEtapaActual] = useState(null);
  const [fechaActual, setFechaActual] = useState(null);
  const [delitoActual, setDelitoActual] = useState(null);
  const [selectedRuc, setSelectedRuc] = useState(null);
  const [selectedDelito, setSelectedDelito] = useState(null);
  const [selectedEtapa, setSelectedEtapa] = useState(null);
  const [selectedFecha, setSelectedFecha] = useState(null);
  const [firebaseDate, setFirebaseDate] = useState(null);
  const [idCasoSeleccionado, setIdCasoSeleccionado] = useState(null);
  const [isCargando, setIsCargando] = useState(true);
  const [etapaList, setEtapaList] = useState(etapas);

  //Desde todos los casos, se genera una lista de objectos que permitirá realizar búsquedas
  const rucList = casos.map((caso) => caso.ruc);
  const duplicatesInfo = casos.map((caso) => {
    return {
      ruc: caso.ruc,
      delito: caso.ingresoData.delito,
      fecha_ingreso: caso.ingresoData.fecha_ingreso,
    };
  });
  const delitoList = delitos;
  //Creo un tema para poder modificar el CSS del selector de fechas
  const StyledTextField = styled(TextField)({
    backgroundColor: "#ffffff",
  });

  function findSharedObjects(arr) {
    const result = [];

    for (let i = 0; i < arr.length - 1; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        const obj1 = arr[i];
        const obj2 = arr[j];

        // Check if objects share at least one key-value pair
        let hasSharedKeyValue = false;

        if (obj1.ruc === obj2.ruc) {
          hasSharedKeyValue = true;
        }

        if (hasSharedKeyValue) {
          if (!result.includes(obj1)) {
            result.push(obj1);
          }
          if (!result.includes(obj2)) {
            result.push(obj2);
          }
        }
      }
    }

    return result;
  }

  const duplicates = findSharedObjects(duplicatesInfo);
  useEffect(() => {
    if (selectedRuc != null) {
      //Función para findIndex, en esta caso busca si el ruc es equivalente al valor seleccionado de ruc
      const selectedRucIndex = (caso) => {
        return caso.ruc === selectedRuc;
      };
      const casoIndex = casos.findIndex(selectedRucIndex);
      let etapaListHelper = [...etapas];

      console.log(handleDateYMD(casos[casoIndex].fecha_ingreso));

      if (casos[casoIndex].etapa === "Seguimiento") etapaListHelper.pop();
      if (casos[casoIndex].etapa === "Mediación") {
        etapaListHelper.pop();
        etapaListHelper.pop();
      }
      if (casos[casoIndex].etapa === "Premediación") {
        etapaListHelper.pop();
        etapaListHelper.pop();
        etapaListHelper.pop();
      }

      if (casos[casoIndex].etapa === "Ingreso") {
        etapaListHelper.pop();
        etapaListHelper.pop();
        etapaListHelper.pop();
        etapaListHelper.pop();
      }
      setFechaActual(
        dayjs(handleDateYMD(casos[casoIndex].ingresoData.fecha_ingreso))
      );
      setSelectedFecha(
        dayjs(handleDateYMD(casos[casoIndex].ingresoData.fecha_ingreso))
      );
      setDelitoActual(casos[casoIndex].ingresoData.delito);
      setSelectedDelito(casos[casoIndex].ingresoData.delito);
      setEtapaActual(casos[casoIndex].etapa);
      setSelectedEtapa(casos[casoIndex].etapa);
      setEtapaList(etapaListHelper);
      let newTimestamp = Timestamp.fromDate(
        dayjs(
          handleDateYMD(casos[casoIndex].ingresoData.fecha_ingreso)
        ).toDate()
      );
      setFirebaseDate(newTimestamp);
      setIdCasoSeleccionado(casos[casoIndex].id);
      setIsCargando(false);
    }
  }, [casos, selectedRuc, isCargando, isCargandoDatos]);

  const changeData = {
    idCaso: idCasoSeleccionado,
    fecha_ingreso: firebaseDate,
    etapa: selectedEtapa,
    delito: selectedDelito,
  };

  return (
    <div className={classes.columns}>
      <div className={classes.mainContainer}>
        <div className={classes.ruc}>
          <div>RUC actual</div>
          <div>
            <Autocomplete
              value={selectedRuc}
              sx={{ width: 1 }}
              onChange={(event, value) => {
                setSelectedRuc(value);
                setIsCargando(true);
              }}
              options={rucList}
              renderInput={(params) => <StyledTextField {...params} />}
            />
          </div>
          <div>RUC nuevo</div>
          <div>
            <Autocomplete
              disabled={true}
              value={selectedRuc}
              sx={{ width: 1 }}
              onChange={(event, value) => {
                setSelectedRuc(value);
                setIsCargando(true);
              }}
              options={rucList}
              renderInput={(params) => <StyledTextField {...params} />}
            />
          </div>
          <div>Duplicados</div>
          <div>
            <List
              dense={false}
              sx={{
                width: "100%",
                //maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 300,
                "& ul": { padding: 0 },
              }}
            >
              {duplicates.map((duplicate) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={duplicate.ruc}
                      secondary={
                        handleDate(duplicate.fecha_ingreso) +
                        " " +
                        duplicate.delito
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </div>
        <div className={classes.cambios}>
          <div className={classes.cambio}>
            <div>Etapa actual</div>
            <div>
              <Autocomplete
                sx={{ width: 1 }}
                disabled={true}
                value={etapaActual}
                options={etapaList}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div>Etapa nueva</div>
            <div>
              <Autocomplete
                disabled={true}
                sx={{ width: 1 }}
                value={selectedEtapa}
                onChange={(event, value) => setSelectedEtapa(value)}
                options={etapaList}
                renderInput={(params) => <StyledTextField {...params} />}
              />
            </div>
          </div>
          <div className={classes.cambio}>
            <div>Delito actual</div>
            <div>
              <Autocomplete
                disabled={true}
                sx={{ width: 1 }}
                value={delitoActual}
                options={delitoList}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div>Delito nuevo</div>
            <div>
              <Autocomplete
                value={selectedDelito}
                sx={{ width: 1 }}
                onChange={(event, value) => setSelectedDelito(value)}
                options={delitoList}
                renderInput={(params) => <StyledTextField {...params} />}
              />
            </div>
          </div>
          <div className={classes.cambio}>
            <div>Fecha ingreso actual</div>
            <div>
              <DatePicker disabled={true} value={fechaActual} />
            </div>
            <div>Fecha ingreso nueva</div>
            <div>
              <DatePicker
                disableFuture={true}
                value={selectedFecha}
                onChange={(newDate) => {
                  let newTimestamp = Timestamp.fromDate(newDate.toDate());
                  setSelectedFecha(newDate);
                  setFirebaseDate(newTimestamp);
                }}
                slots={{
                  textField: StyledTextField,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          changeCaso(changeData, setIsCargando);
        }}
      >
        Confirmar cambios
      </Button>
    </div>
  );
}

export default Admin;
