import classes from "./CasoHistoricoModal.module.css";
import FichaCaso from "../formularios/historicos/Ficha";
import EncuestaSatisfaccion from "../formularios/historicos/Satisfaccion";
import CierrePenal from "../formularios/historicos/CierrePenal";
import { HashLoader } from "react-spinners";
import folderSvg from "../../img/folder-open_path.svg";
import { getUnCaso } from "../../firebase/firebaseGetData";
import { useState, useEffect } from "react";

function CasoHistoricoModal(props) {
  const [caso, setCaso] = useState({});
  const [isCargandoCaso, setIsCargandoCaso] = useState(true);
  useEffect(() => {
    if (isCargandoCaso) {
      getUnCaso(props.caso, setCaso, setIsCargandoCaso);
    }
  }, [props.caso, isCargandoCaso]);
  
  let ingresoData = props.caso.ingresoData;
  let premediacionData = caso.premediacionData ? caso.premediacionData : {};
  let mediacionData = caso.mediacionData ? caso.mediacionData : {};
  let mediacionDataSesiones = caso.mediacionData
    ? caso.mediacionData.sesiones
      ? caso.mediacionData.sesiones
      : []
    : [];
  let seguimientoData = caso.seguimientoData ? caso.seguimientoData : {};
  let cierreData = caso.cierreData ? caso.cierreData : {};
  let convocatoriasArray = [];
  let entrevistasArray = [];

  if (caso.premediacionData) {
    if (caso.premediacionData.actividades) {
      convocatoriasArray = premediacionData.actividades.filter(
        (obj) => obj.tipo === "convocatoria"
      );
      entrevistasArray = premediacionData.actividades.filter(
        (obj) => obj.tipo === "entrevista"
      );
    }
  }

  const [tabActiva, setTabActiva] = useState("ficha");

  return (
    <div className={classes.modalContainer}>
      <div className={classes.historicos_cabecera}>
        <div>
          <img className={classes.folder} src={folderSvg} alt="" />
        </div>
        <div className={classes.title}>HISTÓRICO</div>
        <button
          className={classes.cerrar_btn_historicos}
          onClick={() => {
            props.setShowModalHistorico(false);
          }}
        >
          X
        </button>
      </div>
      {isCargandoCaso ? (
        <div className={classes.historicos_main}>
          <div className={classes.loader}>
            <HashLoader color="#36d7b7" size={80} />
          </div>
        </div>
      ) : (
        <div className={classes.historicos_main}>
          {/* BOILERPLATE CASO CERRADO */}
          <div className={classes.modal_content}>
            {/* Container de pestañas */}
            <div className={classes.tabContainer}>
              <div
                className={
                  tabActiva === "ficha" ? classes.tab_activa : classes.tab
                }
                onClick={() => {
                  setTabActiva("ficha");
                }}
              >
                Ficha: <span>{ingresoData.ruc}</span>
              </div>
              <div
                className={
                  tabActiva === "encuesta" ? classes.tab_activa : classes.tab
                }
                onClick={() => {
                  setTabActiva("encuesta");
                }}
              >
                Encuesta satisfacción
              </div>
              <div
                className={
                  tabActiva === "cierre" ? classes.tab_activa : classes.tab
                }
                onClick={() => {
                  setTabActiva("cierre");
                }}
              >
                Cierre penal
              </div>
              <div className={classes.finalStrokeTop}></div>
            </div>
            {/* Selector de información a desplegar */}
            {tabActiva === "ficha" ? (
              <FichaCaso
                ingresoData={ingresoData}
                premediacionData={premediacionData}
                convocatoriasArray={convocatoriasArray}
                entrevistasArray={entrevistasArray}
                mediacionData={mediacionData}
                seguimientoData={seguimientoData}
                mediacionDataSesiones={mediacionDataSesiones}
                cierreData={cierreData}
              ></FichaCaso>
            ) : (
              ""
            )}
            {tabActiva === "encuesta" ? (
              <EncuestaSatisfaccion
                etapa={props.caso.etapa}
                id={props.caso.id}
                ofensores={props.caso.ingresoData.ofensores}
                victimas={props.caso.ingresoData.victimas}
                encuestas={caso.encuestas ? caso.encuestas : {}}
                setIsCargandoCaso={setIsCargandoCaso}
              ></EncuestaSatisfaccion>
            ) : (
              ""
            )}
            {tabActiva === "cierre" ? (
              <CierrePenal
                caso={caso}
                setIsCargandoCaso={setIsCargandoCaso}
                cierre_penal_data={caso.cierre_penal}
              ></CierrePenal>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CasoHistoricoModal;
