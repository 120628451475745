import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";

function handleDownload(url, filename) {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
}

function rutChecker(rut) {
  try {
    let rutNoSpecial = rut.replace(/[^0-9kK]/g, "");
    let rutSinDv = rutNoSpecial.substring(0, rutNoSpecial.length - 1);
    let rutDv = rutNoSpecial.substring(rutNoSpecial.length - 1);
    let rutInt = parseInt(rutSinDv);

    let sum = 0;
    let iteraciones = 0;
    let sigue = true;
    for (let i = 2; sigue; i++) {
      console.log("nuevo ciclo");
      /* Se obtiene el resto, de la división por 10, lo que entrega como resultado
    el último dígito del número, se multiplica por el iterador */
      sum = sum + (rutInt % 10) * i;
      /* Se elimina el último número */
      rutInt = Math.floor(rutInt / 10);
      /* Se verifica que el iterador sea 7 */
      i = i === 7 ? 1 : i;
      sigue = rutInt === 0 ? false : true;
      iteraciones = iteraciones + 1;
      if (iteraciones > 10) {
        sigue = false;
      }
    }

    let resto = sum % 11;
    let dv = 11 - resto;

    if (dv === 10) {
      if (rutDv.toUpperCase() === "K") {
        return true;
      }
    } else if (dv === 11 && parseInt(rutDv) === 0) {
      return true;
    } else if (dv === parseInt(rutDv)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return true;
  }
}

function rutFormater(rut) {
  let rutNoSpecial = rut.replace(/[^0-9kK]/g, "");
  let rutSinDv = rutNoSpecial.substring(0, rutNoSpecial.length - 1);
  let largo = rutSinDv.length;
  let rutDv = rutNoSpecial.substring(rutNoSpecial.length - 1);
  if (largo > 6) {
    let miles = rutSinDv.slice(largo - 3);
    let cienmiles = rutSinDv.slice(largo - 6, largo - 3);
    let millones = rutSinDv.slice(0, largo - 6);
    cienmiles = "." + cienmiles;
    miles = "." + miles;
    rut = millones + cienmiles + miles + "-" + rutDv;
  }
  return rut;
}

function handleDate(timestamp) {
  if (timestamp) {
    let dateData = new Date(timestamp.seconds * 1000);
    let day =
      dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
    let month =
      dateData.getMonth() + 1 > 9
        ? dateData.getMonth() + 1
        : "0" + (dateData.getMonth() + 1);
    let year = dateData.getFullYear();
    let parsedString = day + "-" + month + "-" + year;
    return parsedString;
  } else {
    return "Sin información";
  }
}

function dateArray(startDate, endDate) {
  const datesArray = [];
  while (startDate.isSameOrBefore(endDate, "day")) {
    datesArray.push(startDate.format("YYYY-MM-DD"));
    startDate.add(1, "day");
  }
  return datesArray;
}

function monthArrayGenerator(startDate, endDate){
  const datesArray = [];
  while (startDate.isSameOrBefore(endDate, "month")){
    datesArray.push(startDate.format("YYYY-MM"));
    startDate.add(1, "month");
  }
  return datesArray;
}

export { rutChecker, handleDate, rutFormater, handleDownload, dateArray, monthArrayGenerator };
