import { useState } from "react";
import clock from "../../img/clock.png";
import classes from "./PremediacionCard.module.css";
import ModalPremediacion from "../modal/PremediacionModal";

function PremediacionCard(props) {
  const [modalPremediacion, setShowModalPremediacion] = useState(false);
  const mediador_asignado = props.caso.mediador_asignado
    ? props.caso.mediador_asignado
    : "Sin asignar";

  const showModalPremediacion = () => {
    setShowModalPremediacion(true);
  };
  let victima =
    props.caso.victimas.length > 0
      ? props.caso.victimas.length > 1
        ? "Múltiple"
        : props.caso.victimas[0].nombres +
          " " +
          props.caso.victimas[0].apellidos
      : "Sin información";

  let ofensor =
    props.caso.ofensores.length > 0
      ? props.caso.ofensores.length > 1
        ? "Múltiple"
        : props.caso.ofensores[0].nombres +
          " " +
          props.caso.ofensores[0].apellidos
      : "Sin información";

  let timeDiff = Math.abs(Date.now() - props.caso.fecha_ingreso.seconds * 1000);
  let dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  // FUNCIÓN FECHA
  function handleDate(timestamp) {
    if (timestamp) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "Sin información";
    }
  }

  return (
    <div className={classes.MainCaso} onClick={showModalPremediacion}>
      <div className={classes.CasoEtiqueta}>
        CASO: <span>{props.caso.ruc ? props.caso.ruc : "321654"}</span>
      </div>
      <div className={classes.CasoDatos}>
        <p className={classes.item}>
          Mediador: <span>{mediador_asignado}</span>
        </p>
        <p className={classes.item}>
          Delito: <span>{props.caso.delito ? props.caso.delito : "Dummy"}</span>
        </p>
        <p className={classes.item}>
          Víctima: <span>{victima}</span>
        </p>
        <p className={classes.item}>
          Estado Víctima:
          <span>
            {props.caso.estado_actual_victima
              ? props.caso.estado_actual_victima
              : "Inicio Premediación"}
          </span>
        </p>
        <p className={classes.item}>
          Ofensor: <span>{ofensor}</span>
        </p>
        <p className={classes.item}>
          Estado Ofensor:
          <span>
            {props.caso.estado_actual_ofensor
              ? props.caso.estado_actual_ofensor
              : "Inicio Premediación"}
          </span>
        </p>
        <p className={classes.item}>
          Última modificación:
          <span>
            {props.caso.fecha_ultima_modificacion
              ? handleDate(props.caso.fecha_ultima_modificacion)
              : "11 de Enero de 2023"}
          </span>
        </p>
      </div>

      <div className={classes.CasoAyuda}>
        <img className={classes.CasoReloj} src={clock} alt="" />
        <p>{dayDiff ? dayDiff + " días desde ingreso" : "Sin información"}</p>
        {/* <img className={classes.CasoClip} src={clip} alt="" /> */}
      </div>
      <div className={classes.modalContainer}>
        {modalPremediacion ? (
          <ModalPremediacion
            setShowModalPremediacion={setShowModalPremediacion}
            key={props.caso ? props.caso.id : Date.now()}
            caso={props.caso}
          ></ModalPremediacion>
        ) : null}
      </div>
    </div>
  );
}

export default PremediacionCard;
